import { MetricsKPIDrillDownBreakDownOptions } from 'src/services/openApiV2'
import { filterIcons, filterNames } from 'src/utils/config/config'
import { Dictionary } from 'src/utils/config/dictionary'
import JSONDataType from '../types'

const commonColumnsArr: JSONDataType['mainBreakdownOptions'][0]['columns'] = [
  { title: `${Dictionary.INACCURATE} Error Rate`, field: 'avg_error_inaccurate_rate_percent', type: 'percentage', deltaType: 'positive_delta_red_color' }
]

const jsonData: JSONDataType = {
  title: `${Dictionary.INACCURATE} Error Rate`,
  mainBreakdownOptions: [
    {
      icon: filterIcons.b_name,
      label: filterNames.b_name,
      value: MetricsKPIDrillDownBreakDownOptions.B_NAME,
      columns: [{ title: filterNames.b_name, field: 'b_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.slug,
      label: filterNames.slug,
      value: MetricsKPIDrillDownBreakDownOptions.SLUG,
      columns: [{ title: filterNames.slug, field: 'slug' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_name,
      label: filterNames.vb_name,
      value: MetricsKPIDrillDownBreakDownOptions.VB_NAME,
      columns: [{ title: filterNames.vb_name, field: 'vb_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_platform,
      label: filterNames.vb_platform,
      value: MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM,
      columns: [{ title: filterNames.vb_platform, field: 'vb_platform' }, ...commonColumnsArr]
    }
  ],
  apiFunctions: {
    tableData: 'callDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiPaginatedDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    dateRangeCompareData:
      'drillDownGeneratedMetricsKpiGroupedListOfEntriesDateRangeCompareApiDateRangeCompareDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    exportData: 'exportDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiExportDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    filterData: 'callDrillDownGeneratedMetricsKpiUniqueValuesApiDrillDownGeneratedMetricsKpiUniqueValuesPost'
  },
  finalComponentKey: 'inaccurate_error_overview'
}

export default jsonData
