import { MetricsKPIDrillDownBreakDownOptions } from 'src/services/openApiV2'
import { Modules, filterIcons, filterNames } from 'src/utils/config/config'
import JSONDataType from '../types'

const commonColumnsArr: JSONDataType['mainBreakdownOptions'][0]['columns'] = [
  { title: Modules.Chargebacks, field: 'total_chargebacks', type: 'currency', deltaType: 'positive_delta_red_color' },
  { title: 'Error Rate', field: 'error_rate_percent', type: 'percentage', deltaType: 'positive_delta_red_color' },
  { title: '3P Fee %', field: 'third_party_fee_perc', type: 'percentage', deltaType: 'positive_delta_red_color' },
  { title: 'Total Sales', field: 'total_sales', type: 'currency', deltaType: 'positive_delta_green_color' },
  { title: 'Average rating', field: 'average_rating', type: 'number', deltaType: 'positive_delta_green_color' },
  { title: 'Total Reviews', field: 'total_reviews', type: 'number', deltaType: 'positive_delta_green_color' },
  { title: 'Downtime', field: 'downtime_percentage', type: 'percentage', deltaType: 'positive_delta_red_color' },
  { title: 'Average downtime', field: 'average_downtime_in_minutes', type: 'time_period_formatter', deltaType: 'positive_delta_red_color' },
  { title: 'Marketing spend', field: 'total_marketing_spend', type: 'currency', deltaType: 'positive_delta_green_color' }
]

const jsonData: JSONDataType = {
  title: 'Metrics',
  mainBreakdownOptions: [
    {
      icon: filterIcons.b_name,
      label: filterNames.b_name,
      value: MetricsKPIDrillDownBreakDownOptions.B_NAME,
      columns: [{ title: filterNames.b_name, field: 'b_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.slug,
      label: filterNames.slug,
      value: MetricsKPIDrillDownBreakDownOptions.SLUG,
      columns: [{ title: filterNames.slug, field: 'slug' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_name,
      label: filterNames.vb_name,
      value: MetricsKPIDrillDownBreakDownOptions.VB_NAME,
      columns: [{ title: filterNames.vb_name, field: 'vb_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_platform,
      label: filterNames.vb_platform,
      value: MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM,
      columns: [{ title: filterNames.vb_platform, field: 'vb_platform' }, ...commonColumnsArr]
    }
  ],
  apiFunctions: {
    tableData: 'callDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiPaginatedDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    dateRangeCompareData:
      'drillDownGeneratedMetricsKpiGroupedListOfEntriesDateRangeCompareApiDateRangeCompareDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    exportData: 'exportDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiExportDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    filterData: 'callDrillDownGeneratedMetricsKpiUniqueValuesApiDrillDownGeneratedMetricsKpiUniqueValuesPost'
  }
}

export default jsonData
