import { Box, Button, Dialog, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import TableChip from 'src/components/TableChip'
import Template from 'src/components/Template'
import { ChargebackOrderRow } from 'src/services/openApiV2'
import { Modules } from 'src/utils/config/config'
import { getShortOrderId } from 'src/utils/functions'
import { ItemType, OrderLevelType } from '../Tabs/ErrorsRevenueRecapture'
import { SelectedItemtype } from './ItemLevelRaiseDisputeDialog'
import ItemView from './ItemView'
import { useReasonContext } from './ReasonContext'

interface OrderLevelRaiseDisputeDialogProps {
  data: ChargebackOrderRow | null
  onClose: () => void
  onRaise: (items: OrderLevelType[], reason: string, description?: string) => void
}

export default function OrderLevelRaiseDisputeDialog(props: OrderLevelRaiseDisputeDialogProps) {
  const reasons = useReasonContext()
  const [reason, setReason] = React.useState<(typeof reasons)[0]>(null)
  const [description, setDescription] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState<SelectedItemtype[]>([])

  const variant = props.data?.num_days_to_dispute > 10 ? 'primary' : props.data?.num_days_to_dispute >= 5 ? 'warning' : 'error'
  const data: ItemType[] = (props.data?.items || []) as any

  const raiseDispute = () => {
    // ActionService.raiseDisputeDisputeOrderPut()
    // let x: DisputeData;
    // x.
    const arr: OrderLevelType[] = selectedItem.map((e) => {
      const itemData = data.find((f) => f.id === e.itemID)

      const obj: OrderLevelType = {
        ...itemData
      }

      return obj
    })
    props.onRaise(arr, reason.reason, description.length > 0 ? description : undefined)
  }

  React.useEffect(() => {
    if (props.data === null) {
      setReason(null)
      setDescription('')
      setSelectedItem([])
    }
  }, [props.data])

  return (
    <>
      <Dialog
        open={props.data !== null}
        fullWidth
        maxWidth="md"
        onClose={() => {
          props.onClose()
        }}>
        {props.data !== null && (
          <DialogContent>
            <Typography
              variant="h3"
              sx={{ mb: 1 }}>
              {Modules.ErrorCharges} - Order #{getShortOrderId(props.data.vb_platform, props.data.order_id)}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ mb: 2 }}>
              You have{' '}
              <TableChip
                label={`${props.data.num_days_to_dispute} days`}
                variant={variant}
                size={'small'}
              />{' '}
              left to dispute the {Modules.ErrorCharges.toLowerCase()} for this order
            </Typography>

            <Box>
              {data.map((item, index) => {
                return (
                  <ItemView
                    hideReasons
                    key={index}
                    data={item}
                    platform={props.data.vb_platform}
                    item_level_error={props.data.item_level_error}
                    onChange={(param) => {
                      setSelectedItem((p) => {
                        const arr: typeof p = JSON.parse(JSON.stringify(p))
                        const index = arr.findIndex((e) => e.itemID === param.obj.itemID)
                        if (index >= 0 && param.type === 'unchecked') {
                          return arr.slice(0, index).concat(arr.slice(index + 1))
                        } else if (index < 0 && param.type === 'checked') {
                          return [...arr, param.obj]
                        } else if (index >= 0 && param.type === 'checked') {
                          arr[index] = param.obj
                          return arr
                        }
                        return p
                      })
                    }}
                  />
                )
              })}
            </Box>

            <Box mt={2}>
              <FormControl
                fullWidth
                sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">Choose a reason</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reason?.reason || ''}
                  label="Choose a reason"
                  onChange={(e) => {
                    const obj = reasons.find((p) => p.reason === e.target.value)
                    setReason(obj)
                  }}
                  size="small">
                  {reasons
                    .filter(
                      (e) =>
                        e.platform === props.data.vb_platform &&
                        ((props.data.item_level_error && e.item_level) || (!props.data.item_level_error && e.order_level))
                    )
                    .map((e) => {
                      return (
                        <MenuItem
                          key={e.reason}
                          value={e.reason}>
                          <span style={{ textTransform: 'capitalize' }}>{e.reason.split('_').join(' ').toLowerCase()}</span>
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>

              {!!reason && reason.description && (
                <>
                  <TextField
                    label="Enter Description"
                    placeholder="Describe why"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                    multiline
                    maxRows={3}
                    minRows={3}
                    sx={{ mb: 2 }}
                    size="small"
                  />
                  {!!reason && !!reason.templates.length && (
                    <>
                      <Stack
                        direction="row"
                        alignItems={'center'}
                        spacing={1}
                        sx={{ my: 2 }}>
                        <Box flex={1}>
                          <Divider />
                        </Box>
                        <Typography variant={'subtitle2'}>OR</Typography>
                        <Box flex={1}>
                          <Divider />
                        </Box>
                      </Stack>

                      <Stack
                        direction="column"
                        spacing={1}
                        maxHeight="200px"
                        overflow={'auto'}>
                        {reason.templates.map((item) => {
                          return (
                            <Template
                              description={item.text}
                              onClick={() => {
                                setDescription(item.text)
                              }}
                            />
                          )
                        })}
                      </Stack>
                    </>
                  )}
                </>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent="flex-end"
              gap={2}
              mt={2}>
              <Button
                variant="text"
                onClick={() => {
                  props.onClose()
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={selectedItem.length === 0 || reason === null}
                onClick={() => {
                  raiseDispute()
                }}>
                SUBMIT
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}
