import { useState } from 'react'
import ChannelPieChart, { ClickChannelDataType } from './ChannelPieChart'
import FulfillmentPieChart from './FulfillmentPieChart'

interface SalesChannelBreakdownChartsProps {}

export default function SalesChannelBreakdownCharts(props: SalesChannelBreakdownChartsProps) {
  const [activeChannelData, setActiveData] = useState<ClickChannelDataType | null>(null)
  return (
    <>
      <ChannelPieChart onChannelClick={setActiveData} />
      <FulfillmentPieChart activeChannel={activeChannelData} />
    </>
  )
}
