import { Tag } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import CollapsableSection from 'src/components/CollapsableSection'
import PlatformLogo from 'src/components/PlatformLogo'
import SyncedChartsProvider from 'src/pages/members/MarketingPages/Trends/context/SyncedChartsContext'
import { PLATFORM } from 'src/services/openApiV2'
import { platformNames } from 'src/utils/config/config'
import ConversionMetricsCharts from '../../ConversionMetrics/components/ConversionMetricsCharts'
import OverviewCards from './OverviewCards'
import OverviewMarketingCharts from './OverviewMarketingCharts'
export default function Overview() {
  const [collapseControl, setCollapseControl] = useState<boolean>(true)

  const collapseAdMetrics = () => {
    setCollapseControl(false)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}>
      <CollapsableSection
        title="Overview"
        icon={<Tag sx={{ fontSize: '20px', color: '#000' }} />}>
        <OverviewCards />
      </CollapsableSection>

      <SyncedChartsProvider>
        <CollapsableSection
          title="Marketing Metrics"
          icon={<Tag sx={{ fontSize: '20px', color: '#000' }} />}>
          <OverviewMarketingCharts />
        </CollapsableSection>

        <CollapsableSection
          collapseControl={collapseControl}
          setCollapseControl={setCollapseControl}
          title={
            <Box
              display="flex"
              alignItems="center"
              gap={1}>
              <Typography
                fontSize="14px"
                color={'#000000'}
                fontWeight={600}>
                Ad Campaign Metrics
              </Typography>

              <Tooltip
                title={`Only ${platformNames[PLATFORM.DOORDASH]} and ${platformNames[PLATFORM.UBER_EATS]} data is available for Ads Campaign Metrics`}
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: 'white',
                      fontSize: 12,
                      fontWeight: 500,
                      background: 'black',
                      maxWidth: 600,
                      padding: '10px'
                    }
                  },
                  arrow: {
                    style: {
                      color: 'black'
                    }
                  }
                }}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={0.5}>
                  <PlatformLogo
                    shortImg
                    platformName="Doordash"
                    height="20px"
                    width="20px"
                    boxSx={{
                      flex: 'unset',
                      display: 'flex'
                    }}
                  />
                  <PlatformLogo
                    shortImg
                    platformName="UberEats"
                    height="20px"
                    width="20px"
                    boxSx={{
                      flex: 'unset',
                      display: 'flex'
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          }
          icon={<Tag sx={{ fontSize: '20px', color: '#000' }} />}>
          <ConversionMetricsCharts collapseAdMetrics={collapseAdMetrics} />
        </CollapsableSection>
      </SyncedChartsProvider>
    </Box>
  )
}
