import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const SalesOverview = lazy(() => import('src/pages/members/SalesOverview/Dashboard'))

const DashboardTrendsOnly = lazy(() => import('src/pages/members/dashboard/subPages/TrendsOnly'))
const DashboardMetricsOnly = lazy(() => import('src/pages/members/dashboard/subPages/MetricsOnly'))
const DashboardErrorOverview = lazy(() => import('src/pages/members/dashboard/subPages/ErrorOverview'))
const DashboardSales = lazy(() => import('src/pages/members/dashboard/subPages/Sales'))
const DashboardRatingTrends = lazy(() => import('src/pages/members/dashboard/subPages/DashboardRatingTrends'))

const Reviews = lazy(() => import('src/components/Reviews/Reviews'))
const PlaidAuth = lazy(() => import('src/components/Plaid/PlaidAuth'))
const IssueDiscovery = lazy(() => import('src/pages/members/IssueDiscovery/IssueDiscovery'))
const IssueDiscoveryWithFilter = lazy(() => import('src/pages/members/IssueDiscoveryWithFilter/IssueDiscoveryWithFilter'))

const Errors = lazy(() => import('src/pages/members/Errors/Errors'))
const SingleCampaignAllStores = lazy(() => import('src/pages/members/Marketing/SingleCampaign/SingleCampaignAllStores'))

const WaitingScreen = lazy(() => import('src/pages/members/Marketing/SingleCampaign/WaitingScreen'))
const InAccurateOrders = lazy(() => import('src/pages/members/InAccurateOrders/InAccurateOrders'))

const StorePerformanceIntro = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceIntro'))

const StorePerformanceReport = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceReport'))
const StorePerformanceSubscribeUser = lazy(() => import('src/pages/members/StorePerformance/SubscribeUser'))

const StorePerformanceCadence = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceCadence'))

const WhatsNew = lazy(() => import('src/pages/members/Changelog/WhatsNew'))

const Configurations = lazy(() => import('src/pages/members/Configurations/ConfigurationCenter'))
const StoreAvailabilityReasonsConfigs = lazy(() => import('src/pages/members/StoreAvailabilityReasonsConfigs/StoreAvailabilityReasonsConfigs'))

import DrillDown from 'src/pages/members/DrillDown/DrillDown'
import CardReconciliation from '../../pages/members/Pos/CardReconciliation'
import ThirdPartyOrdersReconciliation from '../../pages/members/Pos/ThirdPartyOrdersReconciliation'
import ThirdPartyReconciliation from '../../pages/members/Pos/ThirdPartyReconciliation'

const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))

const AccountingSetup = lazy(() => import('src/pages/members/AccountingSetup/AccountingSetup'))

const AddAccountingSoftware = lazy(() => import('src/pages/members/AccountingSetup/AddAccountingSoftware'))

const AccountingConfigurationV2 = lazy(() => import('src/pages/members/AccountingSetup/AccountingConfigurationV2'))

const AccountingLocationStatus = lazy(() => import('src/pages/members/AccountingSetup/AccountingLocationStatus'))

const DrillDownExample = lazy(() => import('src/pages/members/DrillDownExample/DrillDownExample'))

const AccountingPortal = lazy(() => import('src/pages/members/AccountingPortal/AccountingPortal'))

const Chooseplateform = lazy(() => import('src/pages/members/credentials/Chooseplateform'))
const Process = lazy(() => import('src/pages/members/credentials/Process'))
const OrdersOld = lazy(() => import('src/pages/members/OrdersOld/Orders'))

const Landing = lazy(() => import('src/pages/members/Landing'))
const Visuals = lazy(() => import('src/pages/members/Visuals'))

//ORDER ACCURACY

const MissedOrders = lazy(() => import('src/pages/members/MissedOrders/MissedOrders'))
const CancelledOrders = lazy(() => import('src/pages/members/CancelledOrders/CancelledOrders'))

const Uploadcsv = lazy(() => import('src/pages/members/credentials/Uploadcsv'))
const IntegrationStatus = lazy(() => import('src/pages/members/IntegrationStatus'))
const Shift = lazy(() => import('src/pages/members/Shift/Shift'))
const ShiftLeaderboard = lazy(() => import('src/pages/members/Shift/Leaderboard'))

const UploadCsvDetail = lazy(() => import('src/pages/members/credentials/UploadCSVDetails'))
// const Finance = lazy(() => import('src/pages/members/Finance/Finance'));
// const FinanceBreakdown = lazy(() => import("src/pages/members/Finance/FinanceBreakdown"));
const ItemPerformance = lazy(() => import('src/pages/members/ItemPerformance/ItemPerformance'))
const ItemPerformancePage = lazy(() => import('src/pages/members/ItemPerformance/ItemPerformancePage'))
const SpecificItemPerformance = lazy(() => import('src/pages/members/ItemPerformance/SpecificItemPerformance/SpecificItemPerformance'))

// Mapping
const Mapping = lazy(() => import('src/pages/members/Mapping/Mapping'))
const MappingInternalOperator = lazy(() => import('src/pages/members/Mapping/MappingInternalOperator'))
const OnboardStatus = lazy(() => import('src/pages/members/OnboardStatus/OnboardStatus'))

const ChargebackDisputeConfigs = lazy(() => import('src/pages/members/ChargebackDisputeConfigs/ChargebackDisputeConfigs'))
const ChargebackConfigs = lazy(() => import('src/pages/members/ChargebackConfigs/ChargebackConfigs'))

export const baseRoutes = [
  {
    path: 'list-orders',
    element: (
      <Navigate
        to="/list-orders/all"
        replace
      />
    )
  },
  { path: 'list-orders/all', element: <OrdersOld status="all" /> },
  {
    path: 'list-orders/cancelled',
    element: <OrdersOld status="cancelled" />
  },
  {
    path: 'list-orders/inaccurate',
    element: <OrdersOld status="inaccurate" />
  },
  { path: 'list-orders/missed', element: <OrdersOld status="missed" /> },
  {
    path: 'errors',
    element: <Navigate to={`/errors/overview${window.location.search}`} />
  },
  { path: 'errors/overview', element: <Errors /> },
  { path: 'errors/inaccurate-orders', element: <InAccurateOrders /> },
  { path: 'errors/inaccurate-orders/:filter', element: <InAccurateOrders /> },
  { path: 'errors/missed-orders', element: <MissedOrders /> },
  { path: 'errors/missed-orders/:filter', element: <MissedOrders /> },
  { path: 'errors/cancelled-orders', element: <CancelledOrders /> },
  { path: 'errors/cancelled-orders/:filter', element: <CancelledOrders /> },
  {
    path: 'errors/issue-discovery/:tab/:filter',
    element: <IssueDiscovery />
  },
  {
    path: 'errors/issue-discovery/:tab/:filter/:filterName',
    element: <IssueDiscoveryWithFilter />
  },
  {
    path: 'issue-discovery/:tab/:filter',
    element: (
      <Navigate
        to="/errors/issue-discovery/discovery/by-reasons"
        replace
      />
    )
  },
  {
    path: 'issue-discovery/:tab/:filter/:filterName',
    element: (
      <Navigate
        to="/errors/issue-discovery/discovery/by-reasons"
        replace
      />
    )
  },
  {
    path: 'dashboard',
    element: <SalesOverview />
  },
  { path: 'dashboard/v1/trends', element: <DashboardTrendsOnly /> },
  { path: 'dashboard/v1/metrics', element: <DashboardMetricsOnly /> },
  {
    path: 'dashboard/v1/error/overview',
    element: <DashboardErrorOverview />
  },
  { path: 'dashboard/v1/sales', element: <DashboardSales /> },
  { path: 'dashboard/v1/rating/trends', element: <DashboardRatingTrends /> },
  { path: 'dashboard/benchmarks', element: <Benchmarks /> },
  // { path: "finance", element: <Finance /> },
  // { path: "finance/summary", element: <Finance /> },
  // { path: "finance/breakdown", element: <FinanceBreakdown /> },
  { path: 'finance/bank/integration', element: <PlaidAuth /> },
  { path: 'reviews/summary', element: <Reviews /> },
  { path: 'reviews/summary/generate', element: <Reviews /> },
  // { path: 'marketing/new', element: <Marketing /> },
  { path: 'marketing/campaign/single', element: <SingleCampaignAllStores /> },
  { path: 'marketing/waiting', element: <WaitingScreen /> },
  {
    path: 'item-performance/details/:storeName/:itemName',
    element: <SpecificItemPerformance />
  },
  { path: 'item-performance/item-view', element: <ItemPerformance /> },
  { path: 'item-performance', element: <ItemPerformancePage /> },

  { path: 'integration-status', element: <IntegrationStatus /> },
  { path: 'visuals', element: <Visuals /> },
  { path: 'landing', element: <Landing /> },
  { path: 'shift', element: <Shift /> },
  { path: 'shift/leaderboard', element: <ShiftLeaderboard /> },
  { path: 'choose-platform', element: <Chooseplateform /> },
  { path: 'process', element: <Process /> },
  { path: 'upload-csv', element: <Uploadcsv /> },
  { path: 'upload-csv-detail', element: <UploadCsvDetail /> },
  { path: 'single', element: <SingleCampaignAllStores /> },
  { path: 'waiting', element: <WaitingScreen /> },

  { path: 'shift', element: <Shift /> },
  { path: 'onboard/data', element: <Mapping /> },
  { path: 'onboard/status', element: <OnboardStatus /> },
  { path: 'loop-store-mapping', element: <MappingInternalOperator /> },

  { path: 'breakdown/example', element: <DrillDownExample /> },

  { path: 'store-performance/intro', element: <StorePerformanceIntro /> },
  { path: 'store-performance/report-type', element: <StorePerformanceReport /> },
  { path: 'store-performance/subscribe-user', element: <StorePerformanceSubscribeUser /> },
  { path: 'store-performance/report-cadence', element: <StorePerformanceCadence /> },
  { path: 'pos/card-reconciliation', element: <CardReconciliation /> },
  { path: 'pos/third-party-reconciliation', element: <ThirdPartyReconciliation /> },
  { path: 'pos/third-party-orders-reconciliation', element: <ThirdPartyOrdersReconciliation /> },
  { path: 'sandboxbookkeeping/gl-entries', element: <AccountingPortal /> },
  {
    path: 'sandboxbookkeeping/integrations',
    element: <AccountingSetup />
  },
  {
    path: 'sandboxbookkeeping/integrations/add',
    element: <AddAccountingSoftware />
  },
  { path: 'sandboxbookkeeping/map-gl', element: <AccountingConfigurationV2 /> },
  { path: 'sandboxbookkeeping/location/status', element: <AccountingLocationStatus /> },
  {
    path: 'list-orders',
    element: (
      <Navigate
        to="/list-orders/all"
        replace
      />
    )
  },
  {
    path: 'all-orders/cancelled',
    element: <OrdersOld status="cancelled" />
  },
  {
    path: 'all-orders/inaccurate',
    element: <OrdersOld status="inaccurate" />
  },
  { path: 'all-orders/missed', element: <OrdersOld status="missed" /> },
  {
    path: 'order-accuracy',
    element: <Navigate to={`/order-accuracy/overview${window.location.search}`} />
  },
  { path: 'order-accuracy/overview', element: <Errors /> },
  { path: 'order-accuracy/inaccurate-orders', element: <InAccurateOrders /> },
  { path: 'order-accuracy/inaccurate-orders/:filter', element: <InAccurateOrders /> },
  { path: 'order-accuracy/missed-orders', element: <MissedOrders /> },
  { path: 'order-accuracy/missed-orders/:filter', element: <MissedOrders /> },
  { path: 'order-accuracy/cancelled-orders', element: <CancelledOrders /> },
  { path: 'order-accuracy/cancelled-orders/:filter', element: <CancelledOrders /> },
  { path: 'drill-down/:name', element: <DrillDown /> },
  { path: 'changelog', element: <WhatsNew /> },
  { path: 'configurations', element: <Configurations /> },
  { path: 'configurations/store-availability', element: <Configurations /> },
  { path: 'configurations/store-availability/reasons', element: <StoreAvailabilityReasonsConfigs /> },
  {
    path: 'configurations/chargebacks/categories',
    element: <ChargebackDisputeConfigs />
  },
  {
    path: 'configurations/chargebacks',
    element: <ChargebackConfigs />
  }
]
