import { CloudDownloadOutlined, DescriptionOutlined, ForwardToInbox, ImageOutlined, TableChart } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, MenuItem, Popover, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import { CsvBuilder } from 'filefy'
import { useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useSnackData } from 'src/context/SnackContext'
import { useTransactionsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/TransactionsBreakdownDataContext'
import { Modules, platformNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { convertComponentToPdf, convertComponentToPng, sendComponentToEmail } from 'src/utils/functions/downloadComponent'

const menuOptions = [
  {
    value: 'pdf',
    icon: <DescriptionOutlined sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Export as PDF',
    function: 'exportPdf'
  },
  {
    value: 'png',
    icon: <ImageOutlined sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Export as PNG',
    function: 'exportPng'
  },
  {
    value: 'csv',
    icon: <TableChart sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Export as CSV',
    function: 'exportCSV'
  },
  {
    value: 'email',
    icon: <ForwardToInbox sx={{ color: '#000', fontSize: '20px' }} />,
    text: 'Send as email',
    function: 'sendToEmail'
  }
]

type ExportTypes = 'pdf' | 'png' | 'email'

interface ExportComponentProps {
  componentRef: React.MutableRefObject<HTMLElement>
  fileName?: string
  buttonSx?: SxProps
  buttonIcon?: React.ReactNode
  buttonText?: string
  buttonLoading?: boolean
  menuSx?: SxProps
  hideMenuOptions?: ExportTypes[]
  onPreExport?: () => void
  onPostExport?: () => void
}

const ExportComponent = ({
  componentRef,
  fileName,
  buttonSx,
  buttonIcon,
  buttonText,
  buttonLoading,
  menuSx,
  hideMenuOptions,
  onPreExport,
  onPostExport
}: ExportComponentProps) => {
  const { openError, openSuccess, setDownloadSnack } = useSnackData()
  const { currentUser } = useAuth()
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null)
  const { platformBreakdownData, breakdownData } = useTransactionsBreakdownDataContext()

  const exportCSV = async () => {
    try {
      const configArr = [
        {
          valueKey: 'values.subtotal_with_tax',
          percentageKey: 'percentages.subtotal_with_tax',
          label: 'Sales Incl. Tax'
        },
        {
          valueKey: 'values.subtotal',
          percentageKey: 'percentages.subtotal',
          label: 'Sales Excl. Tax'
        },
        {
          valueKey: 'values.lost_sales',
          label: Modules.LostSales
        },
        {
          valueKey: 'values.unfulfilled_refunds',
          percentageKey: 'percentages.unfulfilled_refunds',
          label: Modules.UnfulfilledRefunds
        },
        {
          valueKey: 'values.tax_host',
          percentageKey: 'percentages.tax_host',
          label: 'Taxes'
        },
        {
          valueKey: 'values.tax_witheld',
          label: 'Taxes Withheld'
        },
        {
          valueKey: 'values.commission',
          percentageKey: 'percentages.commission',
          label: 'Commission'
        },
        {
          valueKey: 'values.restaurant_delivery_charge',
          percentageKey: 'percentages.restaurant_delivery_charge',
          label: 'Restaurant Delivery Charge'
        },
        {
          valueKey: 'values.marketing',
          percentageKey: 'percentages.marketing',
          label: 'Marketing'
        },
        {
          valueKey: 'values.marketing_ad',
          label: 'Marketing Ad Spend'
        },
        {
          valueKey: 'values.marketing_promo',
          label: 'Marketing Promo Spend'
        },
        {
          valueKey: 'values.loyalty',
          percentageKey: 'percentages.loyalty',
          label: 'Loyalty'
        },
        {
          valueKey: 'values.chargeback',
          percentageKey: 'percentages.chargeback',
          label: Modules.Chargebacks
        },
        {
          valueKey: 'values.reversal',
          percentageKey: 'percentages.reversal',
          label: Modules.WonDisputes
        },
        {
          valueKey: 'values.customer_tip',
          label: 'Customer Tip'
        },
        {
          valueKey: 'values.restaurant_fees',
          label: 'Restaurant Fees'
        },
        {
          valueKey: 'values.miscellaneous',
          label: 'Miscellaneous'
        },
        {
          valueKey: 'values.unaccounted',
          label: 'Unaccounted'
        },
        {
          valueKey: 'values.total_payment',
          label: 'Estimated Payment'
        }
      ]

      const platforms = Object.keys(platformBreakdownData).filter((platform) => !!platformBreakdownData[platform])

      const rows: string[][] = []

      rows.push([
        'Component',
        ...platforms.map((platform) => {
          const platformName = Object.entries(platformNames).find(([key]) => key.toLowerCase() === platform.toLowerCase())?.[1]
          return platformName || platform
        }),
        'Total'
      ])

      rows.push(
        ...configArr
          .map((config) => {
            const result: string[][] = []

            if (config.percentageKey) {
              result.push([
                `${config.label} (Percentage)`,
                ...platforms.map((platform) => {
                  const data = get(platformBreakdownData, `${platform}.${config.percentageKey}`, '')
                  return data ? `${data}%` : ''
                }),
                `${get(breakdownData, `${config.percentageKey}`, '')}%`
              ])
            }

            result.push([
              config.label,
              ...platforms.map((platform) => {
                const data = get(platformBreakdownData, `${platform}.${config.valueKey}`, '')
                return data || ''
              }),
              get(breakdownData, `${config.valueKey}`, '')
            ])

            return result
          })
          .flat()
      )

      const csvBuilder = new CsvBuilder('platform_breakdown.csv')
      csvBuilder.addRows(rows)
      csvBuilder.exportFile()
      openSuccess('CSV exported successfully')
    } catch (err) {
      console.error(err)
      openError('Something went wrong while exporting CSV')
    }
  }

  const exportPdf = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      onPostExport && onPreExport()
      await convertComponentToPdf(componentRef.current, fileName)
      onPostExport && onPostExport()
      setDownloadSnack({ status: 'complete' })
      handlePopoverClose()
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      openError('Failed to download PDF: ' + err.message)
    }
  }

  const exportPng = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      onPostExport && onPreExport()
      await convertComponentToPng(componentRef.current, fileName)
      onPostExport && onPostExport()
      setDownloadSnack({ status: 'complete' })
      handlePopoverClose()
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      openError('Failed to download image: ' + err.message)
    }
  }

  const sendToEmail = async () => {
    try {
      onPostExport && onPreExport()
      await sendComponentToEmail(componentRef.current, currentUser.email)
      onPostExport && onPostExport()
      openSuccess('File sent to your email successfully')
      handlePopoverClose()
    } catch (err) {
      openError('Failed to send file to email: ' + err.message)
    }
  }

  const functions = {
    exportPdf,
    exportPng,
    sendToEmail,
    exportCSV
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  return (
    <Box>
      <Tooltip title="Export this data">
        <IconButton
          sx={{
            borderRadius: '4px',
            bgcolor: popoverAnchorEl ? '#EEEEEE' : '#F5F5F5',
            '&:hover': {
              bgcolor: '#EEEEEE'
            },
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            ...buttonSx
          }}
          disabled={buttonLoading}
          onClick={(e) => {
            setPopoverAnchorEl(e.currentTarget)
          }}>
          {buttonLoading ? (
            <CircularProgress
              size={18}
              sx={{ color: '#000' }}
            />
          ) : buttonIcon ? (
            buttonIcon
          ) : (
            <CloudDownloadOutlined sx={{ color: '#000', fontSize: '20px' }} />
          )}
          {buttonText && (
            <Typography
              color="#000"
              fontWeight={600}
              fontSize={14}>
              {buttonText}
            </Typography>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        sx={{
          ...menuSx
        }}>
        <Stack p={1}>
          {menuOptions
            .filter((option) => !hideMenuOptions?.includes(option.value as ExportTypes))
            ?.map((option, index) => (
              <MenuItem
                key={option.value + index}
                onClick={functions[option.function]}
                sx={{ display: 'flex', alignItems: 'center', gap: 1.5, px: 0.5 }}>
                {option.icon}
                <Typography
                  color="#000"
                  fontWeight={500}
                  fontSize={13}>
                  {option.text}
                </Typography>
              </MenuItem>
            ))}
        </Stack>
      </Popover>
    </Box>
  )
}

export default ExportComponent
