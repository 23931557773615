import { PieChart } from '@LoopKitchen/loop-ui'
import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { useTransactionsBreakdownDataContext } from '../../../utils/context/TransactionsBreakdownDataContext'
import { ClickChannelDataType } from '../ChannelPieChart'

interface FulfillmentPieChartProps {
  activeChannel?: ClickChannelDataType
}

export default function FulfillmentPieChart(props: FulfillmentPieChartProps) {
  const { activeChannel } = props
  const { channelFulfillmentData, channelFulfillmentDataLoading } = useTransactionsBreakdownDataContext()

  const colors = useMemo(() => {
    return [
      '#196E82', // Darkest
      // '#1A7894',
      '#728492',
      '#A3AEB7',
      '#B1BCC5',
      '#CFD8E0',
      '#D6DFE6',
      '#EBECF2',
      '#F3F4F9' // Lightest
    ]
  }, [])

  const fulfillmentData = useMemo(() => {
    if (!channelFulfillmentData || !Array.isArray(channelFulfillmentData)) return []

    return channelFulfillmentData
      .filter((item) => (activeChannel ? item.channel === activeChannel.channel : true))
      .reduce(
        (acc, curr) => {
          if (typeof curr.values !== 'number') return acc
          const existingIndex = acc.findIndex((item) => item.originalName === curr.fulfillment)

          if (existingIndex !== -1) {
            acc[existingIndex].value += curr.values
          } else {
            acc.push({
              name: curr.fulfillment.replaceAll('_', ' '),
              originalName: curr.fulfillment,
              value: curr.values
            })
          }

          return acc
        },
        [] as { name: string; originalName: string; value: number; color?: string }[]
      )
      .sort((a, b) => b.value - a.value)
      .map((item, index, arr) => {
        const sum = arr.reduce((acc, curr) => acc + curr.value, 0)
        return {
          ...item,
          percentage: (item.value / sum) * 100,
          color: colors[index]
        }
      })
  }, [activeChannel, channelFulfillmentData])

  return (
    <>
      <Box sx={{ position: 'relative', p: '15px', width: '100%', aspectRatio: '3 / 2', border: '1px solid #196E82', borderRadius: '4px' }}>
        <PieChart
          pies={[
            {
              data: fulfillmentData,
              dataKey: 'value',
              nameKey: 'name',
              innerRadius: '70%',
              outerRadius: '80%',
              cx: '50%',
              cy: '50%',
              fill: '#8884d8',
              paddingAngle: 0,
              isAnimationActive: false
            }
          ]}
          cells={fulfillmentData.map((item, index) => {
            return {
              fill: item.color,
              style: { cursor: 'pointer' }
            }
          })}
          options={{
            tooltip: true,
            legend: true
          }}
          configs={{
            tooltipConfig: {
              formatter: (value: number) => `${formatCurrency(value, { maxFractionDigits: 1 })}`
            },
            legendConfig: {
              align: 'right',
              verticalAlign: 'top',
              layout: 'vertical',
              formatter: (value, entry, index) => {
                return (
                  <span style={{ textTransform: 'capitalize' }}>
                    {value?.toLowerCase()} ({formatCurrency(fulfillmentData[index].value, { maxFractionDigits: 1, notation: 'compact' })})
                  </span>
                )
              }
            }
          }}
        />
        {activeChannel && (
          <Box sx={{ p: '4px', borderRadius: '4px', border: '1px solid #E6E6E6E6', position: 'absolute', top: '15px', left: '15px' }}>
            <Typography sx={{ color: '#2F4A5A', fontWeight: 600, fontSize: '12px', lineHeight: '15px', textTransform: 'capitalize' }}>
              {activeChannel?.channel?.toLowerCase()} {formatNumber(activeChannel?.percentage, { maxFractionDigits: 2 })}%
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}
