import { captureException, captureMessage } from '@sentry/react'
import { POST_HOG_BASE_URL } from 'src/assets/configs/globalConst'
import { metricsService } from 'src/services/metrices'
import { sendSlackNotification } from 'src/utils/api'
import { getSession } from 'src/utils/functions/localStorage'
import { getFrontendAlertChannel } from 'src/utils/functions/slackSupport'

// Function to test Sentry error logging
function triggerTestError() {
  try {
    // Simulate an error
    throw new Error('Test error for Sentry setup validation!')
  } catch (error) {
    // Capture the error using Sentry
    captureException(error)

    // Log to console for confirmation
    console.log('Test error sent to Sentry:', error)
  }
}

// Function to test Sentry message logging
function triggerTestMessage() {
  // Capture a test message using Sentry
  captureMessage('Test message for Sentry setup validation!')
  // Log to console for confirmation
  console.log('Test message sent to Sentry')
}

function triggerTestSlackError() {
  const email = getSession('data')?.email
  const org = getSession('data')?.org
  sendSlackNotification({
    message: `Test Message to Slack`,
    channel: getFrontendAlertChannel(),
    title: `urls:
    \`<${window.location.href}|${window.location.href}>\`
    \`<${POST_HOG_BASE_URL}/person/${email}#activeTab=sessionRecordings|${email}>\`
    \`<${POST_HOG_BASE_URL}/groups/0/${org}|${org}>\`
    \`this is coming from the test page "/test-internal"\
    `
  })
}

const TestPage = (): JSX.Element => {
  return (
    <div>
      <h1>Testing Module </h1>
      <button onClick={triggerTestError}>Trigger Test Error</button>
      <button onClick={triggerTestMessage}>Trigger Test Message</button>
      <button onClick={() => metricsService.trackError('Test Error', 'TEST123', 'Test Module', 'This is a test error message')}>Track Test Error</button>
      <button onClick={() => metricsService.trackApiError('GET', '/test', 500, 'Test Error', 'Test Module', 1000)}>Track Test API Error</button>
      <button onClick={triggerTestSlackError}>Trigger slack errors as Internal Error</button>
      <button onClick={triggerTestSlackError}>Trigger slack errors as External Error</button>
    </div>
  )
}

export default TestPage
