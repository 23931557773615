import { Switch, SwitchProps, styled } from '@mui/material'

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme, size }) => {
  const scale = size === 'small' ? 0.75 : 1

  return {
    width: 42 * scale,
    height: 26 * scale,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2 * scale,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${16 * scale}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: `${6 * scale}px solid #fff`
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22 * scale,
      height: 22 * scale
    },
    '& .MuiSwitch-track': {
      borderRadius: (26 / 2) * scale,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }
})

export default IOSSwitch
