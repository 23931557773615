import { Interceptors } from '@LoopKitchen/heyapi-client/lib/core/OpenAPI'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import get from 'lodash/get'
import { metricsService } from 'src/services/metrices'
import { OpenAPI } from '..'
import { ABORT_CODE, ABORT_ERROR_NAME, ABORT_KEY, ABORT_SKIP_KEY, abortMap } from './constants'

export function createAbortKey(apiUrl: string): string {
  const abortKey = `${window.location.pathname}-${apiUrl}`
  return abortKey
}

export function skipAbortController<T extends Function>(serviceFunction: T): T {
  const newServiceFunc = (async (...args): Promise<any> => {
    OpenAPI.HEADERS = {
      [ABORT_SKIP_KEY]: 'skip'
    }
    const result = await serviceFunction?.apply(this, args)
    return result
  }) as Function
  return newServiceFunc as T
}

export function addAbortMiddleWare(apiUrl: string, func: Function): (...args: any[]) => Promise<any> {
  return async (...args): Promise<any> => {
    const targetAbortkey = createAbortKey(apiUrl)
    const prevHeaders = typeof OpenAPI.HEADERS === 'function' ? {} : OpenAPI.HEADERS
    OpenAPI.HEADERS = async () => {
      return {
        ...prevHeaders,
        [ABORT_KEY]: targetAbortkey
      }
    }

    const requestMiddleWare: Interceptors<AxiosRequestConfig>['_fns'][0] = (e) => {
      ;(e as any).requestTime = Date.now()

      try {
        const headers = e.headers ?? {}
        const hasSkipAbortKey = headers[ABORT_SKIP_KEY] === 'skip'

        if (!hasSkipAbortKey) {
          const currentAbortKey = headers[ABORT_KEY]

          // Only handle abortion if the current request matches our target API
          if (currentAbortKey === targetAbortkey) {
            const obj = abortMap.get(currentAbortKey) ?? {}
            const oldController = obj?.controller
            const controller = new AbortController()
            abortMap.set(currentAbortKey, { ...obj, controller })
            e.signal = controller.signal

            if (oldController?.signal.aborted === false) {
              oldController.abort()
            }
          }
        }
      } catch (err) {
        console.log(err)
      }

      return e
    }

    const responseMiddleware: Interceptors<AxiosResponse>['_fns'][0] = async (e) => {
      try {
        // Handle successful response
        const abortKey = createAbortKey(new URL(get(e, 'config.url', '')).pathname)
        if (abortMap.has(abortKey)) {
          abortMap.delete(abortKey)
        }

        metricsService.trackHttpLatency(
          e.config.method ?? 'unknown',
          e.config.url ?? 'unknown',
          e.status ?? 0,
          e.config.data ?? {},
          e.config.headers ?? {},
          e.config.params ?? {},
          Date.now() - (e.config as any).requestTime ?? 0
        )
        return e
      } catch (error: any) {
        // Handle error response
        if (error.config) {
          metricsService.trackApiError(
            error.config.method || 'unknown',
            error.config.url || 'unknown',
            error.status,
            error.response.data.error.type,
            error.config.module || 'unknown',
            Date.now() - (error.config as any).requestTime
          )
        }
        return Promise.reject(error)
      }
    }

    const resultFunc = async () => {
      try {
        const promise = func?.apply(this, args)
        const result = await promise
        console.log(3)
        return result
      } catch (err) {
        if (get(err, 'name', null) === ABORT_ERROR_NAME || get(err, 'code', null) === ABORT_CODE) {
          const obj = abortMap.get(targetAbortkey)
          if (obj && obj.promise) {
            return await obj.promise
          }
        }
        throw err
      }
    }

    OpenAPI.interceptors.request.use(requestMiddleWare)
    OpenAPI.interceptors.response.use(responseMiddleware as any)
    const resultPromise = resultFunc()

    const obj = abortMap.get(targetAbortkey) || {}
    abortMap.set(targetAbortkey, { ...obj, promise: resultPromise })

    const result = await resultPromise
    OpenAPI.interceptors.request.eject(requestMiddleWare)
    OpenAPI.interceptors.response.eject(responseMiddleware)
    return result
  }
}
