import { Paper } from '@LoopKitchen/loop-ui'
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import LogosCombined from 'src/assets/images/short_logos_combined.svg'
import DeltaCard from 'src/components/DeltaCard'
import PlatformLogo from 'src/components/PlatformLogo'
import { PlatformBreakdownSwitch } from 'src/components/Switches'
import TabPanel from 'src/components/TabPanel'
import { SectionLink, SectionLinkBtn } from 'src/components/mui/Reusable'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService, ExternalStatus } from 'src/services/openApiV2'
import { track } from 'src/utils/analytics/analytics'
import { Modules } from 'src/utils/config/config'
import { debounce, get } from 'src/utils/config/lodashUtils'
import { formatCurrency } from 'src/utils/functions'
import ErrorsRevenueRecapture from '../Tabs/ErrorsRevenueRecapture'
import StatusLogo from './StatusLogo'

type ChargebackOverviewCompProps =
  | {
      tabValue: 'overview'
      setTabValue: React.Dispatch<React.SetStateAction<'overview'>>
    }
  | {}

export default function ChargebackOverviewComp(props: ChargebackOverviewCompProps) {
  const { currentUser } = useAuth()
  const { getFilters, getFiltersV2 } = useFilter()
  const [tabValue, setTabValue] = 'tabValue' in props ? [props.tabValue, props.setTabValue] : React.useState<'overview'>('overview')
  const [loading, setLoading] = React.useState(false)
  // const [disputeResponse, setDisputeResponse] = React.useState<DisputeResponse>(null)
  const { handleError } = useErrorData()
  const [showPlatformSummary, setShowPlatformSummary] = React.useState(false)

  const [summaryData, setSummaryData] = React.useState(null)
  const [summaryLoading, setSummaryLoading] = React.useState(false)

  const { gridSize, columns } = React.useMemo(() => {
    if (!summaryData || !get(summaryData, 'self_disputable', false)) {
      return {
        columns: 12,
        gridSize: {
          xs: 12,
          md: 6,
          lg: 3
        }
      }
    } else {
      return {
        columns: 10,
        gridSize: {
          xs: 10,
          md: 5,
          lg: 2
        }
      }
    }
  }, [summaryData])

  const getSummaryData = async () => {
    setSummaryLoading(true)
    try {
      const filters = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'], true)

      const orderSummaryResult = await DefaultService.getChargebackOrdersSummaryV3ChargebacksOrdersSummaryV3Post({
        requestBody: {
          ...filters,
          external_status_in: '',
          order_state_in: 'CHARGEBACK|LOST_SALE|REVERSAL'
        }
      })

      setSummaryData(orderSummaryResult)
    } catch (err) {
      handleError(err.message)
    }
    setSummaryLoading(false)
  }

  const customDeltaCard = (label: string, keys: ExternalStatus[], loading?: boolean, tooltip?: string, desc?: string) => {
    const data_key =
      label === Modules.EnabledChargebacks
        ? label
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/^loop_/, '')
        : label.toLowerCase().replace(/\s+/g, '_')
    let title = summaryData ? summaryData[data_key]['total'] : 0

    const platforms = ['Doordash', 'UberEats', 'Grubhub'].map((platform) => ({
      name: platform.toLowerCase(),
      value: summaryData?.[data_key][platform] ?? 0
    }))

    return (
      <DeltaCard
        label={
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: '0.9rem' }}>
            <span style={{ fontSize: '16px' }}>{label} </span>
            {label == 'In Progress' && (
              <StatusLogo
                status={ExternalStatus.IN_PROGRESS}
                animate
              />
            )}
          </Stack>
        }
        // labelTooltip={tooltip || null}
        loading={loading}
        title={formatCurrency(title)}
        extraFields={
          label === Modules.WonDisputes
            ? [
                {
                  label: 'Attributed',
                  value: formatCurrency(get(summaryData || {}, `won_disputes[attributed]`, 0)),
                  valueSx: { fontSize: '12px' },
                  labelSx: { fontWeight: 500 }
                },
                {
                  label: 'Unattributed',
                  value: formatCurrency(get(summaryData || {}, `won_disputes[unattributed]`, 0)),
                  valueSx: { fontSize: '12px' },
                  labelSx: { fontWeight: 500 }
                }
              ]
            : []
        }
        removeMaxWidth
        extraComp={
          <>
            {showPlatformSummary && (
              <Stack
                direction="column"
                spacing={1}
                p={1}>
                {platforms.map((item) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    key={item.name}>
                    <PlatformLogo
                      platformName={item.name}
                      height={'25px'}
                      width={'25px'}
                      shortImg
                      boxSx={{ flex: '0' }}
                    />
                    {loading ? (
                      <Skeleton
                        width="50%"
                        height="18px"
                      />
                    ) : (
                      <Typography
                        variant="subtitle2"
                        color="rgba(0,0,0,0.5)">
                        {formatCurrency(item.value)}
                      </Typography>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
            <Box
              sx={{
                display: 'flex',
                height: '60px',
                alignItems: 'flex-end'
              }}>
              {desc && desc.length && (
                <Typography
                  sx={{
                    fontWeight: 200,
                    lineHeight: '1.15em',
                    fontSize: '0.70rem',
                    color: '#00000080',
                    padding: '8px 12px 0px 12px',
                    whiteSpace: 'pre-line'
                  }}>
                  {desc}
                </Typography>
              )}
            </Box>
          </>
        }
        sx={{ px: '10px', py: '8px' }}
      />
    )
  }

  React.useEffect(() => {
    track('chargebacks', currentUser.org, currentUser.email.replace('@', '-'))
  }, [])

  React.useEffect(() => {
    setSummaryLoading(true)
    const debouncedGetSummaryData = debounce(getSummaryData, 1000)
    debouncedGetSummaryData()
    return () => {
      debouncedGetSummaryData.cancel()
    }
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'])])

  return (
    <Box sx={{ background: 'white' }}>
      <Paper sx={{ m: 0, p: 0 }}>
        <SectionLink
          id="chargeback-summary"
          sx={{ mb: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            flexWrap="wrap">
            <Stack
              direction="row"
              alignItems={'center'}
              spacing={1}
              flex={1}>
              <Typography variant="h3">Summary</Typography>
              <SectionLinkBtn link="chargeback-summary" />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}>
              <Box
                sx={{
                  p: '8px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                  border: '1px solid #EDEDED',
                  borderRadius: '4px',
                  background: showPlatformSummary ? '#EDEDED' : '#FFF'
                }}
                onClick={() => {
                  setShowPlatformSummary(!showPlatformSummary)
                }}>
                <img
                  src={LogosCombined}
                  alt="logos combined"
                  style={{ width: '45px' }}
                />
                <Typography
                  fontSize="12px"
                  fontWeight={600}
                  mx="5px">
                  Platform breakdown
                </Typography>
                <PlatformBreakdownSwitch checked={showPlatformSummary} />
              </Box>
            </Stack>
          </Stack>

          <Grid
            container
            spacing={2}
            columns={columns}
            sx={{ mt: 2 }}>
            <Grid
              item
              {...gridSize}>
              {customDeltaCard(
                Modules.EnabledChargebacks,
                [
                  ExternalStatus.ACCEPTED,
                  ExternalStatus.DENIED,
                  ExternalStatus.IN_PROGRESS,
                  ExternalStatus.EXPIRED,
                  ExternalStatus.TO_BE_RAISED,
                  ExternalStatus.NOT_ELIGIBLE
                ],
                summaryLoading,
                `Total refundable amount across only Loop-enabled stores`,
                `Total refundable amount across only Loop-enabled locations`
              )}
            </Grid>
            {get(summaryData, 'self_disputable', false) && (
              <Grid
                item
                {...gridSize}>
                {customDeltaCard('To be Raised', [ExternalStatus.TO_BE_RAISED], summaryLoading)}
              </Grid>
            )}
            <Grid
              item
              {...gridSize}>
              {customDeltaCard('In Progress', [ExternalStatus.IN_PROGRESS], summaryLoading)}
            </Grid>
            <Grid
              item
              {...gridSize}>
              {customDeltaCard(
                Modules.WonDisputes,
                [ExternalStatus.ACCEPTED],
                summaryLoading,
                `Total amount including${Modules.LostSales.toLowerCase()} that Loop has fetched back!`,
                `Total amount including ${Modules.LostSales.toLowerCase()} that Loop has fetched back!`
              )}
            </Grid>
            <Grid
              item
              {...gridSize}>
              {customDeltaCard('Denied', [ExternalStatus.DENIED], summaryLoading)}
            </Grid>
          </Grid>
        </SectionLink>
      </Paper>

      <Box my={'32px'}>
        <TabPanel
          index="overview"
          value={tabValue}>
          <ErrorsRevenueRecapture />
        </TabPanel>
      </Box>
    </Box>
  )
}
