import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// Chargebacks
const Chargebacks = lazy(() => import('src/pages/members/Chargebacks/Chargebacks'))
const ChargebacksTransactions = lazy(() => import('src/pages/members/ChargebacksTransactions/ChargebacksTransactions'))
const ChargebacksHistoryStoreView = lazy(() => import('src/pages/members/HistoryByStore/pages/ChargebacksHistoryStoreView/ChargebacksHistoryStoreView'))

const ChargebacksDetails = lazy(() => import('src/pages/members/ChargebacksDetails/ChargebacksDetails'))

export const existingRoutes = [
  { path: 'chargebacks', element: <Chargebacks tabValue="overview" /> },
  {
    path: 'chargebacks/overview',
    element: <Chargebacks tabValue="overview" />
  },
  { path: 'chargebacks/transactions', element: <ChargebacksTransactions /> },
  {
    path: 'chargebacks/payouts',
    element: (
      <Navigate
        replace
        to="/chargebacks/transactions"
      />
    )
  },
  {
    path: 'chargebacks/stores/view',
    element: <ChargebacksHistoryStoreView />
  },
  {
    path: 'chargebacks/matrix',
    element: <ChargebacksDetails />
  }
]

export const moduleNamedRoutes = [
  { path: 'recover', element: <Chargebacks tabValue="overview" /> },
  {
    path: 'recover/overview',
    element: <Chargebacks tabValue="overview" />
  },
  { path: 'recover/transactions', element: <ChargebacksTransactions /> },
  {
    path: 'recover/payouts',
    element: (
      <Navigate
        replace
        to="/recover/transactions"
      />
    )
  },
  {
    path: 'recover/stores/view',
    element: <ChargebacksHistoryStoreView />
  },
  {
    path: 'recover/matrix',
    element: <ChargebacksDetails />
  }
]

export const recoverRoutes = [...existingRoutes, ...moduleNamedRoutes]
