import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { MouseEventHandler } from 'react'
import { PosPayouts } from 'src/services/openApiV2'
import PayoutDetail from './PayoutDetail'

export default function PayoutDetailDrawer(props: { onClose?: MouseEventHandler<HTMLButtonElement>; payout_line_item: PosPayouts }) {
  return (
    <Box
      sx={{
        px: 4,
        pt: 2
      }}>
      <IconButton onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
      <PayoutDetail payout_line_item={props.payout_line_item} />
    </Box>
  )
}
