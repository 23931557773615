import { metricsService } from 'src/services/metrices'
import { sendSlackNotification } from '../api'
import { getSession } from './localStorage'

export function getFrontendAlertChannel(): string {
  const userData = getSession('data')
  if (userData?.internal) {
    metricsService.trackError('alert', 'frontend-alerts-internal')
    return 'frontend-alerts-internal'
  }
  metricsService.trackError('alert', 'frontend-alerts')
  return 'frontend-alerts'
}

export function downloadSuccessSlackAlert(obj: { apiUrl?: string; procedureName?: string; title?: string }) {
  const { apiUrl, procedureName, title } = obj

  const { email = undefined, org = undefined } = (() => {
    try {
      const data = getSession('data')
      return data || {}
    } catch (err) {
      return {}
    }
  })()

  sendSlackNotification({
    message: 'Download CSV success',
    channel: 'fe-logs',
    title:
      `Download success for\n` +
      (org ? `\`org: ${org}\`\n` : ``) +
      (email ? `\`user: ${email}\`\n` : ``) +
      `\`page URL: <${window.location.href}|${window.location.pathname}>\`\n` +
      (title ? `\`Table title: ${title}\`\n` : ``) +
      (apiUrl ? `\`API URL: ${apiUrl}\`\n` : ``) +
      (procedureName ? `\`Procedure Name: ${procedureName}\`` : ``)
  })
}

export function downloadFailedSlackAlert(obj: { apiUrl?: string; err: Error; procedureName?: string }) {
  const { apiUrl, err, procedureName } = obj

  const { email = undefined, org = undefined } = (() => {
    try {
      const data = JSON.parse(getSession('data'))
      return data || {}
    } catch (err) {
      return {}
    }
  })()

  sendSlackNotification({
    message: 'Download CSV failed',
    channel: getFrontendAlertChannel(),
    title:
      `Download failed for\n` +
      (org ? `\`org: ${org}\`\n` : ``) +
      (email ? `\`user: ${email}\`\n` : ``) +
      `\`page URL: <${window.location.href}|${window.location.pathname}>\`\n` +
      `\`error message: ${err.message}\`\n` +
      (apiUrl ? `\`API URL: ${apiUrl}\`\n` : ``) +
      (procedureName ? `\`Procedure Name: ${procedureName}\`` : ``)
  })
}
