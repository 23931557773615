import { lazy } from 'react'

// Marketing New UI
const MarketingNew = lazy(() => import('src/pages/members/MarketingNew/Marketing'))
const MarketingCampaignManagement = lazy(() => import('src/pages/members/MarketingCampaignManagement'))
const MarketingCompetitionAnalysis = lazy(() => import('src/pages/members/MarketingCompetitionAnalysis'))
const MarketingEvaluation = lazy(() => import('src/pages/members/MarketingNew/MarketingEvaluation'))
const MarketingTrends = lazy(() => import('src/pages/members/MarketingPages/Trends/Trends'))
const MarketingStoreMetrics = lazy(() => import('src/pages/members/MarketingPages/StoreMetrics/StoreMetrics'))
const MarketingCampaignMetrics = lazy(() => import('src/pages/members/MarketingPages/CampaignMetrics/CampaignMetrics'))
const MarketingConversionMetrics = lazy(() => import('src/pages/members/MarketingPages/ConversionMetrics/ConversionMetrics'))
const MarketingCompetitiveAnalysisItems = lazy(() => import('src/pages/members/MarketingPages/CompetitionAnalysisItems/CompetitionAnalysisItems'))
const MarketingCompetitiveAnalysisStores = lazy(() => import('src/pages/members/MarketingPages/CompetitionAnalysisStores/CompetitionAnalysisStores'))
const MarketingStoresRanking = lazy(() => import('src/pages/members/MarketingPages/StoresRanking/StoresRanking'))
const MarketingCOGS = lazy(() => import('src/pages/members/MarketingNew/Cogs'))
const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))
const MarketingCustomerRetention = lazy(() => import('src/pages/members/CustomerRetention/CustomerRetention'))

export const truRoiRoutes = [
  { path: 'marketing/new', element: <MarketingNew /> },
  {
    path: 'marketing/campaign/management',
    element: <MarketingCampaignManagement />
  },
  {
    path: 'marketing/competition/analysis',
    element: <MarketingCompetitionAnalysis />
  },
  {
    path: 'marketing/evaluation',
    element: <MarketingEvaluation />
  },
  {
    path: 'marketing/trends',
    element: <MarketingTrends />
  },
  {
    path: 'marketing/cogs',
    element: <MarketingCOGS />
  },
  {
    path: 'marketing/store_metrics',
    element: <MarketingStoreMetrics />
  },
  {
    path: 'marketing/campaign_metrics',
    element: <MarketingCampaignMetrics />
  },
  {
    path: 'marketing/conversion_metrics',
    element: <MarketingConversionMetrics />
  },
  {
    path: 'marketing/competition_analysis_items',
    element: <MarketingCompetitiveAnalysisItems />
  },
  {
    path: 'marketing/competition_analysis_stores',
    element: <MarketingCompetitiveAnalysisStores />
  },
  {
    path: 'marketing/stores_ranking',
    element: <MarketingStoresRanking />
  },
  {
    path: 'benchmarks/loop-benchmarks',
    element: <Benchmarks />
  },
  {
    path: 'marketing/customer_retention',
    element: <MarketingCustomerRetention />
  }
]
