import { Box, Paper, Tab, Tabs } from '@mui/material'
import React from 'react'
import TabPanel from 'src/components/TabPanel'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { DefaultService } from 'src/services/openApiV2'
import { get } from 'src/utils/config/lodashUtils'
import useTabUrlState from 'src/utils/hooks/useTabUrlState'
import CampaignMetrics from '../MarketingPages/CampaignMetrics/components/CampaignMetricsComp'
import ConversionMetrics from '../MarketingPages/ConversionMetrics/components/ConversionMetricsComp'
import StoreMetrics from '../MarketingPages/StoreMetrics/components/StoreMetricsComp'
import Overview from '../MarketingPages/Trends/components/Overview'
import RecommendedCampaigns from './tabs/RecommendedCampaigns'

interface MarketingBodyCompProps {
  onTabChange?: (tab: string) => void
}

export default function MarketingBodyComp(props: MarketingBodyCompProps) {
  const { onTabChange } = props
  const [tabValue, setTabValue] = useTabUrlState('tab_value', 'overview')
  const { getFilters, getFiltersV2 } = useNewFilterContext()
  const [showRecommendedCampaigns, setShowRecommendedCampaigns] = React.useState(false)

  React.useEffect(() => {
    const getRecommendedCampaigns = async () => {
      try {
        const res = await DefaultService.getRecommendedCampaignsGetRecommendedCampaignsPost({
          campaignType: 'ALL',
          requestBody: getFiltersV2(['chain'], true),
          limit: 10,
          offset: 0
        })
        setShowRecommendedCampaigns(get(res, 'data.length', 0) > 0)
      } catch (err) {
        setShowRecommendedCampaigns(false)
      }
    }
    getRecommendedCampaigns()
  }, [...getFilters(['chain'])])

  // const getQueryData = async () => {
  //   setQueryLoading(true)
  //   try {
  //     const res = await DefaultService.callMarketingQueryApiMarketingQueryPost(
  //       getFiltersV2(
  //         [
  //           'vb_name',
  //           'chain',
  //           'b_name',
  //           'vb_platform',
  //           'am_name',
  //           'start_date',
  //           'end_date'
  //         ],
  //         true
  //       )
  //     )
  //     setQueryData(res)
  //   } catch (err) {
  //     console.log('query error: ', err)
  //   }
  //   setQueryLoading(false)
  // }

  React.useEffect(() => {
    if (onTabChange) {
      onTabChange(tabValue)
    }
  }, [tabValue])

  return (
    <>
      <Paper sx={{ pb: 0, mt: 0, pt: '20px', mx: '36px' }}>
        <Tabs
          value={tabValue}
          onChange={(e, v) => {
            setTabValue(v)
          }}>
          <Tab
            label="OVERVIEW"
            value="overview"
            sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
          />
          <Tab
            label="CAMPAIGN METRICS"
            value="campaign_metrics"
            sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
          />
          <Tab
            label="STORE METRICS"
            value="store_metrics"
            sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
          />
          <Tab
            label="CONVERSION METRICS"
            value="conversion_metrics"
            sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
          />
          {showRecommendedCampaigns && (
            <Tab
              label="RECOMMENDED CAMPAIGNS"
              value="recommended_campaigns"
              sx={{ borderBottom: '2px solid rgba(186, 189, 204, 1)' }}
            />
          )}
        </Tabs>
      </Paper>
      <Box sx={{ bgcolor: 'white' }}>
        <Box sx={{ mx: '36px', my: '26px' }}>
          <Box>
            <TabPanel
              index="overview"
              value={tabValue}>
              <Overview />
            </TabPanel>
            <TabPanel
              index="campaign_metrics"
              value={tabValue}>
              <CampaignMetrics />
            </TabPanel>
            <TabPanel
              index="store_metrics"
              value={tabValue}>
              <StoreMetrics />
            </TabPanel>
            <TabPanel
              index="conversion_metrics"
              value={tabValue}>
              <ConversionMetrics />
            </TabPanel>
            {showRecommendedCampaigns && (
              <TabPanel
                index="recommended_campaigns"
                value={tabValue}>
                <RecommendedCampaigns />
              </TabPanel>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
