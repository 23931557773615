import { lazy } from 'react'
import TestPage from 'src/pages/members/Test/TestPage'

//ORDER ACCURACY

const VideoUpload = lazy(() => import('src/pages/members/video/VideoUpload'))
const DisputableOrders = lazy(() => import('src/pages/members/video/DisputableOrders'))
const WaitingPage = lazy(() => import('src/pages/public/waiting/WaitingPage'))

// Using GPT
const SearchDatabase = lazy(() => import('src/components/ChatGPTComponents/SearchDatabase'))
// Using GPT
const AskQuestion = lazy(() => import('src/pages/members/Ask/AskQuestion'))

const Redirect = lazy(() => import('src/pages/members/Redirect/Redirect'))

export const coreRoutes = [
  { path: 'create-account/wait', element: <WaitingPage /> },
  { path: '/ask', element: <AskQuestion /> },
  { path: 'search', element: <SearchDatabase /> },
  { path: 'redirect', element: <Redirect /> },
  { path: 'video', element: <VideoUpload /> },
  { path: 'video/:order', element: <DisputableOrders /> },
  { path: 'test-internal', element: <TestPage /> }
]
