import { Column, CustomMaterialTable, CustomMaterialTableProps } from '@LoopKitchen/loop-ui'
import { CsvBuilder } from 'filefy'
import { useErrorData } from 'src/context/ErrorContext'
import { useSnackData } from 'src/context/SnackContext'
import { ABORT_MESSAGE } from 'src/services/openApiV2/utils/constants'
import { get } from 'src/utils/config/lodashUtils'
import { formatNumber } from 'src/utils/functions'
import processCSV from 'src/utils/workers/functions/processCSV'
import CustomDownloadBtn from './components/CustomDownloadBtn'
import { RenderFunctionType } from './components/ModifyCSVDialog'

export type CustomColumns<T> = Column<T> & { hideInExport?: boolean }

type CustomMaterialTableWrapperProps<T extends object> = Omit<CustomMaterialTableProps<T>, 'columns' | 'options'> & {
  columns: CustomColumns<T>[]
  onExportModifyCSV?: (data: T[]) => Promise<string | { [key: string]: string | number }[]>
  // onExportModifyCSV?: (data: T[]) => Promise<string>
  options?: CustomMaterialTableProps<T>['options'] & {
    extraExportColumns?: { title: string; field: string; hideInExport?: boolean; exportRender?: RenderFunctionType }[]
  }
}

export default function CustomMaterialTableWrapper<T extends object>(props: CustomMaterialTableWrapperProps<T>) {
  const { handleError } = useErrorData()
  const { setDownloadSnack } = useSnackData()

  const onExportModifyCSV = async () => {
    try {
      if (!props.onExportModifyCSV) {
        throw new Error('CSV data is not available')
      }
      setDownloadSnack({ status: 'close' })
      setDownloadSnack({ status: 'start' })
      const csvData = await props.onExportModifyCSV(props.data)
      const columns = props.columns
        .filter((e) => (e.hideInExport ? false : !e.hidden))
        .concat(get(props, 'options.extraExportColumns', []))
        .map((item) => {
          return {
            title: item.title,
            field: item.field,
            hidden: item.hideInExport
          }
        })
      processCSV({
        csvData,
        columns,
        onSuccess: (data) => {
          const fileName = get(props, 'options.csvFileName', 'data.csv')
          const builder = new CsvBuilder(fileName)
          builder.setColumns(data.headers)
          builder.addRows(data.rows)
          builder.exportFile()
          setDownloadSnack({
            status: 'complete',
            message:
              get(props, 'options.totalPaginatedDataLength', 0) > 100000
                ? `First ${formatNumber(100000, { maxFractionDigits: 0 })} records are downloaded`
                : undefined
          })
        },
        onError: (msg) => {
          console.log('error: ', msg)
        }
      })
    } catch (error) {
      if (error?.message === ABORT_MESSAGE) {
        return
      }
      setDownloadSnack({ status: 'close' })
      handleError(error.message)
    }
  }

  return (
    <>
      <CustomMaterialTable
        {...props}
        options={{
          ...props.options,
          export: props.onExportModifyCSV ? false : props.options?.export,
          extraToolbarComponent: (
            <>
              {props.options?.extraToolbarComponent}
              {props.options?.export && props.onExportModifyCSV && (
                <CustomDownloadBtn
                  onNormalExport={() => (props.onExportModifyCSV ? onExportModifyCSV() : props.onExportCSV?.call(null, props.columns, props.data))}
                  // onModifyExport={onExportModifyCSV}
                  loading={props.onExportCSV ? !!props?.options?.loadingExport : props.isLoading}
                />
              )}
            </>
          )
        }}
      />
    </>
  )
}
