import { MultiSelect, Paper } from '@LoopKitchen/loop-ui'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Chip, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import React, { useState } from 'react'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import {
  DefaultService,
  PosCardType,
  PosPayouts,
  PosPayoutsFiltersForQueryPaginated,
  PosPayoutsPaginated,
  PosPayoutsSummaryAggregate,
  PosSummaryFiltersForQuery
} from 'src/services/openApiV2'
import { get } from 'src/utils/config/lodashUtils'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import DeltaCard from '../../../components/DeltaCard'
import { exportCSV } from '../../../components/ExportCsvV2'
import { StringShortener } from '../../../components/StringShortener'
import Page from '../../../components/mui/Page'
import { useErrorData } from '../../../context/ErrorContext'
import { useFilter } from '../../../context/FilterContext'
import { useSnackData } from '../../../context/SnackContext'
import { formatCurrency } from '../../../utils/functions'
import usePostHogHook from '../../../utils/hooks/usePostHogHook'
import LoadingAnimation from '../dashboard/components/LoadingAnimation'
import PayoutDetailDrawer from './PayoutDetailDrawer'

export default function CardReconciliation() {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { trackPostHogDownload } = usePostHogHook()
  const { headerHeightPx } = useLayoutDimension()
  const [analyticsLoading, setAnalyticsLoading] = React.useState(false)
  const [dataLoading, setDataLoading] = React.useState(false)
  const [summaryData, setSummaryData] = React.useState<PosPayoutsSummaryAggregate>({})
  const [tableData, setTableData] = React.useState<PosPayoutsPaginated>(null)
  const [allCardTypes, setAllCardTypes] = React.useState<string[]>([])
  const [selectedCardTypes, setSelectedCardTypes] = React.useState<string[]>([])
  const [clickedPayout, setClickedPayout] = React.useState<PosPayouts>(null)
  const { setDownloadSnack } = useSnackData()
  const [filterLoading, setFilterLoading] = useState(false)
  const [clickedCard, setClickedCard] = useState('')
  const [filterBubble, setFilterBubble] = useState<{ visible: boolean; message: string }>({
    visible: false,
    message: ''
  })
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const handleClose = () => {
    setDrawerOpen(false)
  }

  const requestBodyForPayoutsGetter = (): PosPayoutsFiltersForQueryPaginated => {
    const requestBody: PosPayoutsFiltersForQueryPaginated = getFiltersV2(
      ['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'],
      true
    )
    requestBody.show_only_non_zero_bank_variance = clickedCard === 'non_zero_bank_variance'
    requestBody.show_only_payouts_without_bank_transactions = clickedCard === 'payout_bank_variance'
    requestBody.show_only_non_zero_transaction_variance = clickedCard === 'payout_transaction_variance'
    requestBody.card_types_to_include = selectedCardTypes.join('|')
    return requestBody
  }

  const getCardTypes = async () => {
    try {
      const data: Array<PosCardType> = await DefaultService.callPosListCardTypesApiPosListCardTypesPost({
        requestBody: {
          ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
        }
      })
      setAllCardTypes(data.map((cardType) => cardType.card_type))
    } catch (err) {
      handleError(err.message)
    }
  }

  const getTableData = async (obj: { limit: number; offset: number }, callback: (data: PosPayoutsPaginated) => void) => {
    setDataLoading(true)

    try {
      const limit = get(obj, 'limit', 100)
      const offset = get(obj, 'offset', 0)

      const res: PosPayoutsPaginated = await DefaultService.callPosListPayoutsApiPaginatedPosListPayoutsPost({
        requestBody: {
          ...requestBodyForPayoutsGetter(),
          limit: limit,
          offset: offset
        },
        isPaginated: true
      })

      callback(res)
    } catch (err) {
      handleError(err.body)
    }

    setDataLoading(false)
  }

  const getSummaryData = async () => {
    setAnalyticsLoading(true)
    try {
      const requestBody: PosSummaryFiltersForQuery = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
      requestBody.card_types_to_include = selectedCardTypes.join('|')
      const data: PosPayoutsSummaryAggregate = await DefaultService.callPosPayoutsSummaryApiPosPayoutsSummaryPost({ requestBody })
      setSummaryData(data)
      setAnalyticsLoading(false)
    } catch (err) {
      handleError(err.body)
      setAnalyticsLoading(false)
      setSummaryData(null)
    }
  }

  const onLastPage = () => {
    const next_offset = get(tableData, 'next_offset', null)
    if (next_offset) {
      getTableData(
        {
          limit: 100,
          offset: next_offset
        },
        (data) => {
          setTableData((prev) => {
            return {
              ...data,
              data: [...get(prev, 'data', []), ...get(data, 'data', [])]
            }
          })
        }
      )
    }
  }

  React.useEffect(() => {
    getCardTypes()
  }, [])

  React.useEffect(() => {
    getSummaryData()
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date']), selectedCardTypes])

  React.useEffect(() => {
    getTableData({ limit: 100, offset: 0 }, (data) => {
      setTableData(data)
    })
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date']), clickedCard, selectedCardTypes])

  return (
    <>
      {filterLoading && <LoadingAnimation />}
      {!filterLoading && (
        <>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleClose}
            sx={{
              width: '80%',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '80%',
                boxSizing: 'border-box'
              }
            }}>
            <PayoutDetailDrawer
              onClose={handleClose}
              payout_line_item={clickedPayout}
            />
          </Drawer>
          <Page
            title="POS Card Reconciliation"
            sx={{ bgcolor: 'white' }}
            filterProps={{
              hideFilterKeys: ['vb_name', 'am_name', 'vb_platform'],
              hideResetButton: true,
              extraFilters: [
                <MultiSelect
                  label="Card type"
                  selectButtonSx={{
                    p: '12px 32px 12px 8px'
                  }}
                  disableScrollLock
                  options={allCardTypes.map((cardType) => ({ label: cardType, value: cardType }))}
                  value={selectedCardTypes.length === 0 ? allCardTypes : selectedCardTypes}
                  onChange={(value) => {
                    setSelectedCardTypes(value)
                  }}
                />
              ],
              onResetCallback: () => {
                setSelectedCardTypes([])
              }
            }}>
            <Paper>
              <Typography
                variant="h3"
                component="h3"
                marginBottom="2rem">
                Summary
              </Typography>
              <Box
                display="flex"
                gap="0.5rem"
                marginTop={'1rem'}
                flexWrap="wrap">
                <DeltaCard
                  title={`${formatCurrency(summaryData.payout_amount)}`}
                  label="Total Payout"
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('payout_amount')
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(summaryData.payout_transaction_variance)}`}
                  label="Unaccounted"
                  labelTooltip={'Variance between transactions and payouts are reported by POS'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('payout_transaction_variance')
                    setFilterBubble({ visible: true, message: 'Showing only payouts with non zero transaction variance' })
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(summaryData.payout_bank_variance)}`}
                  label="Bank Variance"
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('payout_bank_variance')
                    setFilterBubble({ visible: true, message: 'Showing only payouts which did not match with the bank' })
                  }}
                />
              </Box>
            </Paper>

            {filterBubble.visible && (
              <Box p={4}>
                <Chip
                  label={<Typography variant="body1">{filterBubble.message}</Typography>}
                  onDelete={() => {
                    setFilterBubble({ visible: false, message: '' })
                    setClickedCard('')
                  }}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                  color="primary"
                  style={{
                    marginBottom: '1rem' // spacing below the chip
                  }}
                />
              </Box>
            )}

            <Box
              mb={4}
              p={4}>
              <CustomMaterialTableWrapper
                columns={[
                  {
                    title: 'Location',
                    field: 'location'
                  },
                  {
                    title: 'Payout ID',
                    field: 'payout_id',
                    // TODO: pass platform in API response and remove this hardcoding
                    render: (rowData: PosPayouts) => (
                      <StringShortener
                        str={rowData.payout_id}
                        platform={rowData?.pos}
                        allowClickToCopy={false}
                        // displayLength={if(rowData?.pos == 'crunchtime')?15:6}
                        displayLength={rowData?.pos == 'crunchtime' ? 40 : 6}
                      />
                    )
                  },
                  {
                    title: 'Start Date',
                    field: 'first_transaction_date'
                  },
                  {
                    title: 'End Date',
                    field: 'last_transaction_date'
                  },
                  {
                    title: 'Payout Amount',
                    field: 'payout_amount',
                    render: (rowData: PosPayouts) => formatCurrency(rowData.payout_amount)
                  },
                  {
                    title: 'Unaccounted',
                    field: 'payout_transaction_variance',
                    render: (rowData: PosPayouts) => formatCurrency(rowData.payout_transaction_variance)
                  },
                  {
                    title: 'Bank Amount',
                    field: 'bank_amount',
                    render: (rowData: PosPayouts) => formatCurrency(rowData.bank_amount)
                  },
                  {
                    title: 'Bank recognition date',
                    field: 'bank_date'
                  }
                ]}
                data={tableData?.data || []}
                isLoading={dataLoading}
                options={{
                  stickyHeader: headerHeightPx,
                  export: true,
                  search: false,
                  pagination: true,
                  toolbarSx: { pb: 2, pt: 0, pl: 0 },
                  totalPaginatedDataLength: tableData?.max_rows || 0,
                  debounceMilliseconds: 1500
                }}
                onLastPage={onLastPage}
                onExportModifyCSV={() => DefaultService.exportPosListPayoutsApiExportPosListPayoutsPost({ requestBody: requestBodyForPayoutsGetter() }) as any}
                onExportCSV={() => {
                  exportCSV({
                    apiCall: DefaultService.exportPosListPayoutsApiExportPosListPayoutsPost,
                    requestBody: requestBodyForPayoutsGetter(),
                    onStart: () => setDownloadSnack({ status: 'start' }),
                    onComplete: () => {
                      setDownloadSnack({ status: 'complete' })
                      downloadSuccessSlackAlert({ apiUrl: '/api/export/pos/list_payouts' })
                    },
                    onError: (errMessage: string) => {
                      setDownloadSnack({ status: 'close' })
                      handleError(errMessage)
                      downloadFailedSlackAlert({ err: new Error(errMessage), apiUrl: '/api/export/pos/list_payouts' })
                    },
                    trackDownload: (details: { fileName: string; type: 'CSV' | 'PDF' }) => {
                      trackPostHogDownload(details)
                    }
                  })
                }}
                onRowClick={(row: PosPayouts) => {
                  setClickedPayout(row)
                  setDrawerOpen(true)
                }}
              />
            </Box>
          </Page>
        </>
      )}
    </>
  )
}
