import React from 'react'
import {
  AdsDetailsDrillDownBreakDownOptions,
  DefaultService,
  MarketingDrillDownBreakDownOptions,
  MetricsKPIDrillDownBreakDownOptions,
  ThirdPartyFeeDrillDownBreakDownOptions
} from 'src/services/openApiV2'
import componentsObject from './componentsObject'

export const BreakdownEnums = { ...MetricsKPIDrillDownBreakDownOptions, ...MarketingDrillDownBreakDownOptions }

export type BreakdownEnumsType =
  | MetricsKPIDrillDownBreakDownOptions
  | MarketingDrillDownBreakDownOptions
  | ThirdPartyFeeDrillDownBreakDownOptions
  | AdsDetailsDrillDownBreakDownOptions

export type FiltersObjType = { key: BreakdownEnumsType; value: string[] }

type APIFunctions = {
  [K in keyof typeof DefaultService]: (typeof DefaultService)[K] extends (...args: any[]) => void ? K : never
}[keyof typeof DefaultService]

type JSONDataType = {
  title: string
  mainBreakdownOptions: {
    icon: string | JSX.Element
    label: string
    value: BreakdownEnumsType
    columns: {
      title: string
      field: string
      type?: 'number' | 'percentage' | 'currency' | 'date' | 'time_period_formatter'
      style?: 'bold' | 'normal'
      deltaType?: 'positive_delta_green_color' | 'positive_delta_red_color'
    }[]
  }[]
  apiFunctions: {
    tableData: APIFunctions
    dateRangeCompareData: APIFunctions
    filterData: APIFunctions
    exportData: APIFunctions
  }
  tabs?: { [key: string]: string | React.ReactNode }
  finalComponentKey?: keyof typeof componentsObject
}

export default JSONDataType
