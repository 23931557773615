import { SvgIcon, SvgIconProps } from '@mui/material'

export default function FranchiseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <mask
          id="mask0_0_2437"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18">
          <rect
            x="0.530273"
            y="0.79187"
            width="16.5742"
            height="16.5742"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_0_2437)">
          <path
            d="M3.29256 10.4601C2.91274 10.4601 2.58758 10.3249 2.3171 10.0544C2.04662 9.78394 1.91138 9.45879 1.91138 9.07896C1.91138 8.69914 2.04662 8.37398 2.3171 8.1035C2.58758 7.83302 2.91274 7.69778 3.29256 7.69778C3.67239 7.69778 3.99754 7.83302 4.26802 8.1035C4.53851 8.37398 4.67375 8.69914 4.67375 9.07896C4.67375 9.45879 4.53851 9.78394 4.26802 10.0544C3.99754 10.3249 3.67239 10.4601 3.29256 10.4601ZM4.43204 14.3965L3.46521 13.4297L6.46929 10.4256L7.43612 11.3924L4.43204 14.3965ZM6.50382 7.69778L3.49974 4.6937L4.46657 3.72687L7.47065 6.73095L6.50382 7.69778ZM8.8173 15.9849C8.43748 15.9849 8.11232 15.8496 7.84184 15.5792C7.57136 15.3087 7.43612 14.9835 7.43612 14.6037C7.43612 14.2239 7.57136 13.8987 7.84184 13.6282C8.11232 13.3578 8.43748 13.2225 8.8173 13.2225C9.19713 13.2225 9.52228 13.3578 9.79276 13.6282C10.0632 13.8987 10.1985 14.2239 10.1985 14.6037C10.1985 14.9835 10.0632 15.3087 9.79276 15.5792C9.52228 15.8496 9.19713 15.9849 8.8173 15.9849ZM8.8173 4.93541C8.43748 4.93541 8.11232 4.80017 7.84184 4.52969C7.57136 4.2592 7.43612 3.93405 7.43612 3.55422C7.43612 3.1744 7.57136 2.84924 7.84184 2.57876C8.11232 2.30828 8.43748 2.17304 8.8173 2.17304C9.19713 2.17304 9.52228 2.30828 9.79276 2.57876C10.0632 2.84924 10.1985 3.1744 10.1985 3.55422C10.1985 3.93405 10.0632 4.2592 9.79276 4.52969C9.52228 4.80017 9.19713 4.93541 8.8173 4.93541ZM11.1308 7.73231L10.164 6.73095L13.2026 3.72687L14.1694 4.6937L11.1308 7.73231ZM13.2026 14.3965L10.1985 11.3924L11.1653 10.4256L14.1694 13.4297L13.2026 14.3965ZM14.342 10.4601C13.9622 10.4601 13.6371 10.3249 13.3666 10.0544C13.0961 9.78394 12.9609 9.45879 12.9609 9.07896C12.9609 8.69914 13.0961 8.37398 13.3666 8.1035C13.6371 7.83302 13.9622 7.69778 14.342 7.69778C14.7219 7.69778 15.047 7.83302 15.3175 8.1035C15.588 8.37398 15.7232 8.69914 15.7232 9.07896C15.7232 9.45879 15.588 9.78394 15.3175 10.0544C15.047 10.3249 14.7219 10.4601 14.342 10.4601Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
