import { Config, RecommendedCampaignModel } from 'src/services/openApiV2'
import { isEqual } from 'src/utils/config/lodashUtils'

export default class LocalStorage {
  static active_local_storage_key = 'active_campaign_waiting_list'
  static recommended_local_storage_key = 'recommended_campaign_waiting_list'

  static waiting_time = 1000 * 60 * 5

  static addActiveCampaignID = (campaign_id: string) => {
    let str = localStorage.getItem(this.active_local_storage_key)

    let arr: { time: number; campaign_id: string }[] = str ? JSON.parse(str) : []

    let obj = arr.findIndex((e) => e.campaign_id === campaign_id)

    if (obj < 0) {
      arr.push({
        time: Date.now(),
        campaign_id
      })
      localStorage.setItem(this.active_local_storage_key, JSON.stringify(arr))
    }
  }

  static isActiveCampaignIDAvailable = (campaign_id: string) => {
    let str = localStorage.getItem(this.active_local_storage_key)

    let arr: { time: number; campaign_id: string }[] = str ? JSON.parse(str) : []

    arr = arr.filter((e) => {
      return Date.now() - e.time < this.waiting_time
    })

    let index = arr.findIndex((e) => e.campaign_id === campaign_id)

    return index >= 0
  }

  static removeOldActiveCampaignIDs = () => {
    let str = localStorage.getItem(this.active_local_storage_key)

    let arr: { time: number; campaign_id: string }[] = str ? JSON.parse(str) : []

    let result: typeof arr = []

    arr.forEach((obj) => {
      let diff = Date.now() - obj.time
      if (diff < this.waiting_time) {
        result.push({ ...obj })
      }
    })
    if (result.length > 0) {
      localStorage.setItem(this.active_local_storage_key, JSON.stringify(result))
    } else {
      localStorage.removeItem(this.active_local_storage_key)
    }
  }

  static addRecommendedConfig = (model: RecommendedCampaignModel) => {
    let str = localStorage.getItem(this.recommended_local_storage_key)

    let arr: { time: number; config: Config }[] = str ? JSON.parse(str) : []

    let index = arr.findIndex((e) => isEqual(e.config, model.config))

    if (index < 0) {
      arr.push({
        time: Date.now(),
        config: model.config
      })
      localStorage.setItem(this.recommended_local_storage_key, JSON.stringify(arr))
    }
  }

  static isRecommendedConfigAvailable = (model: RecommendedCampaignModel) => {
    let str = localStorage.getItem(this.recommended_local_storage_key)

    let arr: { time: number; config: Config }[] = str ? JSON.parse(str) : []

    arr = arr.filter((e) => {
      return Date.now() - e.time < this.waiting_time
    })

    let index = arr.findIndex((e) => isEqual(e.config, model.config))

    return index >= 0
  }

  static removeOldRecommendedConfig = () => {
    let str = localStorage.getItem(this.recommended_local_storage_key)

    let arr: { time: number; config: Config }[] = str ? JSON.parse(str) : []

    let result: typeof arr = []

    arr.forEach((obj) => {
      let diff = Date.now() - obj.time
      if (diff < this.waiting_time) {
        result.push({ ...obj })
      }
    })

    if (result.length > 0) {
      localStorage.setItem(this.recommended_local_storage_key, JSON.stringify(result))
    } else {
      localStorage.removeItem(this.recommended_local_storage_key)
    }
  }
}
