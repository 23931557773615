import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Fade from '@mui/material/Fade'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'
import circleImg from 'src/assets/circle.png'
import { useGlobalDialogData } from 'src/context/GlobalDialogContext'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return (
    <Fade
      timeout={3000}
      ref={ref}
      {...props}
    />
  )
})

export default function CustomDialog(): JSX.Element {
  const { globalDialog, setGlobalDialog } = useGlobalDialogData()

  const handleClose = () => {
    setGlobalDialog({ open: false, text: '' })
  }

  return (
    <Dialog
      open={globalDialog.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
      fullWidth>
      {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
      <DialogContent sx={{ textAlign: 'center' }}>
        <img
          src={circleImg}
          alt="circle"
          style={{
            width: 140,
            height: 140,
            margin: 'auto',
            marginBottom: 16
          }}
        />
        <DialogContentText id="alert-dialog-slide-description">{globalDialog.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
