import { MetricsKPIDrillDownBreakDownOptions } from 'src/services/openApiV2'
import { filterIcons, filterNames } from 'src/utils/config/config'
import JSONDataType from '../types'

const commonColumnsArr: JSONDataType['mainBreakdownOptions'][0]['columns'] = [
  { title: 'Total Sales', field: 'total_sales', type: 'currency', deltaType: 'positive_delta_green_color' }
]

const jsonData: JSONDataType = {
  title: 'Sales',
  mainBreakdownOptions: [
    {
      icon: filterIcons.b_name,
      label: filterNames.b_name,
      value: MetricsKPIDrillDownBreakDownOptions.B_NAME,
      columns: [{ title: filterNames.b_name, field: 'b_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.slug,
      label: filterNames.slug,
      value: MetricsKPIDrillDownBreakDownOptions.SLUG,
      columns: [{ title: filterNames.slug, field: 'slug' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_name,
      label: filterNames.vb_name,
      value: MetricsKPIDrillDownBreakDownOptions.VB_NAME,
      columns: [{ title: filterNames.vb_name, field: 'vb_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_platform,
      label: filterNames.vb_platform,
      value: MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM,
      columns: [{ title: filterNames.vb_platform, field: 'vb_platform' }, ...commonColumnsArr]
    }
  ],
  apiFunctions: {
    tableData: 'callDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiPaginatedDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    dateRangeCompareData:
      'drillDownGeneratedMetricsKpiGroupedListOfEntriesDateRangeCompareApiDateRangeCompareDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    exportData: 'exportDrillDownGeneratedMetricsKpiGroupedListOfEntriesApiExportDrillDownGeneratedMetricsKpiGroupedListOfEntriesPost',
    filterData: 'callDrillDownGeneratedMetricsKpiUniqueValuesApiDrillDownGeneratedMetricsKpiUniqueValuesPost'
  },
  finalComponentKey: 'sales'
  // tabs: {
  //   Overview: 'Overview component'
  // }
}

export default jsonData
