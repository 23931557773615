import { MarketingDrillDownBreakDownOptions } from 'src/services/openApiV2'
import { filterIcons, filterNames } from 'src/utils/config/config'
import JSONDataType from '../types'

const commonColumnsArr: JSONDataType['mainBreakdownOptions'][0]['columns'] = [
  { title: 'Avg. ROI', field: 'avg_roi', type: 'number', deltaType: 'positive_delta_green_color' }
]

const jsonData: JSONDataType = {
  title: 'ROI',
  mainBreakdownOptions: [
    {
      icon: filterIcons.b_name,
      label: filterNames.b_name,
      value: MarketingDrillDownBreakDownOptions.B_NAME,
      columns: [{ title: filterNames.b_name, field: 'b_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.slug,
      label: filterNames.slug,
      value: MarketingDrillDownBreakDownOptions.SLUG,
      columns: [{ title: filterNames.slug, field: 'slug' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_name,
      label: filterNames.vb_name,
      value: MarketingDrillDownBreakDownOptions.VB_NAME,
      columns: [{ title: filterNames.vb_name, field: 'vb_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_platform,
      label: filterNames.vb_platform,
      value: MarketingDrillDownBreakDownOptions.VB_PLATFORM,
      columns: [{ title: filterNames.vb_platform, field: 'vb_platform' }, ...commonColumnsArr]
    }
  ],
  apiFunctions: {
    tableData: 'callDrillDownGeneratedMarketingGroupedListOfEntriesApiPaginatedDrillDownGeneratedMarketingGroupedListOfEntriesPost',
    dateRangeCompareData:
      'drillDownGeneratedMarketingGroupedListOfEntriesDateRangeCompareApiDateRangeCompareDrillDownGeneratedMarketingGroupedListOfEntriesPost',
    exportData: 'exportDrillDownGeneratedMarketingGroupedListOfEntriesApiExportDrillDownGeneratedMarketingGroupedListOfEntriesPost',
    filterData: 'callDrillDownGeneratedMarketingUniqueValuesApiDrillDownGeneratedMarketingUniqueValuesPost'
  }
}

export default jsonData
