import { lazy } from 'react'

const ReviewsAndRatings = lazy(() => import('src/pages/members/ReviewsAndRatings/ReviewsAndRatings'))
const ReviewsAndRatingsTrends = lazy(() => import('src/pages/members/RatingAndReviewsPages/Trends/Trends'))
const ReviewsAndRatingsStoreRatings = lazy(() => import('src/pages/members/RatingAndReviewsPages/StoreRatings/StoreRatings'))
const ReviewsAndRatingsReviews = lazy(() => import('src/pages/members/RatingAndReviewsPages/Reviews/Reviews'))

export const reEngageRoutes = [
  { path: 'reviews/ratings', element: <ReviewsAndRatings /> },
  { path: 'reviews/ratings/trends', element: <ReviewsAndRatingsTrends /> },
  { path: 'reviews/ratings/store_ratings', element: <ReviewsAndRatingsStoreRatings /> },
  { path: 'reviews/ratings/reviews', element: <ReviewsAndRatingsReviews /> }
]
