import { PieChart } from '@LoopKitchen/loop-ui'
import { Box, Skeleton } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { formatCurrency } from 'src/utils/functions'
import { useTransactionsBreakdownDataContext } from '../../../utils/context/TransactionsBreakdownDataContext'
import ActiveSection from './ActiveSection'

export type ClickChannelDataType = { channel: string; value: number; percentage: number }

interface ChannelPieChartProps {
  onChannelClick?: (obj: ClickChannelDataType) => void
}

export default function ChannelPieChart(props: ChannelPieChartProps) {
  const { onChannelClick } = props
  const { channelFulfillmentData, channelFulfillmentDataLoading } = useTransactionsBreakdownDataContext()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const colors = useMemo(() => {
    return [
      '#196E82', // Darkest
      // '#1A7894',
      '#728492',
      '#A3AEB7',
      '#B1BCC5',
      '#CFD8E0',
      '#D6DFE6',
      '#EBECF2',
      '#F3F4F9' // Lightest
    ]
  }, [])

  const channelChartData = useMemo(() => {
    if (!channelFulfillmentData || !Array.isArray(channelFulfillmentData)) return []

    const result = channelFulfillmentData
      .reduce(
        (acc, curr) => {
          if (typeof curr.values !== 'number') return acc
          const existingIndex = acc.findIndex((item) => item.channel === curr.channel)

          if (existingIndex !== -1) {
            acc[existingIndex].value += curr.values
          } else {
            acc.push({
              channel: curr.channel,
              value: curr.values
            })
          }

          return acc
        },
        [] as { channel: string; value: number; color?: string }[]
      )
      .sort((a, b) => b.value - a.value)
      .map((item, index, arr) => {
        const sum = arr.reduce((acc, curr) => acc + curr.value, 0)
        return {
          ...item,
          percentage: (item.value / sum) * 100,
          color: colors[index]
        }
      })
    return result
  }, [channelFulfillmentData, colors])

  const handleActiveIndexChange = (index: number) => {
    setActiveIndex((prev) => (prev === index ? null : index))
  }

  const handleChannelClick = (obj: ClickChannelDataType | null) => {
    if (onChannelClick) {
      onChannelClick(obj)
    }
  }

  useEffect(() => {
    handleChannelClick(typeof activeIndex === 'number' ? channelChartData[activeIndex] : null)
  }, [activeIndex])

  return (
    <>
      <Box sx={{ p: '15px', width: '100%', aspectRatio: '3 / 2' }}>
        {channelFulfillmentDataLoading && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
          />
        )}
        {!channelFulfillmentDataLoading && (
          <PieChart
            pies={[
              {
                data: channelChartData,
                activeIndex: activeIndex,
                activeShape: (props) => {
                  return <ActiveSection {...props} />
                },
                dataKey: 'value',
                nameKey: 'channel',
                outerRadius: '80%',
                cx: '50%',
                cy: '50%',
                fill: '#8884d8',
                paddingAngle: 0,
                // labelLine={false}
                isAnimationActive: false,
                onClick: (_, index) => {
                  handleActiveIndexChange(index)
                }
              }
            ]}
            cells={channelChartData.map((item) => {
              return {
                fill: item.color,
                style: { cursor: 'pointer' }
              }
            })}
            options={{
              tooltip: true,
              legend: true
            }}
            configs={{
              tooltipConfig: {
                formatter: (value: number) => `${formatCurrency(value, { maxFractionDigits: 1 })}`
              },
              legendConfig: {
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                formatter: (value, entry, index) => {
                  return (
                    <span style={{ textTransform: 'capitalize', fontWeight: activeIndex === index ? 'bold' : 'normal' }}>
                      {value?.toLowerCase()} ({formatCurrency(channelChartData[index].value, { maxFractionDigits: 1, notation: 'compact' })})
                    </span>
                  )
                },
                wrapperStyle: { cursor: 'pointer' },
                onClick: (_, index) => {
                  handleActiveIndexChange(index)
                }
              }
            }}
          />
        )}
      </Box>
    </>
  )
}
