import { Box } from '@mui/material'
import React, { useState } from 'react'
// import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Typography from '@mui/material/Typography'
// import useFilter from 'src/utils/hooks/useFilter'
// import _ from 'src/utils/config/lodashUtils'
import { useNavigate } from 'react-router-dom'
// import ExportCSV from 'src/components/ExportCSV'
import { CsvBuilder } from 'filefy'
import NoTableDataImg from 'src/assets/images/no-table-data.png'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import EmptyScreen from 'src/components/EmptyScreen/EmptyScreen'
import { useAuth } from 'src/context/AuthContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService } from 'src/services/openApiV2'
import { Modules, filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { emptyScreenMessages } from 'src/utils/constants/emptyScreen'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import usePostHogHook from 'src/utils/hooks/usePostHogHook'
import InnerTable from './InnerTable'
import { TableDataType } from './types'

interface HostKitchenProps {
  tab: number
}

const HostKitchenTable = (props: HostKitchenProps) => {
  const { trackPostHogDownload } = usePostHogHook()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { setDownloadSnack } = useSnackData()
  const { headerHeightPx } = useLayoutDimension()
  const [tableObj, setTableObj] = React.useState<TableDataType | null>(null)
  const [ascending, setAscending] = React.useState(false)
  const [orderBy, setOrderBy] = React.useState<string>()
  // const [tableData, setTableData] = React.useState([])
  // const [vbTableData, setVbTableData] = React.useState([])
  const [tableDataLoading, setTableDataLoading] = React.useState(true)
  const [minError, setMinError] = useState<number>(0)
  const [maxError, setMaxError] = useState<number>(0)
  const [errorSliderValues, setErrorSliderValues] = React.useState<number[]>([minError, maxError])
  const [minErrorRate, setMinErrorRate] = useState<number>(0)
  const [maxErrorRate, setMaxErrorRate] = useState<number>(0)
  const [errorRateSliderValues, setErrorRateSliderValues] = React.useState<number[]>([minErrorRate, maxErrorRate])
  const { getFilters, getFiltersV2, selectFiltersFromContext } = useNewFilterContext()
  const tabNames = ['', '_Missed', '_Cancelled', '_Inaccurate']
  const { orgConfig } = useAuth()

  const error_category = React.useMemo(() => {
    try {
      return tabNames[props.tab].slice(1)
    } catch (err) {
      console.log(err)
      return ''
    }
  }, [tabNames, props.tab])

  const tableData = React.useMemo(() => {
    return get(tableObj, 'locations', [] as typeof tableObj.locations)
  }, [tableObj])

  const getTableData = async (obj: { limit: number; offset: number }) => {
    setTableDataLoading(true)
    try {
      const res = (await DefaultService.getErrorOverviewByLocationKpiErrorOverviewLocationPost({
        requestBody: {
          ...(getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true) as any),
          error_category_in: error_category,
          ascending: ascending,
          order_by: orderBy,
          limit: obj.limit,
          offset: obj.offset
        }
      })) as any
      setTableObj(res)
    } catch (err) {
      console.log(err)
    }
    setTableDataLoading(false)
  }

  const getCSVArr = async () => {
    const res = (await DefaultService.getErrorOverviewByLocationKpiErrorOverviewLocationPost({
      requestBody: {
        ...(getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true) as any),
        error_category_in: error_category,
        ascending: ascending,
        order_by: orderBy,
        limit: 100000,
        offset: 0
      }
    })) as any
    return get(res, 'locations', [])
  }

  const downloadTableData = async () => {
    try {
      setDownloadSnack({ status: 'start' })
      const res: TableDataType['locations'] = await getCSVArr()
      const fileName = `${filterNames.b_name}.csv`
      const data = res

      const titles = Object.keys(data[0])

      const csvData = data.map((rowData) => {
        return titles.map((item) => {
          return rowData[item]
        })
      })

      const builder = new CsvBuilder(fileName).setColumns(titles).addRows(csvData).exportFile()

      trackPostHogDownload({
        fileName,
        type: 'CSV'
      })

      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
    }
  }

  React.useEffect(() => {
    getTableData({ limit: 100, offset: 0 })
  }, [error_category, ascending, orderBy, ...getFilters(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  React.useEffect(() => {
    if (maxError > 0) {
      setErrorSliderValues([minError, maxError])
    }
  }, [maxError])
  React.useEffect(() => {
    if (maxErrorRate > 0) {
      setErrorRateSliderValues([minErrorRate, maxErrorRate])
    }
  }, [maxErrorRate])

  React.useEffect(() => {
    if (tableData.length) {
      resetMaxErrorValues(tableData)
    }
  }, [props, tableData])

  const resetMaxErrorValues = (param: typeof tableData) => {
    const tableData = param
    const errorsArray = tableData.map((item) => get(item, 'current_error_rate', 0))
    const errorRateArray = tableData.map((item) => get(item, 'current_error_rate', 0))
    setMinError(Math.min(...errorsArray))
    setMaxError(Math.max(...errorsArray))
    setErrorSliderValues([minError, maxError])
    setMinErrorRate(Math.min(...errorRateArray))
    setMaxErrorRate(Math.max(...errorRateArray))
    setErrorRateSliderValues([minErrorRate, maxErrorRate])
  }

  const goToUrlWithHostKitchenFilters = (b_name) => {
    selectFiltersFromContext('b_name', [b_name])
    return `/errors/overview`
  }

  const goToUrlWithVbNameFilters = (b_name, vb_name) => {
    selectFiltersFromContext('b_name', [b_name])
    selectFiltersFromContext('vb_name', [vb_name])
    return `/errors/overview`
  }

  return (
    <Box sx={{ width: '95%', m: 'auto' }}>
      <>
        <CustomMaterialTableWrapper
          isLoading={tableDataLoading}
          data={tableData}
          columns={[
            { title: `${filterNames.b_name}`, field: 'b_name' },
            { title: 'Total Errors', field: 'current_error_orders' },
            {
              title: 'Potential ' + Modules.LostSales,
              field: 'unfulfilled_sales',
              render: (data) => {
                return (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mr: 2 }}>
                      {formatCurrency(get(data, 'unfulfilled_sales', 0))}
                    </Typography>
                  </>
                )
              }
            },
            {
              title: Modules.Chargebacks,
              field: 'customer_refunds',
              render: (data) => {
                return (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mr: 2 }}>
                      {formatCurrency(get(data, 'customer_refunds', 0))}
                    </Typography>
                  </>
                )
              }
            },
            {
              title: Modules.UnfulfilledRefunds,
              field: 'unfulfilled_refunds',
              render: (data) => {
                return (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mr: 2 }}>
                      {formatCurrency(get(data, 'unfulfilled_refunds', 0))}
                    </Typography>
                  </>
                )
              }
            },
            {
              title: 'Error Rate',
              field: 'current_error_rate',
              render: (data) => {
                const errorRate = get(data, 'current_error_rate', 0) || 0
                return (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mr: 2 }}>
                      {formatNumber(errorRate, { maxFractionDigits: 2 })}%
                    </Typography>
                    {Number(errorRate) !== 0 ? errorRate < 0 ? <TrendingDownIcon sx={{ color: 'green' }} /> : <TrendingUpIcon sx={{ color: 'red' }} /> : null}
                  </>
                )
              }
            },
            {
              title: '% Delta',
              field: 'error_rate_delta',
              render: (data) => {
                const percentDelta = get(data, 'error_rate_delta', 0)
                return (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mr: 2 }}>
                      {typeof percentDelta === 'number' ? formatNumber(percentDelta, { maxFractionDigits: 2 }) + '%' : percentDelta}
                    </Typography>
                    {typeof percentDelta === 'number' && Number(percentDelta) !== 0 ? (
                      percentDelta < 0 ? (
                        <TrendingDownIcon sx={{ color: 'green' }} />
                      ) : (
                        <TrendingUpIcon sx={{ color: 'red' }} />
                      )
                    ) : null}
                  </>
                )
              }
            }
          ]}
          options={{
            stickyHeader: headerHeightPx,
            pagination: true,
            totalPaginatedDataLength: get(tableObj, 'max_rows', undefined),
            export: true,
            csvFileName: `${filterNames.b_name}.csv`,
            sortable: true,
            showOrderBy: true,
            initialOrderBy: ascending ? 'ascending' : 'descending',
            extraExportColumns: [{ title: 'Store ID', field: 'store_id' }]
          }}
          onLastPage={() => {
            if (get(tableObj, 'next_offset', 0) > 0) {
              getTableData({ limit: 100, offset: get(tableObj, 'next_offset', 0) })
            }
          }}
          onOrderByChange={(param) => {
            setAscending(param === 'ascending')
          }}
          onSortByChange={(param) => {
            setOrderBy(param.value)
          }}
          emptyTemplate={
            <EmptyScreen
              NoDataImg={NoTableDataImg}
              labels={emptyScreenMessages.emptyTable}
            />
          }
          detailPanel={
            orgConfig?.filterConfig?.vb_name &&
            ((rowData: (typeof tableData)[0]) => {
              return (
                <InnerTable
                  b_name={rowData.b_name}
                  error_category={error_category}
                  goToUrlWithVbNameFilters={goToUrlWithVbNameFilters}
                />
              )
              // return (
              //   <CustomMaterialTable
              //     data={rowData.vbList}
              //     columns={virtualBrandTableColumns}
              //     onRowClick={(data) => {
              //       navigate(
              //         goToUrlWithVbNameFilters(
              //           data.hostKitchen,
              //           data.vitualBrand
              //         )
              //       )
              //     }}
              //   />
              // )
            })
          }
          onRowClick={(data) => {
            navigate(goToUrlWithHostKitchenFilters(data.b_name))
          }}
          onExportModifyCSV={getCSVArr}
          onExportCSV={(columns, data) => {
            downloadTableData()
            // try {
            //   const newColumns = columns.filter((item) => get(item, 'hidden', false) === false)
            //   const csvData = data.map((rowData) => {
            //     return newColumns.map((item) => {
            //       return rowData[item.field]
            //     })
            //   })

            //   const fileName = `${filterNames.b_name}.csv`
            //   trackPostHogDownload({
            //     fileName,
            //     type: 'CSV'
            //   })

            //   const builder = new CsvBuilder(fileName)
            //     .setColumns(newColumns.map((item) => item.title))
            //     .addRows(csvData)
            //     .exportFile()
            //   downloadSuccessSlackAlert({})
            //   return builder
            // } catch (err) {
            //   downloadFailedSlackAlert({ err })
            // }
          }}
        />
      </>
    </Box>
  )
}

export default HostKitchenTable
