import { MetricsKPIDrillDownOutput, MetricsKPIDrillDownOutputDateRangeCompare } from 'src/services/openApiV2'
import { DrillDownColumnType } from '../../../pages/members/DrillDown/utils/hooks/useServiceHook'
import workerCreator from '../workerCreator'

export default function metricsCSVGenerator(obj: {
  cols: DrillDownColumnType[]
  d: MetricsKPIDrillDownOutputDateRangeCompare
  onSuccess: (data: { headers: string[]; rows: string[][] }) => void
  onError?: (error: string) => void
}) {
  const { cols, d, onSuccess, onError } = obj
  let worker = workerCreator(async function (e) {
    try {
      const { columns, data } = e.data as { columns: typeof cols; data: typeof d }
      const visibleColumns = columns.filter((column) => !column.hidden)
      const percentages = data.percentages.reduce(
        (acc, curr) => {
          acc[curr.breakdown_column] = curr
          return acc
        },
        {} as { [key: string]: MetricsKPIDrillDownOutput }
      )
      const fieldsWithDelta = Object.entries(data.percentages[0] ?? {})
        .filter(([field, value]) => {
          return typeof value === 'number'
        })
        .map((e) => e[0])

      const headers: string[] = []

      visibleColumns.forEach((col) => {
        headers.push(col.title)
        if (fieldsWithDelta.includes(col.field)) {
          headers.push(`${col.title} Difference`)
        }
      })

      const rows = data.current_range_data.map((current) => {
        const row: string[] = []
        visibleColumns.forEach((col) => {
          row.push(`${current[col.field]}`)
          if (fieldsWithDelta.includes(col.field)) {
            // @ts-ignore
            const perc = _.get(percentages, [current.breakdown_column, col.field])
            row.push(perc ? `${perc}%` : ``)
          }
        })
        return row
      })

      this.postMessage({ success: true, data: { headers, rows } })
    } catch (err) {
      this.postMessage({ success: false, error: err.message })
    }
  })

  worker.postMessage({ columns: cols, data: d })

  worker.onmessage = function (e) {
    worker.terminate()
    const { success, data, error } = e.data as { success: boolean; data: { headers: string[]; rows: string[][] }; error: string }
    if (success) {
      onSuccess(data)
    } else if (onError && error) {
      onError(error)
    }
    worker = null // to free up memory
  }
}
