export type AbortObj = {
  promise?: Promise<any>
  controller?: AbortController
}

export const abortMap = new Map<string, null | AbortObj>()

export const ABORT_KEY = 'abort_key'
export const ABORT_SKIP_KEY = 'abort_skip_key'
export const ABORT_ERROR_NAME = 'AbortError'
export const ABORT_MESSAGE = "Request was cancelled because it's being reissued."
export const ABORT_CODE = 'ERR_CANCELED'