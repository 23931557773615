class MetricsService {
  private static readonly METRICS_URL = 'https://metrics.tryloop.ai'

  private static instance: MetricsService

  private metricsQueue: Array<{
    category: string
    metric: string
    labels: Record<string, string>
    value: number
    timestamp: string
  }> = []
  private flushTimeoutId: NodeJS.Timeout | null = null

  private readonly taskPriority = {
    flush: { timeout: 1000 } as IdleRequestOptions,
    track: { timeout: 2000 } as IdleRequestOptions
  }

  private readonly debounceTimers: Record<string, NodeJS.Timeout> = {}
  private readonly debounceDelay = 1000 // 1 second

  private readonly sampleRates: Record<string, number> = {
    user_behavior: 0.1, // Track 10% of behavior events
    performance: 1.0, // Track 100% of performance events
    error: 1.0, // Track 100% of errors
    default: 1.0 // Default 20% sampling
  }

  private readonly timeout = 1000 // 3 seconds timeout
  private readonly maxRetries = 2
  private readonly baseRetryDelay = 1000 // Base delay of 1 second
  private readonly maxRetryDelay = 10000 // Maximum delay of 10 seconds

  private readonly networkConfig = {
    slow: { batchSize: 150, flushInterval: 45000 }, // 45s for slow
    medium: { batchSize: 100, flushInterval: 30000 }, // 30s for medium
    fast: { batchSize: 50, flushInterval: 15000 } // 15s for fast
  }

  private currentNetworkConfig = this.networkConfig.medium

  private constructor(private readonly url: string) {
    this.url = url
    this.startNetworkMonitoring()
    this.startPeriodicFlush()
  }

  public sanitizeJSON(input: Record<string, any> | string, type: 'header' | 'body' | 'query'): Record<string, any> | string {
    if (type === 'header' || type === 'query') {
      // TODO: Implement this
      // Remove auth token from headers
      // Remove any other sensitive information from headers
      return input
    }

    if (typeof input === 'string') {
      return input
    }

    return typeof input === 'object' && input !== null ? input : {}
  }

  public static getInstance(): MetricsService {
    if (!MetricsService.instance) {
      MetricsService.instance = new MetricsService(MetricsService.METRICS_URL)
    }
    return MetricsService.instance
  }

  private async fetchWithTimeout(url: string, options: RequestInit): Promise<Response> {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), this.timeout)

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      })
      return response
    } finally {
      clearTimeout(timeoutId)
    }
  }

  private async retryOperation(operation: () => Promise<any>): Promise<void> {
    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        await operation()
        return
      } catch (error) {
        console.error(`Metrics tracking failed (attempt ${attempt}/${this.maxRetries}):`, error)

        if (attempt === this.maxRetries) {
          // Drop the metric after max retries to prevent infinite loops
          console.warn('Max retries reached, dropping metric')
          return
        }

        // Exponential backoff with jitter
        const delay = Math.min(this.maxRetryDelay, this.baseRetryDelay * Math.pow(2, attempt - 1) * (0.5 + Math.random() * 0.5))
        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }

  private startNetworkMonitoring() {
    if (typeof navigator === 'undefined' || !('connection' in navigator)) return

    const connection = (navigator as any).connection
    if (connection) {
      this.updateNetworkConfig(connection)
      connection.addEventListener('change', () => this.updateNetworkConfig(connection))
    }
  }

  private updateNetworkConfig(connection: any) {
    const effectiveType = connection.effectiveType || 'medium'
    const downlink = connection.downlink || 2

    if (downlink <= 1 || effectiveType === '2g') {
      this.currentNetworkConfig = this.networkConfig.slow
    } else if (downlink <= 5 || effectiveType === '3g') {
      this.currentNetworkConfig = this.networkConfig.medium
    } else {
      this.currentNetworkConfig = this.networkConfig.fast
    }

    // Update flush interval if already running
    if (this.flushTimeoutId) {
      clearInterval(this.flushTimeoutId)
      this.startPeriodicFlush()
    }
  }

  private shouldSampleEvent(category: string): boolean {
    const sampleRate = this.sampleRates[category] ?? this.sampleRates.default
    return Math.random() < sampleRate
  }

  private async trackMetric(category: string, metric: string, labels: Record<string, string> = {}, value: number = 1) {
    // Apply sampling
    if (!this.shouldSampleEvent(category)) {
      return
    }

    // Add network quality to labels
    const networkLabels = {
      ...labels,
      network_type: (navigator as any)?.connection?.effectiveType || 'unknown'
    }

    if (this.metricsQueue.length >= this.currentNetworkConfig.batchSize) {
      console.warn('Metrics queue size limit reached, dropping oldest metrics')
      this.metricsQueue = this.metricsQueue.slice(-Math.floor(this.currentNetworkConfig.batchSize * 0.9))
    }

    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        this.addToQueue(category, metric, networkLabels, value)
      }, this.taskPriority.track)
    } else {
      setTimeout(() => {
        this.addToQueue(category, metric, networkLabels, value)
      }, 0)
    }
  }

  private addToQueue(category: string, metric: string, labels: Record<string, string>, value: number) {
    this.metricsQueue.push({
      category,
      metric,
      labels,
      value,
      timestamp: new Date().toISOString()
    })
  }

  public async trackDebounced(category: string, metric: string, labels: Record<string, string> = {}, value: number = 1) {
    const key = `${category}-${metric}-${JSON.stringify(labels)}`

    if (this.debounceTimers[key]) {
      clearTimeout(this.debounceTimers[key])
    }

    this.debounceTimers[key] = setTimeout(() => {
      this.trackMetric(category, metric, labels, value)
      delete this.debounceTimers[key]
    }, this.debounceDelay)
  }

  private startPeriodicFlush() {
    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      const scheduleNextFlush = () => {
        window.requestIdleCallback(() => {
          this.flush()
            .catch((error) => console.error('Failed to flush metrics:', error))
            .finally(() => {
              this.flushTimeoutId = setTimeout(scheduleNextFlush, this.currentNetworkConfig.flushInterval)
            })
        }, this.taskPriority.flush)
      }

      this.flushTimeoutId = setTimeout(scheduleNextFlush, this.currentNetworkConfig.flushInterval)
    } else {
      this.flushTimeoutId = setInterval(() => {
        this.flush().catch((error) => console.error('Failed to flush metrics:', error))
      }, this.currentNetworkConfig.flushInterval)
    }
  }

  public async trackCritical(category: string, metric: string, labels: Record<string, string> = {}, value: number = 1) {
    await this.addToQueue(category, metric, labels, value)
    if (this.metricsQueue.length >= Math.min(10, this.currentNetworkConfig.batchSize)) {
      await this.flush()
    }
  }

  public async trackScrollDepth(depth: number) {
    await this.trackDebounced('user_behavior', 'scroll_depth', {}, depth)
  }

  public async trackMouseMovement(coordinates: { x: number; y: number }) {
    await this.trackDebounced('user_behavior', 'mouse_movement', {
      x: coordinates.x.toString(),
      y: coordinates.y.toString()
    })
  }

  private async flush(): Promise<void> {
    if (this.metricsQueue.length === 0) return

    // Process one metric at a time for now, but structure allows for future batching
    while (this.metricsQueue.length > 0) {
      const metric = this.metricsQueue.shift()!
      await this.sendMetric(metric)
    }
  }

  private async sendMetric(metric: { category: string; metric: string; labels: Record<string, string>; value: number; timestamp: string }) {
    await this.retryOperation(async () => {
      try {
        const response = await this.fetchWithTimeout(`${this.url}/collect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(metric)
        })

        if (!response.ok) {
          // Only retry on 5xx errors, drop metric on 4xx
          if (response.status >= 500) {
            throw new Error(`Server error: ${response.status}`)
          } else {
            console.warn(`Dropping metric due to ${response.status} response`)
            return
          }
        }
      } catch (error) {
        throw error // Let retryOperation handle the retry logic
      }
    })
  }

  // User Metrics
  public async trackActiveUsers(count: number) {
    await this.trackMetric('user', 'active', {}, count)
  }

  public async trackUserLogin() {
    await this.trackMetric('user', 'login')
  }

  public async trackUserLoginWithMagicLink() {
    await this.trackMetric('user', 'login_with_magic_link')
  }

  public async trackUserLoginWithPassword() {
    await this.trackMetric('user', 'login_with_password')
  }

  public async trackUserSession(userType: string) {
    await this.trackMetric('user', 'session', { user_type: userType })
  }

  public async trackUserAction(actionType: string, userType: string) {
    await this.trackMetric('user', 'action', { action_type: actionType, user_type: userType })
  }

  // Performance Metrics
  public async trackMemoryUsage(bytes: number) {
    await this.trackMetric('performance', 'memory', {}, bytes)
  }

  public async trackCPUUsage(percentage: number) {
    await this.trackMetric('performance', 'cpu', {}, percentage)
  }

  public async trackGCDuration(duration: number) {
    await this.trackMetric('performance', 'gc', {}, duration)
  }

  // Latency Metrics
  public async trackHttpLatency(
    method: string,
    route: string,
    statusCode: number,
    request_body: Record<string, any> | string,
    request_headers: Record<string, any> | string,
    request_query: Record<string, any> | string,
    duration: number
  ) {
    await this.trackMetric(
      'latency',
      'http',
      {
        request_body: this.sanitizeJSON(request_body, 'body') as string,
        request_headers: this.sanitizeJSON(request_headers, 'header') as string,
        request_query: this.sanitizeJSON(request_query, 'query') as string,
        method,
        route,
        status_code: statusCode.toString()
      },
      duration
    )
  }

  public async trackDatabaseQueryLatency(queryType: string, duration: number) {
    await this.trackMetric('latency', 'database', { query_type: queryType }, duration)
  }

  // Status/Error Metrics
  public async trackError(
    errorType: string = 'unknown',
    errorCode: string | number = 'unknown',
    module: string = 'unknown',
    errorMessage: string = 'unknown',
    route: string = 'unknown'
  ) {
    await this.trackMetric('error', 'general', {
      error_type: errorType,
      error_code: errorCode.toString(),
      route: route,
      module: module,
      error_message: errorMessage
    })
  }

  public async trackApiError(method: string, route: string, statusCode: number, errorType: string, module: string, duration: number) {
    await this.trackMetric(
      'error',
      'api',
      {
        method,
        route,
        status_code: statusCode.toString(),
        error_type: errorType,
        module
      },
      duration
    )
  }

  public async trackHealthStatus(isHealthy: boolean) {
    await this.trackMetric('status', 'health', {}, isHealthy ? 1 : 0)
  }

  public async cleanup() {
    if (this.flushTimeoutId) {
      clearInterval(this.flushTimeoutId)
    }
    // Flush any remaining metrics
    await this.flush()
  }
}

// return a singleton instance of MetricsService
export const metricsService = MetricsService.getInstance()

// Usage examples:
/*
metricsService.trackUserLogin()
metricsService.trackUserAction('button_click', 'premium_user')
metricsService.trackApiError('GET', '/api/users', 500, 'NetworkError', 'UserModule')
metricsService.trackHttpLatency('POST', '/api/data', 200, 0.543)
metricsService.trackDatabaseQueryLatency('SELECT', 0.123)
metricsService.trackHealthStatus(true)
*/
