import { useAuth } from 'src/context/AuthContext'
import { DefaultService, UsersService } from 'src/services/openApiV2'
import { get } from 'src/utils/config/lodashUtils'
import { UserBNameNoAuthType } from '../utils/types'

export default function useFilterDataServiceHook() {
  const { currentUser } = useAuth()

  /**
   * by this function we get all the data of filters related to specific ORG
   */
  const getAllFiltersData = async (org?: string) => {
    try {
      if (!org) return []
      const res = await DefaultService.getChainFiltersChainFiltersPost({
        requestBody: {
          chain_in: org
        }
      })
      return res
    } catch (err) {
      console.log('get all filters data: ', err)
      throw err
    }
  }

  /**
   * This function sets the b_names that is assigned to the user (mainly for `store-manager` access level)
   * @returns
   */
  const getUserAssignedBNamesData = async () => {
    try {
      if (!get(currentUser, 'org', false)) return []

      const res: UserBNameNoAuthType = (await UsersService.getBnamesByUserNoAuthUserBnamesNoAuthGet({
        userId: get(currentUser, 'uid', ''),
        limit: 100000
      })) as any
      return get(res, 'items', [] as typeof res.items)
    } catch (err) {
      console.log('getUserAssignedBNamesData: ', err)
      throw err
    }
  }

  return {
    getAllFiltersData,
    getUserAssignedBNamesData
  }
}
