import { Modules } from 'src/utils/config/config'
import { Dictionary } from 'src/utils/config/dictionary'

const tooltips = {
  sales_incl_tax: 'Total revenue generated from orders, including taxes charged to customers.',
  sales_excl_tax: 'Total revenue generated from orders, excluding any taxes charged to customers.',
  lost_sales: `Potential revenue lost due to ${Dictionary.CANCELLED.toLowerCase()} or failed orders.`,
  unfulfilled_refunds: 'Refunds due to order unfulfillment.',
  taxes: 'Taxes collected from customers and paid to you by 3P platforms.',
  taxes_withheld: 'Taxes collected from customers and held by 3P platforms to pay to tax authorities on your behalf.',
  tax_backup_withheld: 'Amount withheld by third-party provider if taxpayer information is incomplete or not updated. For more info: https://shorturl.at/aVVXS',
  commissions: 'Fees charged by the platform for facilitating orders.',
  restaurant_delivery_charge: 'Charges collected from customers for restaurant delivery.',
  marketing: 'Costs associated with promoting the restaurant on the platform.',
  marketing_ad_spend: 'Costs associated with advertising on the platform.',
  marketing_promo_spend: 'Costs associated with promotional activities on the platform.',
  loyalty: 'Specific to Olo, this is the cost of the loyalty program.',
  chargebacks: 'Refunds due to customer dissatisfaction.',
  won_disputes: `Includes won unfulfilled ${Modules.Chargebacks} disputes.`,
  customer_tips: 'Additional amounts paid by customers as tips for service.',
  restaurant_fees: 'Specific to Olo, this is the fees charged by the restaurant for facilitating orders.',
  miscellaneous: 'Various fees or charges that do not fit into specific categories.',
  unaccounted: 'Amounts that are not allocated or categorized, often requiring further investigation or clarification.',
  cost_of_goods_sold: 'Costs associated with producing the goods or services sold.'
}

export default tooltips
