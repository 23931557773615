import { CustomMaterialTable } from '@LoopKitchen/loop-ui'
import { Typography } from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApiV2'
import { Modules } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { formatCurrency } from 'src/utils/functions'
import { measurePerformance } from 'src/utils/functions/performance'

interface InnerTableProps {
  b_name: string
  error_category: string
  goToUrlWithVbNameFilters: (b_name: string, vb_name: string) => string
}

type TableType = {
  brands: {
    b_name: string
    vb_name: string
    order_id: string
    order_date_time: Timestamp
    customer_refunds: number
    unfulfilled_refunds: number
    unfulfilled_sales: number
    lost_sale: number
    error_charges: number
    customer_feedback: string
  }[]
  next_offset: number | null
  max_rows: number
}

export default function InnerTable(props: InnerTableProps) {
  const { currentUser } = useAuth()
  const [tableObj, setTableObj] = React.useState<TableType | null>(null)
  const [loading, setLoading] = React.useState(false)
  const { getFiltersV2 } = useFilter()
  const navigate = useNavigate()

  const getTableData = async (obj: { limit: number; offset: number }) => {
    setLoading(true)
    try {
      const res = (await measurePerformance(
        async () => {
          return await DefaultService.getErrorOverviewByBrandKpiErrorOverviewBrandPost({
            requestBody: {
              ...getFiltersV2(['vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true),
              b_name_in: props.b_name,
              error_category_in: props.error_category,
              limit: obj.limit,
              offset: obj.offset
            }
          })
        },
        {
          procedure: '/kpi/error_overview/error_orders',
          email: currentUser.email,
          org: currentUser.org
        }
      )) as TableType
      setTableObj(res)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    getTableData({ limit: 100, offset: 0 })
  }, [props.b_name, props.error_category])

  return (
    <CustomMaterialTable
      isLoading={loading}
      data={get(tableObj, 'brands', [] as typeof tableObj.brands)}
      columns={[
        { title: 'Order ID', field: 'order_id' },
        {
          title: 'Order Timestamp',
          field: 'order_date_time',
          render: (data) => {
            return <Typography variant="subtitle2">{moment(data['order_date_time']).format('DD MMM YYYY hh:mm A')}</Typography>
          }
        },
        {
          title: 'Potential ' + Modules.LostSales,
          field: 'unfulfilled_sales',
          render: (data) => {
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {formatCurrency(get(data, 'unfulfilled_sales', 0))}
                </Typography>
              </>
            )
          }
        },
        {
          title: Modules.Chargebacks,
          field: 'customer_refunds',
          render: (data) => {
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {formatCurrency(get(data, 'customer_refunds', 0))}
                </Typography>
              </>
            )
          }
        },
        {
          title: Modules.UnfulfilledRefunds,
          field: 'unfulfilled_refunds',
          render: (data) => {
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {formatCurrency(get(data, 'unfulfilled_refunds', 0))}
                </Typography>
              </>
            )
          }
        },
        { title: 'Customer Feedback', field: 'customer_feedback' }
      ]}
      options={{
        pagination: true,
        pageSize: 100,
        // pageSizeList: [10, 50, 100],
        totalPaginatedDataLength: get(tableObj, 'max_rows', undefined)
      }}
      onLastPage={() => {
        if (get(tableObj, 'next_offset', 0) > 0) {
          getTableData({ limit: 100, offset: get(tableObj, 'next_offset', 0) })
        }
      }}
      onRowClick={(data) => {
        navigate(props.goToUrlWithVbNameFilters(data.b_name, data.vb_name))
      }}
    />
  )
}
// ------------------------------
