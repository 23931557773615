import { HelpOutlineOutlined, SvgIconComponent } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import HeadingContainer from 'src/components/OrderDrawer/components/helpers/HeadingContainer';
import { CampaignDetailPaginatedV3 } from 'src/services/openApiV2';
import { formatCurrency } from 'src/utils/functions';
import { Alert } from '@LoopKitchen/loop-ui';

interface BreakdownCompProps {
  charges: any;
}

interface BreakdownItem {
  label: string;
  price: number;
  color?: string;
  tooltip?: string;
  isBreakdown?: boolean;
}

interface IconTooltipProps {
  tooltip: string;
  position: 'left' | 'right' | 'top' | 'bottom';
  icon: SvgIconComponent;
}

const IconTooltip = ({ tooltip, position, icon: Icon }: IconTooltipProps) => (
  <Tooltip
    title={tooltip}
    arrow
    placement={position}
    componentsProps={{
      tooltip: {
        style: {
          color: 'white',
          fontSize: 12,
          fontWeight: 400,
          maxWidth: 300,
          padding: '10px',
          backgroundColor: 'rgba(0, 0, 0, 1)'
        }
      },
      arrow: {
        style: { color: 'black' }
      }
    }}>
    <Box
      sx={{
        fontSize: '16px',
        color: 'black',
        borderRadius: '2px',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Icon fontSize="inherit" color="inherit" />
    </Box>
  </Tooltip>
);

const BreakdownList = ({ data }: { data: BreakdownItem[] }) => (
  <Stack direction="column" alignItems="stretch" gap="5px" sx={{ pl: '15px' , border: '1px solid #EEEEEE', borderRadius: '2px', backgroundColor: 'rgba(253, 253, 253, 1)' , padding: '4px'}}>
    {data.map((item, index) => (
      <Stack
        key={index}
        direction="row"
        alignItems="center"
        gap="2px"
        sx={{
          width: '100%',
          height: '100%',
          p: '2px'
        }}>
        <Typography
          fontSize= {item.isBreakdown ? '13px' : '12px'}
          fontWeight={item.isBreakdown ? 600 : 400}
          ml={item.isBreakdown ? '2px' : '15px'}>
          {item.label}
        </Typography>
        {item.tooltip && (
          <IconTooltip tooltip={item.tooltip} position={'top'} icon={HelpOutlineOutlined} />
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          fontSize= {item.isBreakdown ? '13px' : '12px'}
          fontWeight={item.isBreakdown ? 600 : 400}
          color={item.color}>
          {formatCurrency(item.price, { maxFractionDigits: 2 })}
        </Typography>
      </Stack>
    ))}
  </Stack>
);

export default function CampaignSalesBreakdown({ charges }: BreakdownCompProps) {
  const total_spend = charges.attr_spend + charges.attr_other_promo_spend + charges.attr_other_ad_spend;
  const total_cofund = charges.attr_cofund + charges.attr_other_promo_cofund + charges.attr_other_ad_cofund;
  const effective_spend = total_spend - total_cofund;
  const calculateData = React.useCallback(() => {
    return {
      attrSales: [
        {
          label: `Sales (${charges.attr_orders} orders)`,
          price: charges.attr_sales,
          color: '#1E1E1E',
          isBreakdown: true
        }
      ],
      attrSpend: [
        {
          label: `Total Marketing Spend`,
          price: Math.abs(charges.attr_spend + charges.attr_other_spend - charges.attr_cofund),
          isBreakdown: true,
          tooltip: 'Total cost of all marketing that influenced these orders , broken down into marketing spend due to this campaign and overlapping campaigns'
        },
        {
          label: `Current Campaign`,
          price: Math.abs(charges.attr_spend),
        },
        {
          label: `Overlapping Promotions`,
          price: Math.abs(charges.attr_other_promo_spend),
        },
        {
          label: `Overlapping Ads`,
          price: Math.abs(charges.attr_other_ad_spend),
        },
      ],
      attrCofund: [
        {
          label: `Total Cofunding`,
          price: Math.abs(charges.attr_cofund + charges.attr_other_promo_cofund + charges.attr_other_ad_cofund),
          isBreakdown: true,
          tooltip: 'Total cost of all cofunding that influenced these orders , broken down into cofunding due to this campaign and overlapping campaigns'
        },
        {
          label: `Current Campaign`,
          price: Math.abs(charges.attr_cofund),
        },
        {
          label: `Overlapping Promotions`,
          price: Math.abs(charges.attr_other_promo_cofund),
        },
        {
          label: `Overlapping Ads`,
          price: Math.abs(charges.attr_other_ad_cofund),
        },
      ],
      attrPayout: [
        {
          label: `Gross Payout`,
          price: Math.abs(charges.attr_sales) - Math.abs(effective_spend),
          color: '#1E1E1E',
          isBreakdown: true
        },
        {
          label: `Sales`,
          price: charges.attr_sales,
          color: '#1E1E1E',
        },
        {
          label: `Effective Marketing Spend`,
          price: Math.abs(effective_spend),
          color: 'red',
          tooltip: 'Total Marketing Spend - Total Cofunding'
        }
      ],
      unattributed: [
        {
          label: `Sales` + (charges.orders ? ` (${charges.orders - charges.attr_orders} orders)` : ''),
          price: Math.max(charges.sales - charges.attr_sales, 0),
          color: '#1E1E1E'
        },
        {
          label: `Marketing Spend`,
          price: Math.max(charges.cost - charges.attr_spend, 0),
          color: '#FF0000'
        }
      ],
      reported: [
        {
          label: `Sales` + (charges.orders ? ` (${charges.orders} orders)` : ''),
          price: charges.sales,
          color: '#1E1E1E'
        },
        {
          label: `Marketing Spend`,
          price: Math.abs(charges.cost),
          color: '#FF0000'
        }
      ]
    };
  }, [charges]);
  
  const { attrSales, attrSpend, attrCofund, attrPayout, unattributed, reported } = calculateData();
  
  return (
    <>
      <HeadingContainer icon={'storefront'} title="Merchant Reported Metrics">
        <BreakdownList data={reported} />
      </HeadingContainer>
      {((charges.vb_platform !== 'UberEats') &&
        <HeadingContainer icon={'link_off'} title="Unattributed">
          <BreakdownList data={unattributed} />
        </HeadingContainer>
        )}
      <HeadingContainer icon={'insights'} title="Loop Attributed Metrics" sx={{ marginTop: '5px' }}>
        <Stack gap={1}>
          <BreakdownList data={attrSales} />
          <BreakdownList data={attrSpend} />
          <BreakdownList data={attrCofund} />
          <BreakdownList data={attrPayout} />
        </Stack>
      </HeadingContainer>
    </>
  );
}
