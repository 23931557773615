import { AccountBalanceWallet, Dvr, KeyboardReturn, ShoppingBasket } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, MarketingMetricAggregateV2 } from 'src/services/openApiV2'
import { get } from 'src/utils/config/lodashUtils'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import RealStateIcon from '../../../../../components/Icon/RealStateIcon'
import { useMarketingTrendsLocalContext } from '../Trends'
import DataCard from './DataCard'

const OverviewCards = () => {
  const { openError } = useSnackData()
  const { getFilters, getFiltersV2 } = useFilter()
  const { channel, fulfillment } = useMarketingTrendsLocalContext()
  const [metricLoading, setMetricLoading] = useState(true)
  const [metricData, setMetricData] = useState<MarketingMetricAggregateV2>(null)
  const cardFiltersArr = ['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date']

  const filterObj = useMemo(() => {
    return {
      channel_in: channel?.join('|'),
      fulfillment_in: fulfillment?.join('|'),
      ...getFiltersV2(cardFiltersArr, true)
    }
  }, [channel, fulfillment, ...getFilters(cardFiltersArr)])

  const getSalesOrdersRatioData = async () => {
    setMetricLoading(true)
    try {
      const res = await DefaultService.callMarketingMetricV2ApiMarketingMetricV2Post({ requestBody: filterObj })
      setMetricData(res[0])
    } catch (err) {
      openError(err.message)
    } finally {
      setMetricLoading(false)
    }
  }

  useEffect(() => {
    getSalesOrdersRatioData()
  }, [filterObj])

  return (
    <>
      <Grid
        container
        spacing={1}>
        <Grid
          item
          lg={4}
          md={6}
          xs={12}>
          <DataCard
            icon={
              <RealStateIcon
                sx={{ fontSize: '20px' }}
                color="inherit"
              />
            }
            title={'Sales from marketing'}
            amount={formatCurrency(get(metricData, 'sales_from_marketing', 0), { maxFractionDigits: 1 })}
            outOfAmount={`${formatCurrency(get(metricData, 'sales', 0), { maxFractionDigits: 1 })} (${formatNumber((get(metricData, 'sales_from_marketing', 0) * 100) / get(metricData, 'sales', 0), { maxFractionDigits: 2 })}%)`}
            loading={metricLoading}
            sx={{ width: '100%', height: '100%', p: '16px' }}
          />
        </Grid>

        <Grid
          item
          lg={4}
          md={6}
          xs={12}>
          <DataCard
            icon={
              <Dvr
                sx={{ fontSize: '20px' }}
                color="inherit"
              />
            }
            title={'Orders from marketing'}
            amount={formatNumber(get(metricData, 'orders_from_marketing', 0), { maxFractionDigits: 0 })}
            outOfAmount={formatNumber(get(metricData, 'orders', 0), { maxFractionDigits: 0 })}
            loading={metricLoading}
            sx={{ width: '100%', height: '100%', p: '16px' }}
          />
        </Grid>

        <Grid
          item
          lg={4}
          md={6}
          xs={12}>
          <DataCard
            icon={
              <ShoppingBasket
                sx={{ fontSize: '20px' }}
                color="inherit"
              />
            }
            title={'Avg. Basket Size from marketing'}
            amount={formatCurrency(get(metricData, 'basket_size_marketing', 0), { maxFractionDigits: 1 })}
            subAmount={` / ${formatCurrency(get(metricData, 'basket_size', 0), { maxFractionDigits: 1 })}`}
            loading={metricLoading}
            sx={{ width: '100%', height: '100%', p: '16px' }}
            tooltip={'Avg. basket size driven from marketing / Avg. basket size overall'}
          />
        </Grid>

        <Grid
          item
          lg={4}
          md={6}
          xs={12}>
          <DataCard
            icon={
              <AccountBalanceWallet
                sx={{ fontSize: '20px' }}
                color="inherit"
              />
            }
            title={'Dollar spent on marketing'}
            amount={formatCurrency(get(metricData, 'total_marketing_cost', 0), { maxFractionDigits: 2 })}
            loading={metricLoading}
            sx={{ width: '100%', height: '100%', p: '16px' }}
          />
        </Grid>

        <Grid
          item
          lg={4}
          md={6}
          xs={12}>
          <DataCard
            icon={
              <ShoppingBasket
                sx={{ fontSize: '20px' }}
                color="inherit"
              />
            }
            title={'Avg. Marketing spend/order'}
            amount={formatCurrency(get(metricData, 'marketing_spend_per_order', 0), { maxFractionDigits: 1 })}
            loading={metricLoading}
            sx={{ width: '100%', height: '100%', p: '16px' }}
          />
        </Grid>

        <Grid
          item
          lg={4}
          md={6}
          xs={12}>
          <DataCard
            icon={
              <KeyboardReturn
                sx={{ fontSize: '20px' }}
                color="inherit"
              />
            }
            title={'ROI'}
            amount={formatNumber(get(metricData, 'roi', 0), { maxFractionDigits: 1 })}
            loading={metricLoading}
            tooltip="Sales from marketing / Dollar spent on marketing"
            sx={{ width: '100%', height: '100%', p: '16px' }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OverviewCards
