import { MultiSelect, Paper } from '@LoopKitchen/loop-ui'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Chip, Slider, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import {
  DefaultService,
  PosThirdPartySummaryAggregate,
  PosThirdPartyVarianceInput,
  PosThirdPartyVarianceList,
  PosThirdPartyVarianceListInput,
  PosThirdPartyVarianceListPaginated,
  PosType
} from 'src/services/openApiV2'

import { get } from 'lodash'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import { exportCSV } from 'src/components/ExportCsvV2'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import DeltaCard from '../../../components/DeltaCard'
import PlatformLogo from '../../../components/PlatformLogo'
import { StringShortener } from '../../../components/StringShortener'
import Page from '../../../components/mui/Page'
import { useErrorData } from '../../../context/ErrorContext'
import { useFilter } from '../../../context/FilterContext'
import { useSnackData } from '../../../context/SnackContext'
import { filterNames } from '../../../utils/config/config'
import { formatCurrency } from '../../../utils/functions'
import usePostHogHook from '../../../utils/hooks/usePostHogHook'
import LoadingAnimation from '../dashboard/components/LoadingAnimation'

export default function ThirdPartyReconciliation() {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { trackPostHogDownload } = usePostHogHook()
  const { headerHeightPx } = useLayoutDimension()
  const [analyticsLoading, setAnalyticsLoading] = React.useState(false)
  const [dataLoading, setDataLoading] = React.useState(false)
  const [summaryData, setSummaryData] = React.useState<PosThirdPartySummaryAggregate>({})
  const [tableData, setTableData] = React.useState<PosThirdPartyVarianceListPaginated>(null)
  const [varianceThreshold, setVarianceThreshold] = React.useState<number>(0.0)
  const [allPosTypes, setAllPosTypes] = React.useState<string[]>([])
  const [selectedPosTypes, setSelectedPosTypes] = React.useState<string[]>([])
  const { setDownloadSnack } = useSnackData()
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [clickedCard, setClickedCard] = useState('')
  const [filterBubble, setFilterBubble] = useState<{ message: string }>({
    message: ''
  })

  const handleSliderChange = (event, newValue) => {
    setVarianceThreshold(newValue)
  }

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const handleClose = () => {
    setDrawerOpen(false)
  }

  const requestBodyForSummaryGetter = (): PosThirdPartyVarianceInput => {
    const requestBody: PosThirdPartyVarianceInput = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
    requestBody.pos = selectedPosTypes.join('|')
    return requestBody
  }

  const requestBodyForListGetter = (): PosThirdPartyVarianceListInput => {
    const requestBody: PosThirdPartyVarianceListInput = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
    requestBody.pos = selectedPosTypes.join('|')
    if (clickedCard === 'tax_variance') {
      requestBody.variance_tax_threshold = varianceThreshold
    } else if (clickedCard === 'pos_3p_variance') {
      requestBody.variance_threshold = varianceThreshold
    } else if (clickedCard === 'commission_variance') {
      requestBody.variance_commission_threshold = varianceThreshold
    }
    return requestBody
  }

  const getPosTypes = async () => {
    try {
      const data: Array<PosType> = await DefaultService.callPosListPosTypesApiPosListPosTypesPost({
        requestBody: {
          ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
        }
      })
      setAllPosTypes(data.map((posType) => posType.pos))
    } catch (err) {
      handleError(err.message)
    }
  }

  const getTableData = async (paginate: boolean = false) => {
    setDataLoading(true)
    setLoadingNextPage(paginate)
    try {
      const data: PosThirdPartyVarianceListPaginated = await DefaultService.callPosListOfVariancesApiPaginatedPosListOfVariancesPost({
        requestBody: {
          ...requestBodyForListGetter(),
          limit: 100,
          offset: paginate ? tableData?.next_offset || 0 : 0
        },
        isPaginated: true
      })
      if (paginate) {
        data.data = [...tableData.data, ...data.data]
      }
      setTableData(data)
      setDataLoading(false)
      setLoadingNextPage(false)
    } catch (err) {
      handleError(err.body)
      setAnalyticsLoading(false)
      setSummaryData(null)
    }
  }

  const getSummaryData = async () => {
    setAnalyticsLoading(true)
    try {
      const requestBody: PosThirdPartyVarianceInput = requestBodyForSummaryGetter()

      const data: PosThirdPartySummaryAggregate = await DefaultService.callPosAggregateMetricsFor3pVsPosApiPosAggregateMetricsFor3pVsPosPost({
        requestBody
      })
      setSummaryData(data)
      setAnalyticsLoading(false)
    } catch (err) {
      handleError(err.body)
      setAnalyticsLoading(false)
      setSummaryData(null)
    }
  }

  React.useEffect(() => {
    getPosTypes()
  }, [])

  React.useEffect(() => {
    getSummaryData()
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date']), selectedPosTypes])

  React.useEffect(() => {
    getTableData(false)
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date']), clickedCard, selectedPosTypes, varianceThreshold])

  React.useEffect(() => {
    if (clickedCard === 'pos_3p_variance') {
      setFilterBubble({
        message: 'Showing only days with variance(subtotal) > ' + varianceThreshold + '$'
      })
    } else if (clickedCard === 'tax_variance') {
      setFilterBubble({
        message: 'Showing only days with variance(tax) > ' + varianceThreshold + '$'
      })
    } else if (clickedCard === 'commission_variance') {
      setFilterBubble({
        message: 'Showing only days with variance(commission) > ' + varianceThreshold + '$'
      })
    }
  }, [varianceThreshold, clickedCard])
  return (
    <>
      {filterLoading && <LoadingAnimation />}
      {!filterLoading && (
        <>
          <Page
            title="Revenue Reconciliation"
            sx={{ bgcolor: 'white', p: 4 }}
            filterProps={{
              customMaxDate: moment().subtract(1, 'days').toDate(),
              hideFilterKeys: ['vb_name', 'am_name', 'vb_platform'],
              hideResetButton: true,
              extraFilters: [
                <MultiSelect
                  label="POS type"
                  selectButtonSx={{
                    p: '12px 32px 12px 8px'
                  }}
                  disableScrollLock
                  options={allPosTypes.map((cardType) => ({ label: cardType, value: cardType }))}
                  value={selectedPosTypes.length === 0 ? allPosTypes : selectedPosTypes}
                  onChange={(value) => {
                    setSelectedPosTypes(value)
                  }}
                />
              ],
              onResetCallback: () => {
                setSelectedPosTypes([])
              }
            }}>
            <Paper sx={{ p: 0 }}>
              <Typography
                variant="h3"
                component="h3"
                pt={2}>
                Summary
              </Typography>
              <Box
                display="flex"
                gap="0.5rem"
                marginTop={'1rem'}
                flexWrap="wrap">
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'subtotal_without_tax_3p', 0))}`}
                  label="Subtotal from 3P"
                  bigFont
                  loading={analyticsLoading}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'subtotal_without_tax_pos', 0))}`}
                  label="Subtotal from POS"
                  bigFont
                  loading={analyticsLoading}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'variance_without_tax', 0))}`}
                  label="Variance in subtotal"
                  labelTooltip={'Variance between 3P and POS data in Subtotal'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('pos_3p_variance')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'variance_tax', 0))}`}
                  label="Variance in tax"
                  labelTooltip={'Variance between 3P and POS data in tax'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('tax_variance')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'variance_commission', 0))}`}
                  label="Variance in commission"
                  labelTooltip={'Variance between 3P and POS data in commision'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('commission_variance')
                    setVarianceThreshold(0.1)
                  }}
                />
              </Box>
              <Box
                display="flex"
                gap="0.5rem"
                marginTop={'1rem'}
                flexWrap="wrap">
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'tax_pos', 0))}`}
                  label="Tax from Pos"
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('tax_pos')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'tax_3p', 0))}`}
                  label="Tax from 3p"
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('tax_3p')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'commission_pos', 0))}`}
                  label="Commission from pos"
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('commission_pos')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(get(summaryData, 'commission_3p', 0))}`}
                  label="Commission from 3p"
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('commission_3p')
                    setVarianceThreshold(0.1)
                  }}
                />
              </Box>
            </Paper>

            {(clickedCard === 'pos_3p_variance' || clickedCard === 'tax_variance' || clickedCard == 'commission_variance') && (
              <Box py={4}>
                <Chip
                  label={<Typography variant="body1">{filterBubble.message}</Typography>}
                  onDelete={() => {
                    setFilterBubble({ message: '' })
                    setClickedCard('')
                    setVarianceThreshold(0.0)
                  }}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                  color="primary"
                />
              </Box>
            )}

            {/* Render the slider and its label only when either of the variance cards are clicked */}
            {(clickedCard === 'pos_3p_variance' || clickedCard === 'tax_variance' || clickedCard == 'commission_variance') && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start">
                <Typography gutterBottom>Select Variance Threshold</Typography>
                <Box
                  width={250}
                  pl={1}>
                  <Slider
                    value={varianceThreshold}
                    onChange={handleSliderChange}
                    step={0.1}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Box>
            )}
            {
              <Box>
                <CustomMaterialTableWrapper
                  columns={[
                    {
                      title: filterNames.b_name,
                      field: 'b_name'
                    },
                    {
                      title: 'POS',
                      field: 'pos',
                      render: (rowData: PosThirdPartyVarianceList) => (
                        <StringShortener
                          str={rowData.pos}
                          platform={rowData.pos} // Assuming you want to use the 'pos' value as the platform too
                          allowClickToCopy={false}
                          displayLength={0}
                        />
                      )
                    },
                    {
                      title: 'Platform',
                      field: 'vb_platform',
                      render: (rowData: PosThirdPartyVarianceList) => (
                        <PlatformLogo
                          platformName={rowData.vb_platform}
                          shortImg={true}
                          height="20px"
                        />
                      )
                    },
                    {
                      title: 'Date',
                      field: 'datestr'
                    },
                    {
                      title: 'Subtotal from POS',
                      field: 'subtotal_without_tax_pos',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.subtotal_without_tax_pos)
                    },
                    {
                      title: 'Subtotal from 3P',
                      field: 'subtotal_without_tax_3p',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.subtotal_without_tax_3p)
                    },
                    {
                      title: 'Variance (Subtotal)',
                      field: 'variance_without_tax',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.variance_without_tax)
                    },
                    {
                      title: 'Tax from POS',
                      field: 'tax_pos',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.tax_pos)
                    },
                    {
                      title: 'Tax from 3P',
                      field: 'tax_3p',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.tax_3p)
                    },
                    {
                      title: 'Variance(Tax)',
                      field: 'variance_tax',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.variance_tax)
                    },
                    {
                      title: 'Commission from POS',
                      field: 'commission_pos',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.commission_pos)
                    },
                    {
                      title: 'Commission from 3P',
                      field: 'commission_3p',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.commission_3p)
                    },
                    {
                      title: 'Variance(Commission)',
                      field: 'variance_commission',
                      render: (rowData: PosThirdPartyVarianceList) => formatCurrency(rowData.variance_commission)
                    }
                  ]}
                  data={tableData?.data || []}
                  isLoading={dataLoading}
                  options={{
                    stickyHeader: headerHeightPx,
                    export: true,
                    search: false,
                    pagination: true,
                    toolbarSx: { pb: 2, pt: 0, pl: 0 },
                    totalPaginatedDataLength: tableData?.max_rows || 0,
                    loadingNextPage: loadingNextPage,
                    debounceMilliseconds: 1500
                  }}
                  onRefresh={() => getTableData(false)}
                  onLastPage={async () => {
                    if (tableData?.next_offset) {
                      await getTableData(true)
                    }
                  }}
                  onExportModifyCSV={() => {
                    return DefaultService.exportPosListOfVariancesApiExportPosListOfVariancesPost({ requestBody: requestBodyForListGetter() }) as any
                  }}
                  onExportCSV={() => {
                    exportCSV({
                      apiCall: DefaultService.exportPosListOfVariancesApiExportPosListOfVariancesPost, // Adjust this to the appropriate API endpoint for the new data type
                      requestBody: requestBodyForListGetter(),
                      onStart: () => setDownloadSnack({ status: 'start' }),
                      onComplete: () => setDownloadSnack({ status: 'complete' }),
                      onError: (errMessage: string) => {
                        setDownloadSnack({ status: 'close' })
                        handleError(errMessage)
                      },
                      trackDownload: (details: { fileName: string; type: 'CSV' | 'PDF' }) => {
                        trackPostHogDownload(details)
                      }
                    })
                  }}
                />
              </Box>
            }
          </Page>
        </>
      )}
    </>
  )
}
