import { CustomMaterialTable, SingleSelect } from '@LoopKitchen/loop-ui'
import FoodBankIcon from '@mui/icons-material/FoodBank'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { CampaignTypeUberEats, DefaultService, RecommendedCampaignModel, RecommendedCampaignResponse } from 'src/services/openApiV2'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { formatCurrency } from 'src/utils/functions'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import LocalStorage from '../../MarketingCampaignManagement/utils/LocalStorage'

interface RecommendedCampaignProps {}

export default function RecommendedCampaign(props: RecommendedCampaignProps) {
  const { headerHeightPx } = useLayoutDimension()
  const { openError, openSuccess } = useSnackData()
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const [campaignTypes, setCampaignTypes] = React.useState<CampaignTypeUberEats[]>([])
  const [selectedCampaignType, setSelectedCampaignType] = React.useState<CampaignTypeUberEats | 'ALL'>('ALL')
  const [recommendedCampaignsData, setRecommendedCampaignsData] = React.useState<RecommendedCampaignResponse>(null)
  const [recommendedCampaignsLoading, setRecommendedCampaignsLoading] = React.useState(false)
  const [startCampaignLoading, setStartCampaignLoading] = React.useState<RecommendedCampaignModel>(null)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<RecommendedCampaignModel>(null)

  const startCampaign = async (model: RecommendedCampaignModel) => {
    setStartCampaignLoading(JSON.parse(JSON.stringify(model)))

    try {
      localStorage.setItem('start_campaign_slug', JSON.stringify(model))
      const res = await DefaultService.startCampaignStartCampaignPost({
        requestBody: {
          ...model.config,
          slug: get(model, 'slug', ''),
          item_name: get(model, 'config.item_name', ''),
          campaign_type: selectedCampaignType as CampaignTypeUberEats
        }
      })
      LocalStorage.addRecommendedConfig(model)
      // openSuccess(get(res, 'message', 'Campaign Started'))
      openSuccess('Campaign Started')
    } catch (err) {
      openError(err.message)
    }

    // setTimeout(() => {
    setStartCampaignLoading(null)
    // }, 2000)

    getRecommendedCampaigns(
      {
        limit: Math.max(100, get(recommendedCampaignsData, 'data.length', 0)),
        offset: 0
      },
      (data) => {
        setRecommendedCampaignsData(data)
      }
    )
  }

  const getRecommendedCampaigns = async (
    body: { limit?: number; offset: number; skipLoading?: boolean },
    callback: (data: RecommendedCampaignResponse) => void
  ) => {
    if (!body.skipLoading) {
      setRecommendedCampaignsLoading(true)
    }
    try {
      const res = await DefaultService.getRecommendedCampaignsGetRecommendedCampaignsPost({
        campaignType: selectedCampaignType,
        requestBody: getFiltersV2(['vb_name', 'chain', 'b_name', 'am_name', 'vb_platform', 'am_ame', 'start_date', 'end_date'], true),
        limit: get(body, 'limit', 100),
        offset: get(body, 'offset', 0)
      })
      callback(res)
    } catch (err) {
      openError(err.message)
    }
    if (!body.skipLoading) {
      setRecommendedCampaignsLoading(false)
    }
  }

  React.useEffect(() => {
    const getCampaignTypes = async () => {
      try {
        const res = (await DefaultService.getCampaignTypesGetCampaignTypesGet()) as typeof campaignTypes
        setCampaignTypes(res)
        if (res.length > 0 && selectedCampaignType !== 'ALL' && !res.includes(selectedCampaignType)) {
          setSelectedCampaignType(res[0])
        }
      } catch (err) {
        handleError(err.message)
      }
    }
    getCampaignTypes()
  }, [])

  React.useEffect(() => {
    if (selectedCampaignType) {
      getRecommendedCampaigns(
        {
          limit: 100,
          offset: 0
        },
        (data) => {
          setRecommendedCampaignsData(data)
        }
      )
    }
  }, [selectedCampaignType, ...getFilters(['vb_name', 'chain', 'b_name', 'am_name', 'vb_platform', 'am_ame', 'start_date', 'end_date'])])

  React.useEffect(() => {
    LocalStorage.removeOldRecommendedConfig()
  }, [])

  return (
    <>
      <ConfirmDialog
        open={!!openConfirmDialog}
        title="Do you want to start this campaign?"
        description=""
        onConfirm={() => {
          startCampaign(openConfirmDialog)
          setOpenConfirmDialog(null)
        }}
        handleClose={() => {
          setOpenConfirmDialog(null)
        }}
      />
      <CustomMaterialTable
        isLoading={recommendedCampaignsLoading}
        data={get(recommendedCampaignsData, 'data', [] as typeof recommendedCampaignsData.data).filter((e) => !LocalStorage.isRecommendedConfigAvailable(e))}
        columns={[
          {
            title: 'Store',
            field: 'vb_name',
            render: (data) => {
              return (
                <Box>
                  {get(data, 'b_name', undefined) && (
                    <Stack
                      direction="row"
                      gap="3px">
                      <Box
                        sx={{
                          color: 'rgba(0, 0, 0, 0.5)',
                          fontSize: '12px'
                        }}>
                        <FoodBankIcon
                          color="inherit"
                          fontSize="inherit"
                        />
                      </Box>
                      <Typography
                        fontSize="12px"
                        color="rgba(0, 0, 0, 0.5)"
                        lineHeight="132%"
                        sx={{ mb: '3px' }}>
                        {get(data, 'b_name', '')}
                      </Typography>
                    </Stack>
                  )}
                  <Typography
                    fontSize="16px"
                    lineHeight="132%">
                    {get(data, 'vb_name', '')}
                  </Typography>
                </Box>
              )
            }
          },
          {
            title: filterNames.vb_address,
            field: 'vb_address'
          },
          {
            title: 'Campaign Type',
            field: 'config.campaign_type',
            render: (data) => {
              const campaignType = get(data, 'config.campaign_type', null)
              return (
                <>
                  <Typography fontSize="12px">{campaignType?.replaceAll('_', ' ')}</Typography>
                </>
              )
            }
          },
          {
            title: 'Config',
            field: 'config',
            hidden: !['ALL', CampaignTypeUberEats.FLAT].includes(selectedCampaignType),
            render: (data) => {
              const spendX = get(data, 'config.spend_x', 0)
              const saveY = get(data, 'config.save_y', 0)
              const campaignType = get(data, 'config.campaign_type', null)
              return (
                <>
                  {campaignType === CampaignTypeUberEats.FLAT && (
                    <Typography fontSize="12px">
                      Spend <b>{formatCurrency(spendX, { maxFractionDigits: 1 })}</b>, Save <b>{formatCurrency(saveY, { maxFractionDigits: 1 })}</b>
                    </Typography>
                  )}
                </>
              )
            }
          },
          {
            title: 'Action',
            field: 'action',
            render: (data) => {
              const isLoading = get(data, 'slug', undefined) === get(startCampaignLoading, 'slug', undefined)
              return (
                <Button
                  size="small"
                  disabled={isLoading}
                  onClick={() => {
                    setOpenConfirmDialog(data)
                  }}
                  sx={{
                    whiteSpace: 'nowrap'
                  }}
                  startIcon={
                    isLoading && (
                      <CircularProgress
                        size="14px"
                        color="secondary"
                      />
                    )
                  }>
                  START CAMPAIGN
                </Button>
              )
            }
          }
        ]}
        options={{
          stickyHeader: headerHeightPx,
          pagination: true,
          extraToolbarComponent: (
            <>
              {campaignTypes.length > 0 && (
                <SingleSelect
                  value={selectedCampaignType}
                  onChange={(val) => {
                    setSelectedCampaignType(val as typeof selectedCampaignType)
                  }}
                  options={[
                    {
                      label: 'All',
                      value: 'ALL'
                    },
                    ...campaignTypes.map((e) => {
                      return {
                        label: e.replaceAll('_', ' '),
                        value: e
                      }
                    })
                  ]}
                  selectButtonSx={{
                    height: '40px',
                    width: '140px',
                    bgcolor: '#F6f6f6',
                    color: '#000',
                    '&:hover': {
                      bgcolor: '#EEEEEE'
                    },
                    borderRadius: '4px',
                    px: '16px',
                    py: '5px'
                  }}
                  renderValue={(value) => {
                    const val = value || 'Campaign Type'
                    return (
                      <Typography
                        fontSize="12px"
                        fontWeight={600}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                        {val}
                      </Typography>
                    )
                  }}
                />
              )}
            </>
          )
        }}
      />
    </>
  )
}
