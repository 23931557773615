import { SingleSelect } from '@LoopKitchen/loop-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ReviewsIcon from '@mui/icons-material/Reviews'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import DeltaChip from 'src/components/DeltaChip'
import PlatformLogo from 'src/components/PlatformLogo'
import { useAuth } from 'src/context/AuthContext'
import { useDrillDownContext } from 'src/context/DrillDownContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import {
  FiltersForQuery,
  // DefaultService,
  MetricsKPIDrillDownBreakDownOptions,
  MetricsKPIDrillDownOutput,
  MetricsKPIDrillDownOutputPaginated
} from 'src/services/openApiV2'
import { Modules, filterIcons, filterNames } from 'src/utils/config/config'
import { Dictionary } from 'src/utils/config/dictionary'
import { get, uniqueId } from 'src/utils/config/lodashUtils'
import { downloadCsv, formatCurrency, formatNumber } from 'src/utils/functions'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import TimePeriodFormatter from 'src/utils/functions/timePeriodFormatter'
import { getFiltersArrStr } from '../utils/functions'
import useServiceHook, { DateRangeCompareDataResponseType, DrillDownColumnType } from '../utils/hooks/useServiceHook'
import allJsonData from '../utils/jsonConfigs'
import { BreakdownEnumsType, FiltersObjType } from '../utils/jsonConfigs/types'
import CustomChip from './CustomChip'

interface MetricsByStoreTableProps {
  useMainFilters?: boolean
  lockUI: boolean
  filtersArr: FiltersObjType[]
  setFilterData: (data: FiltersObjType[], drillDownName: keyof typeof allJsonData) => void
  stickyHeader?: string
}

export default function MetricsByStoreTable(props: MetricsByStoreTableProps) {
  const { filtersArr, lockUI, useMainFilters, stickyHeader, setFilterData } = props
  const navigate = useNavigate()
  const { getTableData, getExportData, exportDataWithDeltaValues, getDateRangeCompareData, getDeltaValue: getDeltaValueHook } = useServiceHook('MetricsKPI')
  const { activeGraph, stack } = useDrillDownContext()
  const { handleError } = useErrorData()
  const { setDownloadSnack } = useSnackData()
  const { getFilters, getFiltersV2 } = useFilter()
  const [tableData, setTableData] = React.useState<MetricsKPIDrillDownOutputPaginated | null>(null)
  const [dateRangeCompareData, setDateRangeCompareData] = React.useState<DateRangeCompareDataResponseType | null>(null)
  const [breakdownBy, setBreakdownBy] = React.useState<BreakdownEnumsType>(MetricsKPIDrillDownBreakDownOptions.B_NAME)
  // const [selectedFilters, setSelectedFilters] = React.useState<{ key: string; value: string }[]>([])
  const [loading, setLoading] = React.useState(false)
  const [deltaLoading, setDeltaLoading] = React.useState(true)
  const [sortBy, setSortBy] = React.useState<string>('total_chargebacks')
  const [orderBy, setOrderBy] = React.useState<boolean>(false)
  const filterArr = ['b_name', 'vb_name', 'chain', 'am_name', 'vb_platform', 'start_date', 'end_date']
  const { orgConfig, currentUser } = useAuth()
  const [pageNumber, setPageNumber] = React.useState(0)

  const jsonData = React.useMemo(() => {
    return allJsonData['MetricsKPI']
  }, [])

  const breakdownByOptions = React.useMemo(() => {
    const options = get(jsonData, 'mainBreakdownOptions', [] as typeof jsonData.mainBreakdownOptions)
    const selectedFiltersValues = filtersArr.map((e) => e.key)

    let result = options.filter((item) => !selectedFiltersValues.includes(item.value))

    const hasBreakdownValue = result.some((item) => item.value === breakdownBy)

    const resultsCanHaveDigitalStores = result.some(
      (item) => item.value === MetricsKPIDrillDownBreakDownOptions.VB_NAME || item.value === MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM
    )
    if (!resultsCanHaveDigitalStores) {
      result = result.filter((item) => item.value !== 'slug')
    }

    if (!hasBreakdownValue && result.length > 0) {
      setBreakdownBy(result[0].value)
    }

    return result
  }, [jsonData, getFiltersArrStr(filtersArr)])

  const selectedBreakdownOption = React.useMemo(() => {
    const result = breakdownByOptions.find((e) => e.value === breakdownBy)
    return result
  }, [breakdownByOptions, breakdownBy])

  const activeStackObj = React.useMemo(() => {
    if (!selectedBreakdownOption) {
      return null
    }
    for (let i = stack.length - 1; i >= 0; i--) {
      const obj = stack[i]
      const currentStackCondition = get(obj, 'filterObj', [] as typeof obj.filterObj).some(
        (item) => item.key === get(selectedBreakdownOption, 'value', 'breakdown_not_found')
      )
      if (currentStackCondition) {
        return obj
      }
    }
    return null
  }, [selectedBreakdownOption, stack])

  const isActive = (value: string) => {
    if (!activeStackObj) {
      return false
    }
    const values = get(activeStackObj, 'filterObj.value', [] as FiltersObjType['value'])
    return values.includes(value)
  }

  const getDeltaValue = (breakdownKey: string, key: keyof MetricsKPIDrillDownOutput) => {
    return getDeltaValueHook(dateRangeCompareData, breakdownKey, key)
  }

  const handleAddSelectedOptions = (arr: { key: BreakdownEnumsType; value: string[] }[]) => {
    setFilterData(arr, 'MetricsKPI')
    // setSelectedFilters((prev) => {
    //   const index = prev.findIndex((item) => item.key === key)
    //   if (index >= 0) {
    //     return [...prev.slice(0, index), { key, value }, ...prev.slice(index + 1)]
    //   }
    //   return [...prev, { key, value }]
    // })
  }

  const getFilterBody = () => {
    let filterBody = {} as Partial<FiltersForQuery> & { start_date_in: string; end_date_in: string }
    if (useMainFilters) {
      filterBody = getFiltersV2(filterArr, true) as typeof filterBody
    } else {
      const dateRange = activeGraph.getDateRange()
      filterBody = {
        start_date_in: get(dateRange, 'start', moment()).format('YYYY-MM-DD'),
        end_date_in: get(dateRange, 'end', moment()).format('YYYY-MM-DD')
      }
    }
    const obj = {}
    filtersArr.forEach((item) => {
      obj[`${item.key}_in`] = item.value.join('|')
    })
    return {
      ...filterBody,
      ...obj,
      breakdown_column: breakdownBy as BreakdownEnumsType
    }
  }

  const getCSVString = async () => {
    let filterBody: any = {}
    if (useMainFilters) {
      filterBody = getFiltersV2(filterArr, true)
    } else {
      const dateRange = activeGraph.getDateRange()
      filterBody = {
        start_date_in: get(dateRange, 'start', moment()).format('YYYY-MM-DD'),
        end_date_in: get(dateRange, 'end', moment()).format('YYYY-MM-DD')
      }
    }
    const obj = {}
    filtersArr.forEach((item) => {
      obj[`${item.key}_in`] = item.value.join('|')
    })
    return await getExportData({ ...filterBody, ...obj, chain_in: get(currentUser, 'org', '') })
  }

  const onExportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await getCSVString()
      downloadCsv('Metrics_By_Store.csv', res)
      setDownloadSnack({ status: 'complete' })
      downloadSuccessSlackAlert({ apiUrl: '/api/export/KpiByStore' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
      downloadFailedSlackAlert({ err, apiUrl: '/api/export/KpiByStore' })
    }
  }

  const exportWidthDelta = async (columns: DrillDownColumnType[]) => {
    setDownloadSnack({ status: 'start' })
    exportDataWithDeltaValues({
      fileName: 'Metrics_by_stores.csv',
      columns,
      filterObj: getFilterBody(),
      onSuccess: () => {
        setDownloadSnack({ status: 'complete' })
      },
      onError: (errMsg) => {
        setDownloadSnack({ status: 'close' })
        handleError(errMsg)
      }
    })
  }

  const navigateToDrillDown = (key: keyof typeof allJsonData, rowData: MetricsKPIDrillDownOutput) => {
    const searchParams = new URLSearchParams()
    const { start_date_in, end_date_in } = getFiltersV2(['start_date', 'end_date'], true)
    searchParams.set('date_range', `${start_date_in}to${end_date_in}`)

    let obj: FiltersObjType
    if (breakdownBy === MetricsKPIDrillDownBreakDownOptions.B_NAME && rowData && rowData.b_name) {
      obj = {
        key: 'b_name' as BreakdownEnumsType,
        value: [get(rowData, 'b_name', '').toString()]
      }
      setFilterData([obj], key)
    } else if (breakdownBy === MetricsKPIDrillDownBreakDownOptions.VB_NAME && rowData && rowData.vb_name) {
      obj = {
        key: 'vb_name' as BreakdownEnumsType,
        value: [get(rowData, 'vb_name', '').toString()]
      }
      setFilterData([obj], key)
    } else if (breakdownBy === MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM && rowData && rowData.vb_platform) {
      obj = {
        key: 'vb_platform' as BreakdownEnumsType,
        value: [get(rowData, 'vb_platform', '').toString()]
      }
      setFilterData([obj], key)
    } else if (breakdownBy === MetricsKPIDrillDownBreakDownOptions.SLUG) {
      setFilterData(
        [
          {
            key: 'vb_name' as BreakdownEnumsType,
            value: [get(rowData, 'vb_name', '').toString()]
          },
          {
            key: 'vb_platform' as BreakdownEnumsType,
            value: [get(rowData, 'vb_platform', '').toString()]
          }
        ],
        key
      )
    }
    // if (obj) {
    //   arr.push(obj)
    // }

    // arr.forEach((item) => {
    //   const key = `filter_${item.key}`
    //   const value = item.value
    //   searchParams.set(key, value)
    // })

    // navigate(`/drill-down/${key}?${searchParams.toString()}`, { state: { label: 'Dashboard', route: window.location.pathname + window.location.search } })
  }

  const apiID = React.useRef<string>()
  const getData = async (id: string, body: { limit?: number; offset: number }, callback: (data: MetricsKPIDrillDownOutputPaginated) => void) => {
    const { limit = 100, offset } = body
    setLoading(true)
    try {
      let filterBody = getFilterBody()
      const res = await getTableData({
        ...filterBody,
        limit: limit,
        offset: offset,
        order_by: sortBy,
        ascending: orderBy
      })
      if (id === apiID.current) {
        callback(res)
      }
    } catch (err) {
      handleError(err.message)
    }
    if (id === apiID.current) {
      setLoading(false)
    }
  }

  const getDateRangeCompare = async () => {
    setDeltaLoading(true)
    try {
      let filterBody = getFilterBody()
      const res = await getDateRangeCompareData({
        ...filterBody
      })
      setDateRangeCompareData(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setDeltaLoading(false)
    }
  }

  const depsRef = React.useRef([])
  const filterDeps = React.useMemo(() => {
    if (lockUI) {
      return depsRef.current
    }
    depsRef.current = getFilters(filterArr)
    return depsRef.current
  }, [lockUI, useMainFilters, ...getFilters(filterArr)])

  React.useEffect(() => {
    if (!lockUI) {
      apiID.current = uniqueId()
      getData(apiID.current, { offset: 0 }, (data) => {
        setTableData(data)
      })
    }
  }, [
    orderBy,
    sortBy,
    breakdownBy,
    getFiltersArrStr(filtersArr),
    useMainFilters ? null : activeGraph.getDateRange()?.start?.format('YYYY-MM-DD'),
    useMainFilters ? null : activeGraph.getDateRange()?.end?.format('YYYY-MM-DD'),
    ...(useMainFilters ? filterDeps : [])
  ])

  React.useEffect(() => {
    getDateRangeCompare()
  }, [
    breakdownBy,
    getFiltersArrStr(filtersArr),
    useMainFilters ? null : activeGraph.getDateRange()?.start?.format('YYYY-MM-DD'),
    useMainFilters ? null : activeGraph.getDateRange()?.end?.format('YYYY-MM-DD'),
    ...(useMainFilters ? filterDeps : [])
  ])

  React.useEffect(() => {
    if (Array.isArray(breakdownByOptions) && breakdownByOptions.length > 0) {
      setBreakdownBy(breakdownByOptions[0].value)
    }
  }, [breakdownByOptions])

  return (
    <>
      <Box>
        {/* <Box sx={{ mt: 1 }}>
          <BreadcrumbsComp
            breadcrumbArr={[
              {
                label: 'Metrics by store',
                onClick:
                  selectedFilters.length > 0
                    ? () => {
                        setSelectedFilters([])
                      }
                    : undefined
              },
              ...selectedFilters.slice(0, -1).map((item, index, arr) => {
                return {
                  label: `${get(filterNames, item.key, '')}: ${item.value}`,
                  onClick: () => {
                    setSelectedFilters(arr.slice(0, index + 1))
                  }
                }
              }),
              selectedFilters.length > 0 ? { label: `${get(filterNames, selectedFilters.at(-1).key, '')}: ${selectedFilters.at(-1).value}` } : null
            ].filter((e) => e)}
          />
        </Box> */}

        <CustomMaterialTableWrapper
          titleComp={
            <SingleSelect
              key={breakdownBy}
              options={breakdownByOptions}
              value={breakdownBy}
              onChange={(value) => {
                setPageNumber(0)
                setBreakdownBy(value)
              }}
              renderValue={(selected) => {
                const option = selectedBreakdownOption
                return (
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                    {option && (
                      <Icon
                        color="inherit"
                        sx={{ fontSize: '16px' }}>
                        {option?.icon}
                      </Icon>
                    )}
                    <Typography
                      fontSize="inherit"
                      color={'#000'}>
                      Breakdown by {option?.label}
                    </Typography>
                  </Box>
                )
              }}
              disableSort
            />
          }
          isLoading={loading}
          data={get(tableData, 'data', []) as typeof tableData.data}
          columns={[
            {
              title: filterNames.b_name,
              field: 'b_name',
              hidden: breakdownBy !== MetricsKPIDrillDownBreakDownOptions.B_NAME,
              render: (data) => {
                const active = isActive(get(data, 'b_name', '').toString())
                return (
                  <CustomChip
                    active={active}
                    onClick={
                      breakdownByOptions.length > 1
                        ? () => {
                            handleAddSelectedOptions([{ key: 'b_name' as BreakdownEnumsType, value: [get(data, 'b_name', '').toString()] }])
                          }
                        : undefined
                    }>
                    <Tooltip
                      arrow
                      title={get(data, 'b_name', '')}
                      placement="top">
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="5px"
                        sx={{ flex: 1 }}>
                        <Icon sx={{ fontSize: '16px' }}>{filterIcons.b_name}</Icon>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {get(data, 'b_name', '')}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </CustomChip>
                )
              }
            },
            {
              title: filterNames.slug,
              field: 'slug',
              hidden: breakdownBy !== MetricsKPIDrillDownBreakDownOptions.SLUG,
              render: (data) => {
                const active = isActive(get(data, 'vb_name', '').toString())

                return (
                  <CustomChip
                    active={active}
                    onClick={
                      breakdownByOptions.length === 4
                        ? () => {
                            handleAddSelectedOptions([
                              {
                                key: 'vb_platform' as BreakdownEnumsType,
                                value: [get(data, 'vb_platform', '').toString()]
                              },
                              {
                                key: 'vb_name' as BreakdownEnumsType,
                                value: [get(data, 'vb_name', '').toString()]
                              }
                            ])
                          }
                        : undefined
                    }>
                    <Tooltip
                      arrow
                      title={get(data, 'vb_name', '')}
                      placement="top">
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="5px"
                        sx={{ flex: 1 }}>
                        {/* <Icon sx={{ fontSize: '16px' }}>{filterIcons.vb_name}</Icon> */}
                        <PlatformLogo
                          shortImg
                          width="16px"
                          height="16px"
                          platformName={get(data, 'vb_platform', '').toString()}
                        />
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {get(data, 'vb_name', '')}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </CustomChip>
                )
              }
            },
            {
              title: filterNames.b_name,
              field: 'b_name',
              hidden: breakdownBy !== MetricsKPIDrillDownBreakDownOptions.SLUG,
              render: (data) => {
                return (
                  <CustomChip>
                    <Tooltip
                      arrow
                      title={get(data, 'b_name', '')}
                      placement="top">
                      <Typography
                        variant="subtitle2"
                        sx={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {get(data, 'b_name', '')}
                      </Typography>
                    </Tooltip>
                  </CustomChip>
                )
              }
            },
            {
              title: filterNames.vb_name,
              field: 'vb_name',
              hidden: breakdownBy !== MetricsKPIDrillDownBreakDownOptions.VB_NAME,
              render: (data) => {
                const active = isActive(get(data, 'vb_name', '').toString())
                return (
                  <CustomChip
                    active={active}
                    onClick={
                      breakdownByOptions.length > 1
                        ? () => {
                            handleAddSelectedOptions([
                              {
                                key: 'vb_name' as BreakdownEnumsType,
                                value: [get(data, 'vb_name', '').toString()]
                              }
                            ])
                          }
                        : undefined
                    }>
                    <Tooltip
                      arrow
                      title={get(data, 'vb_name', '')}
                      placement="top">
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="5px"
                        sx={{ flex: 1 }}>
                        <Icon sx={{ fontSize: '16px' }}>{filterIcons.vb_name}</Icon>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {get(data, 'vb_name', '')}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </CustomChip>
                )
              }
            },
            {
              title: filterNames.vb_platform,
              field: 'vb_platform',
              hidden: breakdownBy !== MetricsKPIDrillDownBreakDownOptions.VB_PLATFORM,
              render: (data) => {
                const active = isActive(get(data, 'vb_platform', '').toString())
                return (
                  <CustomChip
                    active={active}
                    onClick={
                      breakdownByOptions.length > 1
                        ? () => {
                            handleAddSelectedOptions([{ key: 'vb_platform' as BreakdownEnumsType, value: [get(data, 'vb_platform', '').toString()] }])
                          }
                        : undefined
                    }>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="5px"
                      sx={{ flex: 1 }}>
                      <PlatformLogo
                        shortImg
                        platformName={get(data, 'vb_platform', '').toString()}
                        height="20px"
                        width="20px"
                      />
                      <Typography variant="subtitle2">{get(data, 'vb_platform', '')}</Typography>
                    </Stack>
                  </CustomChip>
                )
              }
            },
            {
              title: 'Total Sales',
              field: 'total_sales',
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.sales_chart
              ),
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'total_sales')
                const n = Number(get(data, 'total_sales', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={
                        () => {
                          navigateToDrillDown('Sales', data)
                        }
                        // (breakdownBy === 'slug' && breakdownByOptions.length === 3) || breakdownByOptions.length === 1
                        //   ? () => {
                        //       navigateToDrillDown('Sales', data)
                        //     }
                        //   : undefined
                      }>
                      {formatCurrency(n)}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'error' : delta > 0 ? 'success' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          Sales: {formatCurrency(previous, { maxFractionDigits: 2 })}
                        </>
                      }
                    />
                  </Box>
                )
              }
            },
            {
              title: Modules.Chargebacks,
              field: 'total_chargebacks',
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.chargebacks_chart
              ),
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'total_chargebacks')
                const n = Number(get(data, 'total_chargebacks', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={
                        () => {
                          navigateToDrillDown('Chargebacks', data)
                        }
                        // (breakdownBy === 'slug' && breakdownByOptions.length === 3) || breakdownByOptions.length === 1
                        //   ? () => {
                        //       navigateToDrillDown('Chargebacks', data)
                        //     }
                        //   : undefined
                      }>
                      {formatCurrency(n)}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          {Modules.Chargebacks}: {formatCurrency(previous, { maxFractionDigits: 2 })}
                        </>
                      }
                    />
                  </Box>
                )
              }
            },
            {
              title: 'Error Rate',
              field: 'avg_error_rate_percent',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_error_rate_percent')
                const n = Number(get(data, 'avg_error_rate_percent', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('ErrorRate', data)
                      }}>
                      {`${formatNumber(n)}%`}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          Error Rate: {formatNumber(previous, { maxFractionDigits: 2 })}%
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.chargebacks_chart
              )
            },
            {
              title: `${Dictionary.INACCURATE} Error Rate`,
              field: 'avg_error_inaccurate_rate_percent',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_error_inaccurate_rate_percent')
                const n = Number(get(data, 'avg_error_inaccurate_rate_percent', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('InaccurateErrorRate', data)
                      }}>
                      {`${formatNumber(n)}%`}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          {Dictionary.INACCURATE} Error Rate: {formatNumber(previous, { maxFractionDigits: 2 })}%
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.chargebacks_chart
              )
            },
            {
              title: `${Dictionary.CANCELLED} Error Rate`,
              field: 'avg_error_cancelled_rate_percent',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_error_cancelled_rate_percent')
                const n = Number(get(data, 'avg_error_cancelled_rate_percent', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('CancelledErrorRate', data)
                      }}>
                      {`${formatNumber(n)}%`}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          {Dictionary.CANCELLED} Error Rate: {formatNumber(previous, { maxFractionDigits: 2 })}%
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.chargebacks_chart
              )
            },
            {
              title: `${Dictionary.MISSED} Error Rate`,
              field: 'avg_error_missed_rate_percent',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_error_missed_rate_percent')
                const n = Number(get(data, 'avg_error_missed_rate_percent', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('MissedErrorRate', data)
                      }}>
                      {`${formatNumber(n)}%`}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          {Dictionary.MISSED} Error Rate: {formatNumber(previous, { maxFractionDigits: 2 })}%
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.chargebacks_chart
              )
            },
            {
              title: 'Marketing spend',
              field: 'total_marketing_spend',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'total_marketing_spend')
                const n = Number(get(data, 'total_marketing_spend', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('Marketing', data)
                      }}>
                      {formatCurrency(n)}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta > 0 ? 'success' : delta < 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          Marketing Spend: {formatCurrency(previous, { maxFractionDigits: 2 })}
                        </>
                      }
                    />
                  </Box>
                )
              }
            },
            {
              title: '3P Fee',
              field: 'avg_third_party_fee_perc',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_third_party_fee_perc')
                const n = Number(get(data, 'avg_third_party_fee_perc', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('FinanceOrderTransactions', data)
                      }}>{`${formatNumber(n)}%`}</CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          3P Fee: {formatNumber(previous, { maxFractionDigits: 2 })}%
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.third_party_fee_chart
              )
            },
            {
              title: 'Rating',
              field: 'avg_rating',
              headerStyle: {
                textAlign: 'center'
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.customer_sentiment_chart
              ),
              render: (data) => {
                const { delta: avgRatingDelta, previous: avgRatingPrevious, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_rating')
                const { delta: reviewDelta, previous: reviewPrevious } = getDeltaValue(data.breakdown_column, 'rating_denominator')
                const avgRating = Number(get(data, 'avg_rating', 0))
                const ratingDenominator = Number(get(data, 'rating_denominator', 0))
                const avgRatingIsNaN = isNaN(avgRating)
                const ratingDenominatorIsNaN = isNaN(ratingDenominator)
                return (
                  <Box>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <tr
                        style={{ background: '#F3F3F3', cursor: 'pointer' }}
                        onClick={() => {
                          navigateToDrillDown('Ratings', data)
                        }}>
                        <td style={{ padding: '7px 2.5px 7px 6.5px' }}>
                          {!avgRatingIsNaN && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="5px">
                              <StarRoundedIcon sx={{ fontSize: '15px', color: 'black' }} />
                              <Typography variant="subtitle2">{formatNumber(avgRating, { maxFractionDigits: 1 })}</Typography>
                            </Stack>
                          )}
                        </td>
                        <td style={{ padding: '7px 2.5px 7px 2.5px' }}>
                          {!ratingDenominatorIsNaN && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="5px">
                              <ReviewsIcon sx={{ fontSize: '15px', color: 'black' }} />
                              <Typography variant="subtitle2">{formatNumber(ratingDenominator, { maxFractionDigits: 1 })}</Typography>
                            </Stack>
                          )}
                        </td>
                        <td style={{ padding: '7px 6.5px 7px 2.5px' }}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: '100%' }}>
                            <ChevronRightIcon sx={{ fontSize: '15px', color: 'black' }} />
                          </Stack>
                        </td>
                      </tr>
                      {(deltaLoading || typeof avgRatingDelta === 'number' || typeof reviewDelta === 'number') && (
                        <tr>
                          <td style={{ paddingTop: '5px' }}>
                            <DeltaChip
                              loading={deltaLoading}
                              delta={avgRatingDelta}
                              deltaType={avgRatingDelta > 0 ? 'success' : avgRatingDelta < 0 ? 'error' : 'zero'}
                              tooltipTitle={
                                <>
                                  {previousRangeTooltip || 'Previous'}
                                  <br />
                                  Rating: {formatNumber(avgRatingPrevious, { maxFractionDigits: 2 })}
                                </>
                              }
                            />
                          </td>
                          <td
                            colSpan={2}
                            style={{ paddingTop: '5px' }}>
                            <DeltaChip
                              loading={deltaLoading}
                              delta={reviewDelta}
                              deltaType={reviewDelta > 0 ? 'success' : reviewDelta < 0 ? 'error' : 'zero'}
                              tooltipTitle={
                                <>
                                  {previousRangeTooltip || 'Previous'}
                                  <br />
                                  Reviews: {formatNumber(reviewPrevious, { maxFractionDigits: 2 })}
                                </>
                              }
                            />
                          </td>
                        </tr>
                      )}
                    </table>
                  </Box>
                )
              }
            },
            {
              title: 'Downtime Percentage',
              field: 'avg_downtime_percentage',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'avg_downtime_percentage')
                const n = Number(get(data, 'avg_downtime_percentage', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('LostSales', data)
                      }}>{`${formatNumber(n)}%`}</CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          Downtime: {formatNumber(previous, { maxFractionDigits: 2 })}%
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.lost_sales_chart
              )
            },
            {
              title: 'Downtime Minutes',
              field: 'downtime_in_minutes_numerator',
              render: (data) => {
                const { delta, previous, previousRangeTooltip } = getDeltaValue(data.breakdown_column, 'downtime_in_minutes_numerator')
                const n = Number(get(data, 'downtime_in_minutes_numerator', 0))
                if (isNaN(n)) {
                  return <></>
                }
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <CustomChip
                      onClick={() => {
                        navigateToDrillDown('LostSales', data)
                      }}>
                      {TimePeriodFormatter(n)}
                    </CustomChip>
                    <DeltaChip
                      loading={deltaLoading}
                      delta={delta}
                      deltaType={delta < 0 ? 'success' : delta > 0 ? 'error' : 'zero'}
                      tooltipTitle={
                        <>
                          {previousRangeTooltip || 'Previous'}
                          <br />
                          Downtime: {TimePeriodFormatter(previous)}
                        </>
                      }
                    />
                  </Box>
                )
              },
              hidden: !(
                !orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config ||
                orgConfig?.access_levels[getAccessLevel(currentUser)]?.chart_config?.lost_sales_chart
              )
            }
          ]}
          options={{
            ...(stickyHeader ? { stickyHeader } : {}),
            pagination: true,
            sortable: true,
            export: true,
            csvFileName: 'Metrics_By_Store.csv',
            initialSortableOption: sortBy,
            initialOrderBy: orderBy ? 'ascending' : 'descending',
            pageNumber: pageNumber,
            totalPaginatedDataLength: get(tableData, 'max_rows', undefined),
            bodyCellStyle: {
              borderLeft: 0,
              borderRight: 0
            }
          }}
          onPaginationNext={({ totalPages }) => {
            if (pageNumber < totalPages) {
              setPageNumber((prev) => prev + 1)
            }
          }}
          onPaginationPrev={() => {
            if (pageNumber > 0) {
              setPageNumber((prev) => prev - 1)
            }
          }}
          onSortByChange={(option) => {
            setSortBy(option?.value)
          }}
          onOrderByChange={(order) => {
            setOrderBy(order === 'ascending')
          }}
          onLastPage={() => {
            if (get(tableData, 'next_offset', undefined)) {
              apiID.current = uniqueId()
              getData(apiID.current, { offset: get(tableData, 'next_offset', 0) }, (data) => {
                setTableData((prev) => {
                  return {
                    ...data,
                    data: [...get(prev, 'data', []), ...get(data, 'data', [])]
                  }
                })
              })
            }
          }}
          // onExportModifyCSV={getCSVString}
          onExportCSV={(columns) => {
            // onExportCSV()
            exportWidthDelta(
              columns.map((e) => ({
                title: e.title,
                field: e.field,
                hidden: e.hidden ?? false
              }))
            )
          }}
        />
      </Box>
    </>
  )
}
