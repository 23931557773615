import { lazy } from 'react'

const GuardTrends = lazy(() => import('src/pages/members/StoreAvailability/Navs/Trends'))
const GuardRestoreHistory = lazy(() => import('src/pages/members/StoreAvailability/Navs/RestoreHistory'))
const GuardRealtimeStatus = lazy(() => import('src/pages/members/StoreAvailability/Navs/RealtimeStatus'))
const GuardNotifications = lazy(() => import('src/pages/members/StoreAvailability/Navs/Notifications'))
const GuardNotificationsSlack = lazy(() => import('src/pages/members/StoreAvailability/Navs/NotificationsSlack'))
const GuardHistoryByLostSales = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByLostSales'))
const GuardHistoryByAvgDowntime = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByAvgDowntime'))
const GuardHistoryByRestoreImpact = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByRestoreImpact'))
const GuardMatrix = lazy(() => import('src/pages/members/StoreAvailability/Navs/Matrix'))
const GuardDriverWaitTime = lazy(() => import('src/pages/members/DriverWaitTime/DriverWaitTime'))
const GuardRestoreConfig = lazy(() => import('src/pages/members/GuardRestoreConfig/GuardRestoreConfig'))
const Configurations = lazy(() => import('src/pages/members/Configurations/ConfigurationCenter'))

export const guardRoutes = [
  {
    path: 'guard/trends',
    element: <GuardTrends />
  },
  {
    path: 'guard/restore/history',
    element: <GuardRestoreHistory />
  },
  {
    path: 'guard/restore/matrix',
    element: <GuardMatrix />
  },
  {
    path: 'guard/realtime/status',
    element: <GuardRealtimeStatus />
  },
  {
    path: 'guard/configurations/locations',
    element: <Configurations />
  },
  {
    path: 'guard/configurations/reasons',
    element: <GuardRestoreConfig />
  },
  {
    path: 'guard/notifications/slack',
    element: <GuardNotificationsSlack />
  },
  {
    path: 'guard/notifications/email_sms',
    element: <GuardNotifications />
  },
  {
    path: 'guard/history/lost_sales',
    element: <GuardHistoryByLostSales />
  },
  {
    path: 'guard/history/average_downtime',
    element: <GuardHistoryByAvgDowntime />
  },
  {
    path: 'guard/history/restore_impact',
    element: <GuardHistoryByRestoreImpact />
  },
  {
    path: 'guard/driver-wait-time',
    element: <GuardDriverWaitTime />
  }
]
