import { wrapUseRoutes } from '@sentry/react'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { lazy, useEffect, useState } from 'react'
import { matchPath, Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom'
import Loader from 'src/components/mui/Loader'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import NotFound from 'src/pages/public/notFound/NotFound'
import { fetchUserOrgAndRole, firebaseDb } from 'src/services/firebase'
import { sendSlackNotification } from 'src/utils/api'
import { get } from 'src/utils/config/lodashUtils'
import { getLocal } from 'src/utils/functions/localStorage'
import useStandaloneRouteData from 'src/utils/hooks/useStandaloneRouteData'
import { PrivateLayout } from '../../layout'
import generalRoutes from './generalRoutes'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const HomePage = lazy(() => import('src/pages/members/HomePage/HomePage'))
const NotificationCenter = lazy(() => import('src/pages/members/Notifications/NotificationCenter'))

const StudioContainer = lazy(() => import('src/pages/members/StudioContainer'))
const SupersetDashboard = lazy(() => import('src/pages/members/SupersetDashboard'))
const LinkAuthProvider = lazy(() => import('src/pages/members/LinkAuthProvider/LinkAuthProvider'))

//ORDER ACCURACY

const DataRefreshStatus = lazy(() => import('src/pages/members/DataRefreshStatus'))
const OwnerDetails = lazy(() => import('src/pages/members/OwnerDetails/OwnerDetails'))
// const Finance = lazy(() => import('src/pages/members/Finance/Finance'));
// const FinanceBreakdown = lazy(() => import("src/pages/members/Finance/FinanceBreakdown"));

const VbCampaignAllStores = lazy(() => import('src/pages/members/Marketing/VbCampaignAllStores/VbCampaign'))

// Specific Item Performance

const SentryError = lazy(() => import('src/pages/members/Test'))

// Using GPT
// Using GPT

// Slack OAuth
const SlackOAuth = lazy(() => import('src/pages/members/SlackOAuth/SlackOAuth'))

// User Profile
const UserProfile = lazy(() => import('src/pages/members/User/UserProfile/UserProfile'))
const UserManagement = lazy(() => import('src/pages/members/User/Mangement/UserManagement'))
// Redirect using url id

const TestPage = lazy(() => import('src/pages/members/Test/TestPage'))

const Wizard = lazy(() => import('src/pages/members/Wizard/Wizard'))

const Integrations = lazy(() => import('src/pages/members/Integrations/Integrations'))
const OnboardUberEats = lazy(() => import('src/pages/members/Integrations/OnboardUberEats'))

import { guardRoutes } from './guardPrivateRouter'
import { recoverRoutes } from './recoverPrivateRouter'
// const DrillDown = lazy(() => import('src/pages/members/DrillDown/DrillDown'))
import posthog from 'posthog-js'

import { AccessLevelEnum, APP_NAME, APP_VERSION } from 'src/utils/config/config'
import { capitalize } from 'src/utils/config/lodashUtils'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { balanceRoutes } from './balancePrivateRouter'
import { baseRoutes } from './basePrivateRouter'
import { coreRoutes } from './corePrivateRouter'
import { reEngageRoutes } from './reEngagePrivateRouter'
import { truRoiRoutes } from './truRoiPrivateRouter'

const PrivateRouter = React.memo(() => {
  const location = useLocation()
  const { generateStandaloneRoutes } = useStandaloneRouteData()
  const { setPrivateRoutes, routes } = usePrivateLayoutDrawerContext()
  const params = new URLSearchParams(location.search)
  const redirectLink = params.get('redirect')
  const [filteredRouteList, setFilteredRouteList] = useState<RouteObject[]>([])
  const { getFilters, dateRange, refreshFilters } = useFilter()
  const { state } = useLocation()
  const { currentUser, googleStudioLinks, supersetLinks, orgConfig, globalRoutes, getApiData, logout, hasMagicLink, setStandaloneRoutesLoaded, getRoutes } =
    useAuth()

  const navigateLink = React.useMemo(() => {
    if (currentUser && get(currentUser, 'stage') === 'waiting' && !hasMagicLink) {
      return '/create-account/wait'
    }

    if (redirectLink) {
      return redirectLink
    }

    return 'home'
  }, [redirectLink, currentUser, hasMagicLink])

  React.useEffect(() => {
    setPrivateRoutes(filteredRouteList)
  }, [filteredRouteList])

  React.useEffect(() => {
    let unsubscribe
    if (currentUser) {
      const query = doc(firebaseDb, `users/${currentUser.uid}`)

      unsubscribe = onSnapshot(query, async (snapShot) => {
        if (!snapShot.data()) logout()

        const tempUser = getLocal('data')
        if (tempUser) {
          const localSessionVersion = get(tempUser, 'session_version', 0)
          const localFilterVersion = get(tempUser, 'filter_version', 0)
          const localLogoutVersion = get(tempUser, 'logout_version', 0)
          const tempCurrentUser = snapShot.data()
          const sessionVersion = get(tempCurrentUser, 'session_version', 0)
          const filterVersion = get(tempCurrentUser, 'filter_version', 0)
          const logoutVersion = get(tempCurrentUser, 'logout_version', 0)

          if (sessionVersion.toString() !== localSessionVersion.toString()) {
            // refresh session
            const data = await fetchUserOrgAndRole(currentUser.uid)
            await getApiData(currentUser, data)
            refreshFilters(currentUser.uid, currentUser.org)
            sendSlackNotification({
              message: `SESSION REFRESHED FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `SESSION REFRESHED FROM ADMIN`
            })
          }
          if (filterVersion.toString() !== localFilterVersion.toString()) {
            // refresh filter
            refreshFilters(currentUser.uid, currentUser.org)
            sendSlackNotification({
              message: `FILTER REFRESHED FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `FILTER REFRESHED FROM ADMIN`
            })
          }
          if (logoutVersion.toString() !== localLogoutVersion.toString()) {
            // logout user
            logout()
            sendSlackNotification({
              message: `USER LOGGED OUT FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `USER LOGGED OUT FROM ADMIN`
            })
          }
        }
      })
    }

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [currentUser])

  const googleStudioRoutes = googleStudioLinks.map((link) => ({
    path: link.key,
    element: <StudioContainer dashboardLink={link.url} />
  }))

  const privateRouteList: RouteObject[] = [
    ...baseRoutes,
    ...guardRoutes,
    ...recoverRoutes,
    ...balanceRoutes,
    ...truRoiRoutes,
    ...reEngageRoutes,

    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/77268316-60f5-49cd-961f-46d13f300425/page/FNhwC?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/by-brick-and-mortar',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_l9git8fswc?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/by-state-pin',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_xsbju44n1c?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/630a7a053e909af966de42f7248edf9fa2b899f1466b30f669730353799651cf=store_mapping',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/93dab593-0721-433d-80a3-2d6b9c4245fd/page/rM1CD?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },

    {
      path: '/test-internal',
      element: <TestPage />
    },

    {
      path: 'marketing/campaignVBs',
      element: (
        <VbCampaignAllStores
          getFilters={getFilters}
          dateRange={dateRange}
          state={state}
        />
      )
    }
  ]

  const formatRoute = async () => {
    if (orgConfig && currentUser) {
      try {
        const navConfigKeyArr = []
        const temporatyRouteList = []
        const accessLevel = getAccessLevel(currentUser)
        const findAccessLevel = Object.keys(get(orgConfig, 'accessLevels', {}))
        const tempAccessLevel =
          findAccessLevel.includes(accessLevel) && !findAccessLevel.includes(AccessLevelEnum.DEMO) ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN
        let accessRoutes = getRoutes(tempAccessLevel)

        const defaultRoutes = get(orgConfig, 'navConfig', [])
        const filterRoutes = accessRoutes.length > 0 ? accessRoutes : defaultRoutes
        if (filterRoutes) {
          filterRoutes.forEach((item) => {
            if (typeof item === 'string') {
              navConfigKeyArr.push(item)
            } else if (typeof item === 'object') {
              navConfigKeyArr.push(item.uid)
            }
          })
        }
        privateRouteList.forEach((item) => {
          const isPresent = currentUser?.internal || isRouteExist(item.path)
          if (isPresent) {
            temporatyRouteList.push(item)
          }
        })
        // const standaloneRoutes = await generateStandaloneRoutes(navConfigKeyArr)
        setFilteredRouteList([...temporatyRouteList])

        const standaloneRoutes = await generateStandaloneRoutes(navConfigKeyArr)
        // Update filteredRouteList again when standaloneRoutes is ready
        setFilteredRouteList((prevRoutes) => [...prevRoutes, ...standaloneRoutes])
        setStandaloneRoutesLoaded(true)
      } catch (error: any) {
        console.error('Error in PrivateRouter formatRoute', error)
        // YOU CAN IGNORE THIS ERROR
        // BECAUSE THIS IS TOTALLY NEW USER
        // AND USER DOESN'T HAVE ANY ACCESS LEVEL
      }
    }
  }

  useEffect(() => {
    formatRoute()
  }, [orgConfig, currentUser, routes])

  useEffect(() => {
    if (currentUser?.email) {
      window.dataLayer = window.dataLayer || []
      const { uid, email, phoneNumber, org, 'access-level': accessLevel, name: fullName, photoURL: avatar } = currentUser

      const user = {
        id: uid,
        signup_date: Date.now() / 1000,
        userId: uid,
        fullName: capitalize(fullName),
        email,
        phoneNumber,
        company: org,
        accessLevel,
        avatar
      }

      window.dataLayer.push(user)
      window.loop = {
        user,
        meta: {
          name: APP_NAME,
          version: APP_VERSION
        }
      }

      posthog.identify(user.email, { ...window.loop.meta, ...window.loop.user })
      posthog.capture('$pageview', { ...window.loop.meta, ...window.loop.user })
      posthog.group('company', user.company, { ...window.loop.meta, ...window.loop.user })
      posthog.register({ email: user.email })
    }
  }, [currentUser, location.pathname, orgConfig])

  const flatGlobalRoutes = React.useMemo(() => {
    return globalRoutes.reduce(
      (acc, route) => {
        if (route.subNav) {
          route.subNav.forEach((subRoute) => {
            if (subRoute.key) {
              if (subRoute.key[0] === '/') {
                acc.push({ key: subRoute.key, uid: route.uid })
              } else {
                const tempKey = `${route.key}/${subRoute.key}`
                const key = tempKey?.startsWith('/') ? tempKey : '/' + tempKey
                acc.push({ key, uid: route.uid })
              }
            }
          })
        } else {
          const key = route.key?.startsWith('/') ? route.key : '/' + route.key
          acc.push({ key, uid: route.uid })
        }

        return acc
      },
      [] as { key: string; uid: string }[]
    )
  }, [globalRoutes])

  const noAccess = React.useMemo(() => {
    if (currentUser && orgConfig) {
      // debugger
      try {
        const accessLevel = getAccessLevel(currentUser)
        const compareRoutes = getRoutes(accessLevel)
          .map((route) => {
            const routeUid = typeof route === 'string' ? route : route.uid
            const routeObj = globalRoutes.find((e) => e.uid === routeUid)
            return routeObj?.key
          })
          .filter((e) => !!e)

        if (location.pathname === '/' || !compareRoutes.includes(location.pathname)) {
          return false
        }
      } catch (error) {
        console.error("Error in PrivateRouter's noAccess", error)
      }
      return false
    }
    return true
  }, [currentUser, orgConfig, flatGlobalRoutes, globalRoutes, location.pathname])

  const flatRoute = React.useMemo(() => {
    const flatRoutes = routes
      .filter((e) => !!e)
      .map((route) => {
        const arr: string[] = []
        if ('subNav' in route && Array.isArray(route['subNav']) && route['subNav'].length > 0) {
          let subArr: string[] = route['subNav']
            .filter((subNav) => 'key' in subNav && typeof subNav.key === 'string')
            .map((subNav) => (subNav.key[0] === '/' ? subNav.key.slice(1) : `${route.key}/${subNav.key}`))
          arr.push(...subArr)
        } else {
          arr.push(route.key)
        }
        return arr
      })
      .reduce((prev, curr) => [...prev, ...curr], [])
    return flatRoutes
  }, [routes])

  const supersetRoutes = React.useMemo(() => {
    return supersetLinks.map((item) => {
      const route = routes.find(
        (e) =>
          e.key === item.key ||
          (e &&
            Array.isArray(e.subNav) &&
            e.subNav.some((subNav) => (subNav?.key?.startsWith('/') ? subNav?.key === item?.key : `${e.key}/${subNav?.key}` === item.key)))
      )
      return {
        path: item.key,
        element: (
          <SupersetDashboard
            title={get(route, 'label', null)}
            dashboard_id={item.dashboard_id}
            // dashboardLink={`${link.url}?params={"chain_parameter_id":"${currentUser.org}"}`}
          />
        )
      }
    })
  }, [supersetLinks, routes])

  const isRouteExist = (route) => {
    const path = flatRoute.find((e) => {
      const match = matchPath({ path: route, caseSensitive: false }, `/${e}`)
      return match
    })
    return !!path
  }

  const privateRoutes = React.useMemo(() => {
    return [
      {
        path: '/',
        element: <PrivateLayout noAccess={noAccess} />,
        children: [
          ...googleStudioRoutes,
          ...supersetRoutes,
          ...filteredRouteList,
          { path: 'wizard/:wizardID', element: <Wizard /> }, // to be shifted to privateRoute array when there functionality of enabling a route without displaying to side bar
          { index: true, element: <Navigate to={navigateLink} /> },
          { path: 'home', element: <HomePage /> },
          { path: 'data-refresh-status', element: <DataRefreshStatus /> },
          // { path: 'dashboard-main', element: <Dashboardmain /> },
          { path: 'owner-details', element: <OwnerDetails /> },
          { path: 'link-phone', element: <LinkAuthProvider /> },
          { path: 'sentry-error-test', element: <SentryError /> },
          { path: 'connect/slack', element: <SlackOAuth /> },
          { path: 'user/profile', element: <UserProfile /> },
          { path: 'user/management', element: <UserManagement /> },
          {
            path: 'notification-settings',
            element: <NotificationCenter />
          },
          { path: 'integrations', element: <Integrations /> },
          { path: 'onboard/uber', element: <OnboardUberEats /> },
          {
            path: 'access-denied',
            element: (
              <NotFound
                to={navigateLink}
                blockedOnDemo
              />
            )
          },
          {
            path: '*',
            element: <NotFound to={navigateLink} />
          }
        ]
      },

      ...generalRoutes,
      ...coreRoutes,
      {
        path: 'access-denied',
        element: (
          <NotFound
            to={navigateLink}
            blockedOnDemo
          />
        )
      },
      {
        path: '*',
        element: <NotFound to={navigateLink} />
      }
    ]
  }, [googleStudioLinks, generalRoutes, filteredRouteList])

  const newRoutes = useSentryRoutes(privateRoutes)

  if (currentUser && currentUser.stage === 'waiting') {
    return <>{newRoutes}</>
  }

  return <>{filteredRouteList.length > 0 ? newRoutes : <Loader loading />}</>
})

export default PrivateRouter
