import {
  AdminService as TSAdminService,
  BnamesService as TSBnamesService,
  DefaultService as TSDefaultService,
  DigitalStoresService as TSDigitalStoresService,
  MarketingService as TSMarketingService,
  ModuleService as TSModuleService,
  NotificationsService as TSNotificationsService,
  OpenAPI as TSOpenAPI,
  OwnersService as TSOwnersService,
  ReportsService as TSReportsService,
  StoreAvailabilityService as TSStoreAvailabilityService,
  UsersService as TSUsersService
} from '@LoopKitchen/heyapi-client'
import { captureException } from '@sentry/react'
import get from 'lodash/get'
import posthog from 'posthog-js'
import { getTokenCreationFromStorage, getTokenExpireFromStorage } from 'src/services/firebase'
import { sendSlackNotification } from 'src/utils/api'
import {
  CYPRESS_APP_EMAIL,
  CYPRESS_LOGIN_EMAIL,
  CYPRESS_REGISTER_EMAIL,
  CYPRESS_USER_MANAGEMENT_EMAIL,
  blockedApiEndpointsForSandbox
} from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getSession } from 'src/utils/functions/localStorage'
import { addTableIdToRequest, updateTableId } from 'src/utils/functions/openApiSupport'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { getFrontendAlertChannel } from 'src/utils/functions/slackSupport'
import { addAbortMiddleWare } from './utils/functions'

const restrictedEmails = [CYPRESS_REGISTER_EMAIL, CYPRESS_APP_EMAIL, CYPRESS_LOGIN_EMAIL, CYPRESS_USER_MANAGEMENT_EMAIL]
function containsRestrictedEmail(value) {
  if (typeof value === 'string') {
    return restrictedEmails.includes(value)
  }

  if (Array.isArray(value)) {
    return value.some((val) => containsRestrictedEmail(val))
  }

  if (typeof value === 'object' && value) {
    return Object.values(value).some((val) => containsRestrictedEmail(val))
  }

  return false
}

function getProxy<T extends Record<string, any>>(service: T) {
  const proxy = new Proxy(service, {
    get: (target, prop, receiver) => {
      let userObject: Record<string, any> = {}
      try {
        userObject = getSession('data') || {}
      } catch (e) {
        console.error('Error parsing user data from localStorage:', e)
      }
      if (shouldBlockForUser(userObject) && blockedApiEndpointsForSandbox.includes(prop as string)) {
        throw new Error('This action is prohibited in demo mode.')
      }

      if (prop === 'prototype') {
        return Reflect.get(target, prop, receiver)
      }
      const origMethod = target[prop as string]
      return async (...args) => {
        const time = Date.now()
        const funcStr = origMethod.toString()
        const url = funcStr.match(/url:\s*['"]([^'"]+)['"]/)?.[1]
        try {
          args = addTableIdToRequest(prop, args)

          const result = await addAbortMiddleWare(url, origMethod).apply(this, args)

          updateTableId(prop as string, result)

          return result
        } catch (error) {
          const xCache = get(error, 'response.headers.x-cache', 'No x-cache header found')
          console.error({ ...error })
          const browser = navigator && navigator.userAgent ? navigator.userAgent : `No browser found`
          const errorTime = Date.now() - time
          posthog.capture('api_error', { error, method: prop, args, browser, xCache, errorTime })
          console.error(error)
          const message =
            typeof error.body === 'string'
              ? error.body
              : error.body?.content || error.body?.message || error.body?.error || error.message || 'Something went wrong'

          const userContainsRestrictedEmail = restrictedEmails.includes(userObject.email)
          const argsContainRestrictedEmail = containsRestrictedEmail(args)
          const status = get(error, 'status', 'No status found')

          if (status !== 409 && !userContainsRestrictedEmail && !argsContainRestrictedEmail) {
            const hasInvalidToken = message?.toString()?.toLowerCase()?.includes('invalid authentication')
            sendSlackNotification({
              title:
                `Error message: \`${message}\`\n` +
                (hasInvalidToken ? `Expired User Token Details: \n` : ``) +
                (hasInvalidToken ? `Expiration time: \`${getTokenExpireFromStorage()}\`\n` : ``) +
                (hasInvalidToken ? `Creation time: \`${getTokenCreationFromStorage()}\`\n` : ``) +
                (hasInvalidToken ? `Current time: \`${new Date().toUTCString()}\`\n` : ``) +
                `User: \`<https://us.posthog.com/project/26198/person/${encodeURIComponent(userObject.email)}|${userObject.email}>\`\n` +
                `Page URL: \`<${window.location.href}|${window.location.pathname}>\`\n` +
                `API URL: \`${get(error, 'url', undefined) || get(error, 'config.url', url)}\`\n` +
                `Org: \`${userObject?.org}\`\n` +
                `Access Level: \`${getAccessLevel(userObject)}\``,
              message: `Error occurred while calling API \`${target.name}\`.\`${String(prop)}\``,
              channel: getFrontendAlertChannel()
            })
            error.message = `Error occurred while calling method ${target.name}.${String(prop)}`
            captureException(error)
          }
          throw new Error(message)
        }
      }
    }
  })
  return proxy as T
}

const OpenAPI = TSOpenAPI

const DefaultService = getProxy(TSDefaultService)
const AdminService = getProxy(TSAdminService)
const BnamesService = getProxy(TSBnamesService)
const UsersService = getProxy(TSUsersService)
const DigitalStoresService = getProxy(TSDigitalStoresService)
const ModuleService = getProxy(TSModuleService)
const NotificationsService = getProxy(TSNotificationsService)
const OwnersService = getProxy(TSOwnersService)
const ReportsService = getProxy(TSReportsService)
const StoreAvailabilityService = getProxy(TSStoreAvailabilityService)
const MarketingService = getProxy(TSMarketingService)

export * from '@LoopKitchen/heyapi-client'

export {
  AdminService,
  BnamesService,
  DefaultService,
  DigitalStoresService,
  MarketingService,
  ModuleService,
  NotificationsService,
  OpenAPI,
  OwnersService,
  ReportsService,
  StoreAvailabilityService,
  UsersService
}
