import { Sector } from 'recharts'

interface ActiveSectionProps {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill: string
}

export default function ActiveSection(props: ActiveSectionProps) {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props
  return (
    <>
      <g id={`active-section`}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={outerRadius + 5}
          outerRadius={outerRadius + 8}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          fillOpacity={0.5}
          style={{ pointerEvents: 'none' }}
          // stroke={fill}
          // strokeWidth={'2.5%'}
          // strokeOpacity={0.25}
          // style={{ pointerEvents: 'none' }}
        />
        <Sector
          {...props}
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    </>
  )
}
