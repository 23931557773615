import { Drawer } from '@LoopKitchen/loop-ui'
import { Insights, LinkOff, Storefront, SvgIconComponent } from '@mui/icons-material'
import { Box, Chip, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import React from 'react'
import BackArrowBtnComp from 'src/components/OrderDrawer/components/BackArrowBtnComp'
import PlatformLogo from 'src/components/PlatformLogo'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DateRangeType } from 'src/context/NewFilterContext/utils/FilterGraph'
import { useSnackData } from 'src/context/SnackContext'
import {
  CampaignAttributedOrdersPaginated,
  CampaignAudience,
  CampaignDetailPaginatedV3,
  CampaignDetailPaginatedV3Paginated,
  DefaultService
} from 'src/services/openApiV2'
import { get } from 'src/utils/config/lodashUtils'
import { downloadCsv, formatCurrency, formatNumber } from 'src/utils/functions'
import LocalStorage from '../../../MarketingCampaignManagement/utils/LocalStorage'
import AttributedOrdersTable from './AttributedOrdersTable'
import CampaignSalesBreakdown from './CampaignSalesBreakdown'
import { CampaignFlagProperties } from './CampaignTable'

interface IconTooltipProps {
  tooltip: string
  position: 'left' | 'right' | 'top' | 'bottom'
  icon: SvgIconComponent
}

const IconTooltip = ({ tooltip, position, icon: Icon }) => {
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement={position}
      componentsProps={{
        tooltip: {
          style: {
            color: 'white',
            fontSize: 12,
            fontWeight: 400,
            maxWidth: 300,
            padding: '10px',
            backgroundColor: 'rgba(0, 0, 0, 1)'
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}>
      <Box
        sx={{
          fontSize: '18px',
          color: 'black',
          borderRadius: '2px',
          bgcolor: '#E8E8E8',
          width: '25px',
          height: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Icon
          fontSize="inherit"
          color="inherit"
        />
      </Box>
    </Tooltip>
  )
}

const ALGO_TOOLTIP = 'Measured using orders our system could smartly match from order-level financial reports against merchant-reported daily marketing data.'
const MERCHANT_TOOLTIP = 'Measured using merchant-reported daily marketing data'

interface CampaignCardProps {
  title: string
  value: string
  subValue?: string
  description?: string
  tooltip?: string
  icon: SvgIconComponent
}

function CampaignCard(props: CampaignCardProps) {
  const { title, subValue, value, description, icon, tooltip } = props

  return (
    <Grid
      item
      lg={6}
      md={6}
      xs={12}>
      <Box
        sx={{
          border: '1px solid #EEEEEE',
          borderRadius: '2px',
          backgroundColor: 'rgba(253, 253, 253, 1)',
          width: '100%',
          height: '100%',
          p: '16px'
        }}>
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{ mb: 2 }}>
          <Typography
            fontSize="12px"
            fontWeight={600}
            sx={{ mb: 1 }}>
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
          {icon && (
            <IconTooltip
              tooltip={tooltip}
              icon={icon}
              position={'bottom'}
            />
          )}
        </Stack>

        <Box
          display="flex"
          alignItems="flex-end"
          gap={2}>
          <Typography
            fontSize="20px"
            fontWeight={600}>
            {value}
          </Typography>
          {subValue && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-end">
              <Typography
                fontSize="12px"
                fontWeight={400}
                color="rgba(28, 27, 31, 0.8)">
                {subValue}
              </Typography>
            </Box>
          )}
        </Box>
        <Stack mt={2}>
          {description && (
            <Typography
              fontSize="10px"
              fontWeight={400}
              color="rgba(28, 27, 31, 0.8)">
              {description}
            </Typography>
          )}
        </Stack>
      </Box>
    </Grid>
  )
}

interface CampaignDetailDrawerProps {
  campaignDetails: CampaignDetailPaginatedV3
  period?: DateRangeType
  open: boolean
  onClose: () => void
}

export default function CampaignDetailDrawer(props: CampaignDetailDrawerProps) {
  const { onClose, campaignDetails, open, period } = props
  const { handleError } = useErrorData()

  const [data, setData] = React.useState<CampaignDetailPaginatedV3Paginated>(null)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<CampaignDetailPaginatedV3>(null)
  const [stopCampaignLoading, setStopCampaignLoading] = React.useState<CampaignDetailPaginatedV3>(null)
  const { setDownloadSnack, openSuccess } = useSnackData()

  const [tableData, setTableData] = React.useState<CampaignAttributedOrdersPaginated>(null)
  const [loading, setLoading] = React.useState(false)
  const { getFiltersV2, getFilters, lastDate } = useFilter()
  const [orderBy, setOrderBy] = React.useState<string>('campaign_date')
  const [ascending, setAscending] = React.useState<boolean>(true)
  const [activeTab, setActiveTab] = React.useState(0)

  // Add this handler
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const getData = async (
    obj: { slug_in: string; campaign_id_in: string; offset: number; order_by?: string; ascending?: boolean; limit?: number },
    callback: (data: CampaignAttributedOrdersPaginated) => void
  ) => {
    setLoading(true)
    try {
      const filterObj = {
        limit: 100,
        ...obj,
        ...getFiltersV2(['start_date', 'end_date'], true)
      }

      if (period) {
        filterObj['start_date_in'] = period.start.format('YYYY-MM-DD')
        filterObj['end_date_in'] = period.end.format('YYYY-MM-DD')
      }
      // if (showRecentlyAdded) {
      //   filterObj['start_date_in'] = moment(lastDate).subtract(1, 'month').format('YYYY-MM-DD')
      //   filterObj['end_date_in'] = moment(lastDate).format('YYYY-MM-DD')
      // }

      const res = await DefaultService.callCampaignAttributedOrdersApiPaginatedCampaignAttributedOrdersPost({ requestBody: filterObj, isPaginated: true })
      callback(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (!props.campaignDetails) return
    getData({ slug_in: props.campaignDetails.slug, campaign_id_in: props.campaignDetails.campaignId, offset: 0, order_by: orderBy, ascending }, (res) => {
      setTableData(res)
    })
  }, [orderBy, ascending, period, props.campaignDetails])

  const handleClose = () => {
    setActiveTab(0)
    onClose()
  }

  const onLastPage = () => {
    const nextOffset = get(tableData, 'next_offset', undefined)
    if (nextOffset) {
      getData(
        { slug_in: props.campaignDetails.slug, campaign_id_in: props.campaignDetails.campaignId, offset: nextOffset, order_by: orderBy, ascending },
        (res) => {
          setTableData((prev) => ({
            ...res,
            data: [...get(prev, 'data', []), ...get(res, 'data', [])]
          }))
        }
      )
    }
  }

  const getCSVString = async () => {
    const filterObj = {
      slug_in: campaignDetails.slug,
      campaign_id_in: campaignDetails.campaignId,
      ...getFiltersV2(['start_date', 'end_date'], true)
    }

    return (await DefaultService.exportCampaignAttributedOrdersApiExportCampaignAttributedOrdersPost({ requestBody: filterObj })) as any
  }

  const exportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await getCSVString()
      downloadCsv('campaign.csv', res)
      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }

  const stopCampaign = async (model: CampaignDetailPaginatedV3) => {
    setStopCampaignLoading(JSON.parse(JSON.stringify(model)))

    try {
      let res = await DefaultService.stopCampaignsStopCampaignPost({ requestBody: [{ id: model.campaignId, slug: model.slug }] })
      LocalStorage.addActiveCampaignID(model.campaignId)
      openSuccess('Campaign Stopped')
    } catch (err) {
      handleError(err.message)
    }

    setStopCampaignLoading(null)
  }
  
  const calculateMetrics = (campaignDetails) => {
    if (!campaignDetails) return {
      total_spend: 0,
      total_cofund: 0,
      effective_spend: 0,
      gross_payout: 0
    };
    
    const total_spend = campaignDetails.attr_spend + campaignDetails.attr_other_promo_spend + campaignDetails.attr_other_ad_spend;
    const total_cofund = campaignDetails.attr_cofund + campaignDetails.attr_other_promo_cofund + campaignDetails.attr_other_ad_cofund;
    const effective_spend = total_spend - total_cofund;
    const gross_payout = campaignDetails.attr_sales - effective_spend;
    const eff_roi = effective_spend !== 0 ? campaignDetails.attr_sales / effective_spend : 0;
    const gross_margin = campaignDetails.attr_sales !== 0 ? 100*gross_payout / campaignDetails.attr_sales : 0;
    const eff_cofunding_perc = total_spend !== 0 ? 100*total_cofund / total_spend : 0;
    const aov = campaignDetails.attr_orders > 0 ? campaignDetails.attr_sales / campaignDetails.attr_orders : 0;
    const cpo = campaignDetails.attr_orders > 0 ? effective_spend / campaignDetails.attr_orders : 0;
    return {
      eff_roi,
      eff_cofunding_perc,
      gross_margin,
      aov,
      cpo
    };
  };

// Then in your component:
  const metrics = React.useMemo(() => calculateMetrics(campaignDetails), [campaignDetails]);
  const { eff_roi, eff_cofunding_perc, gross_margin, aov, cpo } = metrics;
  
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}>
      <Box sx={{ width: 'calc(100vw - 300px)', minWidth: '300px', minHeight: '100%' }}>
        {campaignDetails && (
          <Box>
            <Stack
              direction="row"
              alignItems="flex-start">
              <BackArrowBtnComp onClick={handleClose} />
              <Box
                sx={{ flex: 1 }}
                marginLeft={2}
                marginRight={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="10px">
                  <Box>
                    <PlatformLogo
                      shortImg
                      platformName={campaignDetails.vb_platform}
                      width="30x"
                      height="30px"
                    />
                  </Box>

                  <Typography
                    fontSize="20px"
                    color="#000"
                    sx={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}>
                    <span style={{ fontWeight: 700 }}>{campaignDetails.campaignName}</span>
                    <span>
                      {' '}
                      for {campaignDetails.vb_name} ({campaignDetails.b_name})
                    </span>
                  </Typography>
                </Stack>

                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 2,
                    mt: 1,
                    minHeight: '32px', // Reduced tab height
                    '& .MuiTab-root': {
                      minHeight: '32px', // Reduced tab height
                      padding: '6px 16px' // Reduced padding
                    }
                  }}>
                  <Tab
                    label={
                      <Typography
                        fontSize="12px"
                        fontWeight={600}>
                        Overview
                      </Typography>
                    }
                  />
                </Tabs>
                {activeTab === 0 && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="9px"
                      flexWrap="wrap"
                      sx={{ mt: '20px' }}>
                      {campaignDetails.startTime && (
                        <Tooltip title={`Duration`}>
                          <Chip
                            sx={{ borderRadius: '12px', fontSize: '12px', fontWeight: 500 }}
                            label={`From ${moment(campaignDetails.startTime).format('Do MMM YYYY')} till ${!campaignDetails.endTime || campaignDetails.endTime === null || campaignDetails.endTime === '' || Math.abs(moment().diff(moment(campaignDetails.endTime), 'years')) >= 90 ? 'Indefinite' : moment(campaignDetails.endTime).format('Do MMM YYYY')}`}
                          />
                        </Tooltip>
                      )}
                      {campaignDetails.audience !== CampaignAudience.UNKNOWN && (
                        <Tooltip title={`Audience`}>
                          <Chip
                            sx={{ borderRadius: '12px', fontSize: '12px', fontWeight: 500 }}
                            label={campaignDetails.audience}
                          />
                        </Tooltip>
                      )}
                      {campaignDetails.campaign_flag && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="10px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['low_performing'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['low_performing'].icon}
                          <Typography
                            color={CampaignFlagProperties['low_performing'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['low_performing'].title}
                          </Typography>
                        </Box>
                      )}
                      {moment(campaignDetails.startTime).isSameOrAfter(moment(lastDate).subtract(1, 'month')) && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="10px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['recently_added'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['recently_added'].icon}
                          <Typography
                            color={CampaignFlagProperties['recently_added'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['recently_added'].title}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
                      <Typography
                        fontSize="12px"
                        fontWeight={600}
                        color="#696C80">
                        Metric Source
                      </Typography>
                      <IconTooltip
                        tooltip={
                          'Measured using orders our system could smartly match from order-level financial reports against merchant-reported daily marketing data.'
                        }
                        icon={Insights}
                        position={'bottom'}
                      />
                      <IconTooltip
                        tooltip={'Measured using merchant-reported daily marketing data'}
                        icon={Storefront}
                        position={'bottom'}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="stretch"
                      flexWrap="wrap"
                      gap={2}
                      sx={{ mt: '10px', mb: '20px' }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ flex: 1.5 }}>
                        <CampaignCard
                          icon={Insights}
                          title="Gross Margin"
                          value={formatNumber(gross_margin, { maxFractionDigits: 2 }) + '%'}
                          tooltip={ALGO_TOOLTIP}
                          description="The ratio of (Gross Payout) to (Marketing Driven Sales) for orders attributed to this campaign"
                        />
                        <CampaignCard
                          icon={Storefront}
                          title="Weekly Budget"
                          value={
                            campaignDetails.budget && campaignDetails.budget > 0 ? formatCurrency(campaignDetails.budget, { maxFractionDigits: 0 }) : 'Uncapped'
                          }
                          description="Target marketing spend allocated per week for this campaign"
                          tooltip={MERCHANT_TOOLTIP}
                        />
                        <CampaignCard
                          icon={Insights}
                          title="Effective ROI"
                          value={formatNumber(eff_roi, { maxFractionDigits: 1 }) + 'x'}
                          tooltip={ALGO_TOOLTIP}
                          description="The ratio of (Marketing Driven Sales) to (Effective Marketing Spend) for orders attributed to this campaign"
                        />

                        <CampaignCard
                          icon={Storefront}
                          title="3P ROI"
                          value={formatNumber(campaignDetails.roi, { maxFractionDigits: 1 }) + 'x'}
                          description="The ratio of (Marketing Driven Sales) to (Marketing Spend) from merchant-reported daily marketing data"
                          tooltip={MERCHANT_TOOLTIP}
                        />

                        <CampaignCard
                          icon={Insights}
                          title="Effective Cofunding %"
                          value={formatNumber(eff_cofunding_perc,{ maxFractionDigits: 0 })+ '%'}
                          description='Percentage (Total cofunding) incurred on orders in this campaign. Also included overlapping cofunding from other campaigns applied to the same order.'
                          tooltip={ALGO_TOOLTIP}
                        />
                        
                        <CampaignCard
                          icon={Insights}
                          title="Double Dip Rate"
                          value={formatNumber(campaignDetails.attr_multi_campaign_orders_perc, { maxFractionDigits: 2 }) + '%'}
                          // subValue={`/ ${campaignDetails.attr_multi_campaign_orders} orders`}
                          description="Percentage of orders in this campaign having marketing spends attributed to other campaigns."
                          tooltip={ALGO_TOOLTIP}
                        />
                        <CampaignCard
                          icon={Insights}
                          title="Average Order Value"
                          value={formatCurrency(aov, { maxFractionDigits: 2 })}
                          // subValue={`/ ${campaignDetails.attr_multi_campaign_orders} orders`}
                          description="The ratio of (Sales) to (Orders) for orders attributed to this campaign."
                          tooltip={ALGO_TOOLTIP}
                        />

                        <CampaignCard
                          icon={Insights}
                          title="Cost per Order"
                          value={formatCurrency(cpo , { maxFractionDigits: 2 })}
                          // subValue={`/ ${campaignDetails.attr_multi_campaign_orders} orders`}
                          description="The ratio of (Effective Marketing Spend) to (Orders) for orders attributed to this campaign."
                          tooltip={ALGO_TOOLTIP}
                        />
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                      />
                      <Stack
                        direction="column"
                        alignItems="stretch"
                        gap="10px"
                        flexWrap="wrap"
                        sx={{ flex: 1 }}>
                        <CampaignSalesBreakdown charges={campaignDetails} />
                      </Stack>
                    </Stack>
                  </>
                )}
                {/*{activeTab === 0 && (*/}
                {/*  <AttributedOrdersTable*/}
                {/*    isLoading={loading}*/}
                {/*    tableData={tableData}*/}
                {/*    onLastPage={onLastPage}*/}
                {/*    onExportCSV={exportCSV}*/}
                {/*    onExportModifyCSV={getCSVString}*/}
                {/*    onOrderByChange={(type) => {*/}
                {/*      setAscending(type === 'ascending')*/}
                {/*    }}*/}
                {/*    onSortByChange={(option) => {*/}
                {/*      setOrderBy(option.value)*/}
                {/*    }}*/}
                {/*  />*/}
                {/*)}*/}
              </Box>
            </Stack>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}
