import React from 'react'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { ChannelFulfillmentSankey, DefaultService, OrderBreakdownResponse, PLATFORM } from 'src/services/openApiV2'
import { skipAbortController } from 'src/services/openApiV2/utils/functions'

export interface TransactionsPlatformBreakdownInterface {
  doordash: OrderBreakdownResponse
  ubereats: OrderBreakdownResponse
  grubhub: OrderBreakdownResponse
  ezcater: OrderBreakdownResponse
  olo: OrderBreakdownResponse
}
interface TransactionsBreakdownDataContextInterface {
  breakdownData: OrderBreakdownResponse | null
  breakdownLoading: boolean
  platformBreakdownData: TransactionsPlatformBreakdownInterface | null
  platformBreakdownLoading: boolean
  channelFulfillmentDataLoading: boolean
  channelFulfillmentData: ChannelFulfillmentSankey[]
  channel?: string[]
  fulfillment?: string[]
}

const TransactionsBreakdownDataContext = React.createContext<TransactionsBreakdownDataContextInterface>({
  breakdownData: null,
  breakdownLoading: true,
  platformBreakdownData: {
    doordash: null,
    ubereats: null,
    grubhub: null,
    ezcater: null,
    olo: null
  },
  platformBreakdownLoading: true,
  channelFulfillmentDataLoading: true,
  channelFulfillmentData: []
})

export const useTransactionsBreakdownDataContext = () => React.useContext(TransactionsBreakdownDataContext)

interface TransactionsBreakdownDataContextProviderProps {
  children: React.ReactNode
  channel?: string[]
  fulfillment?: string[]
}

export default function TransactionsBreakdownDataContextProvider(props: TransactionsBreakdownDataContextProviderProps) {
  const { channel, fulfillment } = props
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { getAllPossibleFilters } = useNewFilterContext()
  const [breakdownData, setBreakdownData] = React.useState<OrderBreakdownResponse>(null)
  const [platformBreakdownData, setPlatformBreakdownData] = React.useState<TransactionsPlatformBreakdownInterface>({
    doordash: null,
    ubereats: null,
    grubhub: null,
    ezcater: null,
    olo: null
  })
  const [breakdownLoading, setBreakdownLoading] = React.useState(true)
  const [platformBreakdownLoading, setPlatformBreakdownLoading] = React.useState(true)
  const [channelFulfillmentDataLoading, setChannelFulfillmentDataLoading] = React.useState(true)
  const [channelFulfillmentData, setChannelFulfillmentData] = React.useState<ChannelFulfillmentSankey[]>([])

  const filterObj = React.useMemo(() => {
    const obj = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)
    return {
      ...obj,
      channel_in: channel?.join('|') || '',
      fulfillment_in: fulfillment?.join('|') || ''
    }
  }, [channel, fulfillment, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'])])

  const getBreakdownData = async () => {
    setBreakdownLoading(true)
    try {
      const res = await skipAbortController(DefaultService.getOrderBreakdownFinanceOrderBreakdownPost)({ requestBody: filterObj }) as any
      setBreakdownData(res)
    } catch (err) {
      handleError(err.message)
    }
    setBreakdownLoading(false)
  }

  const isPlatformPresent = (filterPlatforms, platform) => {
    return filterPlatforms?.some((p) => p.toLowerCase() === platform.toLowerCase())
  }

  const getPlatformBreakdownData = async () => {
    setPlatformBreakdownLoading(true)
    try {
      const allPlatforms = getAllPossibleFilters(['vb_platform']).vb_platform as string[]
      const breakdownBody = filterObj
      const filterPlatforms = getFiltersV2(['vb_platform']).vb_platform_in

      const platformPromises = [PLATFORM.DOORDASH, PLATFORM.UBER_EATS, PLATFORM.GRUBHUB, PLATFORM.EZ_CATER, PLATFORM.OLO].map((platform) =>
        isPlatformPresent(filterPlatforms === '' ? allPlatforms : filterPlatforms?.split('|'), platform)
          ? skipAbortController(DefaultService.getOrderBreakdownFinanceOrderBreakdownPost)({
              requestBody: {
                ...breakdownBody,
                vb_platform_in: platform
              }
            })
          : Promise.resolve(null)
      )
      const [doordash, ubereats, grubhub, ezcater, olo] = await Promise.all([...platformPromises])

      setPlatformBreakdownData({
        doordash: doordash,
        ubereats: ubereats,
        grubhub: grubhub,
        ezcater: ezcater,
        olo: olo
      })
    } catch (err) {
      handleError(err.message)
    }

    setPlatformBreakdownLoading(false)
  }

  const getChannelFulfillmentData = async () => {
    setChannelFulfillmentDataLoading(true)
    try {
      const res = await DefaultService.callKpiDashboardChannelFulfillmentSankeyApiKpiDashboardChannelFulfillmentSankeyPost({ requestBody: filterObj })
      setChannelFulfillmentData(res)
    } catch (err) {
      console.error('Error in channel and fulfillment data: ', err)
      handleError('Something went wrong while fetching channel and fulfillment data')
    }
    setChannelFulfillmentDataLoading(false)
  }

  React.useEffect(() => {
    getBreakdownData()
    getPlatformBreakdownData()
    getChannelFulfillmentData()
  }, [filterObj])

  const contextValue = React.useMemo(() => {
    return {
      breakdownData,
      breakdownLoading,
      platformBreakdownData,
      platformBreakdownLoading,
      channelFulfillmentData,
      channelFulfillmentDataLoading,
      channel,
      fulfillment
    }
  }, [
    channel,
    fulfillment,
    breakdownData,
    breakdownLoading,
    platformBreakdownData,
    platformBreakdownLoading,
    channelFulfillmentData,
    channelFulfillmentDataLoading
  ])

  return <TransactionsBreakdownDataContext.Provider value={contextValue}>{props.children}</TransactionsBreakdownDataContext.Provider>
}
