import React, { createContext, useContext, useMemo, useState } from 'react'
interface GlobalDialogContextInterface {
  globalDialog: GlobalDialogTypes
  setGlobalDialog: React.Dispatch<React.SetStateAction<GlobalDialogTypes>>
}

interface GlobalDialogTypes {
  open: boolean
  text: string
}

const initialState = {} as GlobalDialogContextInterface

const GlobalDialogContext = createContext<GlobalDialogContextInterface>(initialState)

export const useGlobalDialogData = (): GlobalDialogContextInterface => useContext(GlobalDialogContext)

const GlobalDialogContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [globalDialog, setGlobalDialog] = useState<GlobalDialogTypes>({
    open: false,
    text: ''
  })

  const contextValue = useMemo(() => ({ globalDialog, setGlobalDialog }), [globalDialog, setGlobalDialog])

  return <GlobalDialogContext.Provider value={contextValue}>{children}</GlobalDialogContext.Provider>
}

export default GlobalDialogContextProvider
