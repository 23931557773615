import { PaginationTableComp, SearchTableComp, SingleSelect, SortByComp } from '@LoopKitchen/loop-ui'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import BoltIcon from '@mui/icons-material/Bolt'
import ChatIcon from '@mui/icons-material/Chat'
import InfoIcon from '@mui/icons-material/Info'
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead'
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread'
import RateReviewIcon from '@mui/icons-material/RateReview'
import ReviewsIcon from '@mui/icons-material/Reviews'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, FormControlLabel, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import IconCard from 'src/components/Cards/IconCard'
import OrderDrawer, { OrderDrawerDetails } from 'src/components/OrderDrawer/OrderDrawer'
import PlatformLogo from 'src/components/PlatformLogo'
import IOSSwitch from 'src/components/Switches/IOSSwitch'
import { StyledBox } from 'src/components/mui/Reusable'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { DefaultService, ReviewAggregate, ReviewPaginated, ReviewsSummaryData } from 'src/services/openApiV2'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { formatNumber } from 'src/utils/functions'
import ListComp from '../../../ReviewsAndRatings/components/GeneratedAndAutomated/ListComp'
import ReviewAndRatingComp from '../../../ReviewsAndRatings/components/GeneratedAndAutomated/ReviewAndRatingComp'

export default function GenerateAndAutomate(props: { filter_vb_name?: string; filter_b_name?: string; filter_vb_platform?: string }) {
  const { getFilters: getFiltersGlobal, getFiltersV2: getFiltersV2Global } = useFilter()
  const { openError } = useSnackData()
  const [ratingValue, setRatingValue] = React.useState<number>(0)
  const [customerValue, setCustomerValue] = React.useState<string>()
  const [statusValue, setStatusValue] = React.useState<'all' | 'replied' | 'not_replied'>('all')
  const [searchText, setSearchText] = React.useState('')
  const [orderBy, setOrderBy] = React.useState<string>()
  const [ascending, setAscending] = React.useState(false)
  const [aggregateLoading, setAggregateLoading] = React.useState(false)
  const [aggregateData, setAggregateData] = React.useState<ReviewAggregate>(null)
  const [reviewLoading, setReviewLoading] = React.useState(false)
  const [reviewsData, setReviewsData] = React.useState<ReviewPaginated | null>(null)
  const [reviewSummaryLoading, setReviewSummaryLoading] = React.useState(false)
  const [reviewSummaryData, setReviewSummaryData] = React.useState<ReviewsSummaryData>(null)
  const [paginatedIndexes, setPaginatedIndexes] = React.useState({
    startIndex: 0,
    endIndex: 0
  })
  const [nonExpired, setNonExpired] = React.useState(false)
  const [hasFeedback, setHasFeedback] = React.useState(false)
  const [orderDetails, setOrderDetails] = React.useState<OrderDrawerDetails>(null)

  const getFilters = (filterList: any) => {
    const filters = getFiltersGlobal(filterList)
    for (let i = 0; i < filterList.length; i++) {
      const filter = filterList[i]
      const propValue = props['filter_' + filter]
      if (propValue) {
        filters[i] = propValue
      }
    }
    return filters
  }
  const getFiltersV2 = (filterList?: string[], dateWithHyphen?: boolean) => {
    const filters = getFiltersV2Global(filterList, dateWithHyphen)
    for (let i = 0; i < filterList.length; i++) {
      const filter = filterList[i]
      const propValue = props['filter_' + filter]
      if (propValue) {
        filters[filter + '_in'] = propValue
      }
    }
    return filters
  }

  const reviewSummaryID = React.useRef<number>()

  const getReviewSummary = async (id: number) => {
    setReviewSummaryLoading(true)
    try {
      const review_text = get(reviewsData, 'data', [] as typeof reviewsData.data)
        .filter((e) => e.feedback)
        .map((e) => e.feedback)
      const res = await DefaultService.generateReviewSummaryReviewsSummaryGeneratePost({
        requestBody: {
          params: {
            ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'])
          },
          review_text
        }
      })
      if (reviewSummaryID.current === id) {
        setReviewSummaryData(res)
      }
    } catch (err) {
      openError('Summary Error: ' + err.message)
    }
    if (reviewSummaryID.current === id) {
      setReviewSummaryLoading(false)
    }
  }

  const getReviews = async (body: { limit: number; offset: number }, callback: (data: ReviewPaginated) => void) => {
    setReviewLoading(true)
    try {
      const filterObj = getFiltersV2(['chain', 'vb_name', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      const res = await DefaultService.callReviewApiPaginatedReviewPost({
        requestBody: {
          limit: get(body, 'limit', 100),
          offset: get(body, 'offset', 0),
          search_term: searchText,
          order_by: orderBy,
          ascending,
          rating_in: ratingValue ? ratingValue.toString() : undefined,
          non_expired: nonExpired,
          has_feedback: hasFeedback,
          replied: statusValue === 'replied' ? true : statusValue === 'not_replied' ? false : undefined,
          ...filterObj
        },
        isPaginated: true
      })
      callback(res)
    } catch (err) {
      openError(err.message)
    }
    setReviewLoading(false)
  }

  React.useEffect(() => {
    setReviewsData(null)
    getReviews({ limit: Math.max(get(reviewsData, 'data.length', 0), 100), offset: 0 }, (data) => {
      setReviewsData(data)
    })
  }, [
    searchText,
    ascending,
    orderBy,
    ratingValue,
    statusValue,
    nonExpired,
    hasFeedback,
    ...getFilters(['chain', 'vb_name', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])
  ])

  React.useEffect(() => {
    if (reviewsData?.data.length === 0) return
    reviewSummaryID.current = Math.random()
    getReviewSummary(reviewSummaryID.current)
  }, [reviewsData, ...getFilters(['chain', 'vb_name', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  React.useEffect(() => {
    const getAggregateData = async () => {
      setAggregateLoading(true)
      try {
        const filterObj = getFiltersV2(['chain', 'vb_name', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
        const res = await DefaultService.callReviewAggregateApiReviewAggregatePost({ requestBody: filterObj })
        setAggregateData(res)
      } catch (err) {
        openError(err.message)
        setAggregateData(null)
      }
      setAggregateLoading(false)
    }
    getAggregateData()
  }, [...getFilters(['chain', 'vb_name', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  const ratingOptions = React.useMemo(() => {
    const options = [1, 2, 3, 4, 5].map((rating) => ({
      label: `${rating} Star${rating > 1 ? 's' : ''}`,
      value: rating
    }))
    return [{ label: 'All Ratings', value: 0 }, ...options]
  }, [])

  return (
    <>
      <OrderDrawer
        orderDetails={orderDetails}
        onClose={() => {
          setOrderDetails(null)
        }}
      />
      <Stack
        direction="row"
        alignItems="stretch"
        gap="17px">
        <IconCard
          loading={aggregateLoading}
          icon={
            <span
              style={{
                fontSize: '30px',
                color: 'rgba(246, 186, 96, 1)',
                display: 'flex'
              }}>
              <StarRoundedIcon
                fontSize="inherit"
                color="inherit"
              />
            </span>
          }
          label="Avg. Rating"
          title={
            <>
              {formatNumber(get(aggregateData, 'avg_rating', 0), {
                maxFractionDigits: 2
              })}{' '}
              <span style={{ fontSize: '16px' }}>
                (
                {formatNumber(get(aggregateData, 'tot_ratings', 0), {
                  maxFractionDigits: 0
                })}{' '}
                ratings)
              </span>
            </>
          }
          sx={{ flex: 1 }}
        />
        <IconCard
          loading={aggregateLoading}
          icon={
            <span
              style={{
                fontSize: '30px',
                color: 'rgba(0, 0, 0, 1)',
                display: 'flex'
              }}>
              <ChatIcon
                fontSize="inherit"
                color="inherit"
              />
            </span>
          }
          label="Total Number of Reviews"
          title={
            <>
              {formatNumber(get(aggregateData, 'tot_reviews', 0), {
                maxFractionDigits: 0
              })}{' '}
              {
                <span style={{ fontSize: '16px' }}>
                  (
                  {formatNumber(get(aggregateData, 'feedback_reviews', 0), {
                    maxFractionDigits: 0
                  })}{' '}
                  have feedback)
                </span>
              }
            </>
          }
          sx={{ flex: 1 }}
        />
        <IconCard
          loading={aggregateLoading}
          icon={
            <span
              style={{
                fontSize: '30px',
                color: 'rgba(14, 140, 67, 0.89)',
                display: 'flex'
              }}>
              <MarkChatReadIcon
                fontSize="inherit"
                color="inherit"
              />
            </span>
          }
          label="Replied reviews"
          title={formatNumber(get(aggregateData, 'tot_replied', 0), {
            maxFractionDigits: 0
          })}
          sx={{ flex: 1 }}
        />
        <IconCard
          loading={aggregateLoading}
          icon={
            <span
              style={{
                fontSize: '30px',
                color: 'rgba(247, 53, 64, 1)',
                display: 'flex'
              }}>
              <MarkChatUnreadIcon
                fontSize="inherit"
                color="inherit"
              />
            </span>
          }
          label="Waiting for response"
          title={formatNumber(get(aggregateData, 'tot_not_replied', 0), {
            maxFractionDigits: 0
          })}
          sx={{ flex: 1 }}
        />
      </Stack>
      {!aggregateLoading && aggregateData === null && (
        <Box sx={{ px: 2, py: 3 }}>
          <Typography
            variant="subtitle2"
            textAlign={'center'}>
            Aggregate data not available
          </Typography>
        </Box>
      )}

      <Grid
        container
        columnSpacing={'20px'}
        sx={{ mt: '22px' }}>
        <Grid
          item
          sm={12}
          md={8}>
          <StyledBox sx={{ p: '27px', borderRadius: '4px' }}>
            <Stack
              direction="row"
              alignItems="center"
              gap="15px">
              <Box sx={{ color: 'black', display: 'flex', fontSize: '18px' }}>
                <RateReviewIcon
                  color="inherit"
                  fontSize="inherit"
                />
              </Box>
              <Typography
                fontSize="14px"
                lineHeight="20px"
                fontWeight={600}>
                Reviews and ratings
              </Typography>
            </Stack>

            <Box
              sx={{
                mt: '15px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}>
              <Box
                sx={{
                  px: '10px',
                  py: '8px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                  borderRadius: '2px',
                  border: '1px solid #E6E6E6',
                  bgcolor: '#F9F9F9'
                }}>
                <PlatformLogo
                  shortImg
                  platformName="doordash"
                  height="18px"
                  boxSx={{ display: 'flex', minWidth: '20px' }}
                />
                <Typography
                  variant="subtitle2"
                  color="#122937">
                  Doordash will take 2 days to update the responses
                </Typography>
              </Box>
              <FormControlLabel
                sx={{ ml: 2 }}
                checked={nonExpired}
                onChange={(e, checked) => {
                  setNonExpired(checked)
                }}
                control={<IOSSwitch sx={{ mr: 1 }} />}
                label={<Typography variant="subtitle2">Show non expired reviews only</Typography>}
              />
              <FormControlLabel
                sx={{ ml: 2 }}
                checked={hasFeedback}
                onChange={(e, checked) => {
                  setHasFeedback(checked)
                }}
                control={<IOSSwitch sx={{ mr: 1 }} />}
                label={<Typography variant="subtitle2">Show reviews with feedback only</Typography>}
              />
            </Box>

            <Stack
              direction={'row'}
              alignItems={'flex-end'}
              flexWrap={'wrap'}
              gap="5px"
              sx={{ my: '12px' }}>
              <Box sx={{ flex: 1 }}>
                <SingleSelect
                  options={ratingOptions}
                  value={ratingValue}
                  onChange={(value) => {
                    setRatingValue(value)
                  }}
                  renderValue={(value) => (
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color={'#000'}>
                      {ratingOptions.find((e) => e.value === value)?.label}
                    </Typography>
                  )}
                  selectButtonSx={{ width: '200px' }}
                  disableSearch
                  disableSort
                />
                <SingleSelect
                  label="Status"
                  value={statusValue}
                  onChange={(value) => {
                    setStatusValue(value)
                  }}
                  options={[
                    { label: 'All reviews', value: 'all' },
                    { label: 'Replied reviews', value: 'replied' },
                    { label: 'Not replied', value: 'not_replied' }
                  ]}
                  selectButtonSx={{ width: '200px', ml: '5px' }}
                  disableSearch
                  disableSort
                />
                {/* <CustomBasicSelect/>
                <StatusSelect
                  value={statusValue}
                  onChange={(value) => setStatusValue(value)}
                  options={['Option 1', 'Option 2']}
                /> */}
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              gap="5px"
              width={'100%'}
              sx={{ pl: '15px' }}>
              <SearchTableComp
                absoluteInputPosition
                debounceMilliseconds={2000}
                onChange={(value) => {
                  setSearchText(value)
                }}
              />
              <SortByComp
                options={[
                  { label: filterNames.vb_name, value: 'vb_name' },
                  { label: filterNames.b_name, value: 'b_name' },
                  { label: 'Review ID', value: 'reviewId' },
                  { label: 'Rating', value: 'rating' },
                  { label: 'Order Timestamp', value: 'order_timestamp' },
                  { label: 'Days left to reply', value: 'days_left' }
                ]}
                onChange={(selectedOption) => {
                  setOrderBy(selectedOption?.value)
                }}
                showOrderBy={true}
                initialOrderBy={ascending ? 'ascending' : 'descending'}
                onOrderByChange={(type) => {
                  setAscending(type === 'ascending')
                }}
                selectSx={{
                  width: '150px'
                }}
              />
              {/* <ExportTableComp onExportCSV={() => {}} /> */}
            </Stack>

            <Stack
              direction="column"
              alignItems="stretch"
              gap="15px"
              sx={{ mt: '10px' }}>
              {reviewLoading ? (
                <Stack gap={2}>
                  {new Array(5).fill(0).map((elm) => (
                    <ReviewAndRatingComp
                      loading={true}
                      days_left={undefined}
                      platform={''}
                      storeName={''}
                      reviewID={''}
                      storeID={''}
                      deliveryUUID={''}
                      rating={undefined}
                      date={undefined}
                      reviewText={undefined}
                      replyText={undefined}
                    />
                  ))}
                </Stack>
              ) : (
                get(reviewsData, 'data', [] as typeof reviewsData.data)
                  .slice(get(paginatedIndexes, 'startIndex', 0), get(paginatedIndexes, 'endIndex', 0))
                  .map((review, index) => {
                    return (
                      <ReviewAndRatingComp
                        key={index}
                        days_left={get(review, 'days_left', undefined)}
                        platform={get(review, 'vb_platform', '')}
                        storeName={get(review, 'vb_name', '')}
                        reviewID={get(review, 'reviewId', '')}
                        storeID={get(review, 'store_id', '')}
                        deliveryUUID={get(review, 'deliveryuuid', '')}
                        rating={get(review, 'rating', undefined)}
                        date={get(review, 'order_timestamp', undefined)}
                        reviewText={get(review, 'feedback', undefined)}
                        replyText={get(review, 'reply', undefined)}
                        loading={reviewLoading}
                        onOrderIdClick={
                          review?.deliveryuuid
                            ? () => {
                                setOrderDetails({
                                  order_id: review.deliveryuuid,
                                  slug: review.slug,
                                  open: true,
                                  order_date: review.order_timestamp
                                })
                              }
                            : undefined
                        }
                      />
                    )
                  })
              )}
              {reviewsData && (
                <Stack
                  direction="row"
                  justifyContent="flex-end">
                  <PaginationTableComp
                    totalDataLength={get(reviewsData, 'data.length', 0)}
                    totalPaginatedDataLength={get(reviewsData, 'max_rows', undefined)}
                    onChange={(obj) => {
                      setPaginatedIndexes(obj)
                    }}
                    onLastPage={() => {
                      const next_offset = get(reviewsData, 'next_offset', undefined)
                      if (next_offset) {
                        getReviews({ limit: 100, offset: next_offset }, (data) => {
                          setReviewsData((prev) => {
                            return {
                              ...data,
                              data: [...prev.data, ...data.data]
                            }
                          })
                        })
                      }
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </StyledBox>
        </Grid>
        <Grid
          item
          sm={12}
          md={4}>
          <StyledBox sx={{ px: '23px', py: '27px', borderRadius: '4px' }}>
            <Stack
              direction="row"
              alignItems="center"
              gap="15px">
              <Box sx={{ color: 'black', display: 'flex', fontSize: '18px' }}>
                <ReviewsIcon
                  color="inherit"
                  fontSize="inherit"
                />
              </Box>
              <Typography
                fontSize="14px"
                lineHeight="20px"
                fontWeight={600}
                sx={{ flex: 1 }}>
                Summary
              </Typography>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  reviewSummaryID.current = Math.random()
                  getReviewSummary(reviewSummaryID.current)
                }}
                disabled={reviewSummaryLoading}
                startIcon={<AutoAwesomeIcon />}>
                RE-GENERATE
              </Button>
            </Stack>

            {/* {reviewSummaryLoading && (
              <Stack p={2}>
                <LoadingCard height={200} />
              </Stack>
            )} */}

            {!reviewSummaryLoading && !reviewSummaryData?.summary && !reviewSummaryData?.action_items && !reviewSummaryData?.problematic_menu_items && (
              <Box sx={{ px: 2, py: 3 }}>
                <Typography variant="subtitle2">Summary data not available</Typography>
              </Box>
            )}

            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              sx={{ my: '15px' }}>
              {reviewSummaryLoading ? (
                <Skeleton
                  variant="text"
                  width={200}
                />
              ) : (
                reviewSummaryData?.summary &&
                reviewSummaryData?.action_items &&
                reviewSummaryData?.problematic_menu_items && (
                  <>
                    <Box sx={{ fontSize: '15px', color: 'rgba(232, 234, 235, 1)' }}>
                      <InfoIcon
                        color="inherit"
                        fontSize="inherit"
                      />
                    </Box>
                    <Typography
                      fontSize="11px"
                      lineHeight="12px">
                      This summary will be generated based on the selected filters above
                    </Typography>
                  </>
                )
              )}
            </Stack>

            <StyledBox sx={{ px: '23px', py: '22px', borderRadius: '4px' }}>
              {reviewSummaryLoading ? (
                <Skeleton
                  variant="text"
                  width={'100%'}
                />
              ) : (
                <Typography
                  fontSize="12px"
                  lineHeight="24px">
                  {get(reviewSummaryData, 'summary', '')}
                </Typography>
              )}
              {!reviewSummaryLoading && (
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="10px"
                    sx={{ mt: '20px' }}>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ bgcolor: '#E6E6E6', minWidth: '23px', height: '23px', borderRadius: '2px' }}>
                      <AutoAwesomeIcon sx={{ color: '#1E1E1E', fontSize: '18px' }} />
                    </Stack>
                    <Typography sx={{ fontSize: '11px', color: '#122937', opacity: 0.6, lineHeight: '144%' }}>
                      Generated by AI; please review for accuracy and bias.
                    </Typography>
                  </Stack>
                </>
              )}
            </StyledBox>

            <Box sx={{ mt: '8px' }}>
              <ListComp
                bgcolor="rgba(242, 246, 246, 1)"
                headingColor="rgba(25, 110, 130, 1)"
                listIndexBgColor="rgba(25, 110, 130, 0.21)"
                listIndexColor="rgba(25, 110, 130, 1)"
                heading="Action Items"
                Icon={BoltIcon}
                listItems={get(reviewSummaryData, 'action_items', [] as string[])}
                loading={reviewSummaryLoading}
              />
            </Box>

            <Box sx={{ mt: '8px' }}>
              <ListComp
                bgcolor="rgba(235, 207, 186, 0.19)"
                headingColor="rgba(0, 0, 0, 1)"
                listIndexBgColor="rgba(241, 84, 18, 0.21)"
                listIndexColor="rgba(241, 84, 18, 1)"
                heading="Problematic Menu Items"
                Icon={WarningIcon}
                listItems={get(reviewSummaryData, 'problematic_menu_items', [] as string[])}
                loading={reviewSummaryLoading}
                // listItems={[
                //   <>
                //     Raita <span style={{ opacity: 0.4 }}>2 orders</span>
                //   </>,
                //   <>
                //     Butter Chicken{' '}
                //     <span style={{ opacity: 0.4 }}>2 orders</span>
                //   </>,
                //   <>
                //     Tikka masala <span style={{ opacity: 0.4 }}>2 orders</span>
                //   </>
                // ]}
              />
            </Box>
          </StyledBox>
        </Grid>
      </Grid>
    </>
  )
}
