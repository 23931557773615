import { ChargebackOrdersV2, DefaultService, FiltersForChargebackOrdersQueryV2 } from 'src/services/openApiV2'

export default async function getChargebacksOrders(body: {
  requestBody: FiltersForChargebackOrdersQueryV2
  offset: number
  limit: number
  orderBy?: string
  onSuccess: (data: ChargebackOrdersV2) => void
  onError: (err: Error) => void
  onStart: () => void
  onEnd: () => void
}) {
  body.onStart?.call({})
  try {
    let res = await DefaultService.getChargebackOrdersV2ChargebacksOrdersV2Post({
      requestBody: body.requestBody,
      limit: Math.max(100, body.limit),
      offset: body.offset,
      orderBy: body.orderBy
    })
    body.onSuccess(res)
  } catch (err) {
    body.onError(err)
  }
  body.onEnd?.call({})
}
