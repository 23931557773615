export const Dictionary = {
  CANCELLED: 'Cancelled',
  INACCURATE: 'Inaccurate',
  MISSED: 'Missed',
  GL_CODES: 'Chart of Accounts',
  GL_ENTRIES: 'Journal Entries',
  ACCOUNTING_COMPANIES: 'Accounting Companies',
  ACCOUNTING_SOFTWARES: 'Accounting Softwares',
  FRANCHISEE: 'Franchisee'
}
