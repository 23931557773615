import { ChevronRight, Info } from '@mui/icons-material'
import { Box, Collapse, SxProps, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IOSSwitch } from 'src/components/Switches'
import ColoredTooltip from 'src/components/Tooltip/ColoredTooltip'
import { useFilter } from 'src/context/FilterContext'
import { usePayoutsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/PayoutsBreakdownDataContext'
import { useTransactionsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/TransactionsBreakdownDataContext'
import { PLATFORM } from 'src/services/openApiV2'
import { Modules } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import DataComp from '../../components/DataComp'
import SalesChannelBreakdownCharts from '../../components/SalesChannelBreakdownCharts'
import { indicatorColor, rowFlex } from '../../utils/constants'
import tooltips from './IncomeStatementTooltips'

interface TotalBreakdownProps {
  sx?: SxProps
}

const TotalBreakdown = React.forwardRef<HTMLElement, TotalBreakdownProps>((props, componentRef) => {
  const { sx } = props
  const navigate = useNavigate()
  const { getAllPossibleFilters } = useFilter()
  const { breakdownData, breakdownLoading } = useTransactionsBreakdownDataContext()
  const { fontSizes } = usePayoutsBreakdownDataContext()
  const [openSalesChannelBreakdown, setOpenSalesChannelBreakdown] = React.useState(false)

  const otherTotal = React.useMemo(() => {
    return (
      get(breakdownData, 'values.customer_tip', 0) +
      get(breakdownData, 'values.restaurant_fees', 0) +
      get(breakdownData, 'values.miscellaneous', 0) +
      get(breakdownData, 'values.unaccounted', 0)
    )
  }, [breakdownData])

  return (
    <Box
      ref={componentRef}
      sx={{ ...sx }}>
      <DataComp
        sx={{ border: 'none', py: 0, mt: '15px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { pb: 1 },
            cols: [
              {
                label: 'Component',
                flex: rowFlex[0],
                sx: { fontWeight: 400, fontSize: fontSizes['12'] }
              },
              {
                label: 'Total',
                flex: rowFlex[2],
                sx: { textAlign: 'right', fontWeight: 600, fontSize: fontSizes['12'] }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)' }}
        rows={[
          {
            hideIndicator: true,
            // sx: { py: '3px' },
            cols: [
              {
                label: 'Sales Incl. Tax',
                flex: rowFlex[0],
                sx: { py: 0, fontWeight: 600, color: 'white', fontSize: fontSizes['14'] },
                tooltip: tooltips.sales_incl_tax,
                tooltipColor: 'rgba(255, 255, 255, 0.6)'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.subtotal_with_tax', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#E3EDF0',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.subtotal_with_tax', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: {
                  py: 0,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px', bgcolor: 'rgba(47, 74, 90, 1)' }}
        rows={[
          {
            hideIndicator: true,
            // sx: { pt: '10px' },
            cols: [
              {
                label: 'Sales Excl. Tax',
                flex: rowFlex[0],
                sx: { py: 0, color: 'white', fontSize: fontSizes['14'], fontWeight: 600 },
                tooltip: tooltips.sales_excl_tax,
                tooltipColor: 'rgba(255, 255, 255, 0.6)',
                rightComp: (
                  <IOSSwitch
                    size="small"
                    checked={openSalesChannelBreakdown}
                    onChange={() => {
                      setOpenSalesChannelBreakdown((prev) => !prev)
                    }}
                  />
                )
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.subtotal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#E3EDF0',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.subtotal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  py: 0,
                  color: 'rgba(255, 255, 255, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              }
            ]
          }
        ]}
      />

      <Collapse in={openSalesChannelBreakdown}>
        <SalesChannelBreakdownCharts />
      </Collapse>

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            sx: {
              pb: '10px',
              borderBottom: '1px solid #E6E6E6E6'
            },
            hideIndicator: true,
            cols: [
              {
                label: Modules.LostSales,
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontWeight: 400, fontSize: fontSizes['14'] },
                tooltip: tooltips.lost_sales
              },
              {
                label: (
                  <Typography
                    sx={{
                      fontSize: fontSizes['22'],
                      lineHeight: 'initial',
                      color: 'rgba(47, 74, 90, 0.6)',
                      fontWeight: 600
                    }}>
                    {`${formatCurrency(get(breakdownData, 'values.lost_sales', 0), { maxFractionDigits: 2 })}`}
                  </Typography>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: {
              mt: '10px'
            },
            indicatorColor: indicatorColor.refunds,
            cols: [
              {
                label: Modules.UnfulfilledRefunds,
                flex: rowFlex[0],
                tooltip: tooltips.unfulfilled_refunds,
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.unfulfilled_refunds', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.unfulfilled_refunds', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.taxes,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', pb: '10px' },
            cols: [
              {
                label: 'Taxes',
                flex: rowFlex[0],
                tooltip: tooltips.taxes,
                sx: { fontSize: fontSizes['14'] },
                rightComp: get(breakdownData, 'values.tax_backup_witheld', 0) > 0 && (
                  <ColoredTooltip
                    title="Potential high tax-related withholdings by third-party provider. It is recommended to update taxpayer information with the third-party provider to resolve the issue."
                    tooltipSx={{ fontSize: fontSizes['12'] }}>
                    <Info sx={{ fontSize: 20, color: '#E52E2E' }} />
                  </ColoredTooltip>
                )
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: `${get(breakdownData, 'values.tax_host') > 0 ? 'rgba(15, 120, 60, 1)' : 'rgba(241, 84, 18, 1)'}`
                      }}>
                      {get(breakdownData, 'values.tax_host', 0) > 0 && '+'}
                      {formatCurrency(get(breakdownData, 'values.tax_host', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.tax_host', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: {
              mt: '10px'
            },
            hideIndicator: true,
            cols: [
              {
                label: 'Taxes Withheld',
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: fontSizes['14'], fontWeight: 400 },
                tooltip: tooltips.taxes_withheld
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(47, 74, 90, 0.6)'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.tax_witheld', 0), { maxFractionDigits: 2 })}
                    </Typography>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            hideIndicator: true,
            hide: get(breakdownData, 'values.tax_backup_witheld', 0) <= 0,
            sx: { pt: '6px', pb: '3px' },
            cols: [
              {
                label: 'Tax Backup Withheld',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: tooltips.tax_backup_withheld
              },
              {
                label: formatCurrency(Math.abs(get(breakdownData, 'values.tax_backup_witheld', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: fontSizes['16'],
                  color: 'rgba(47, 74, 90, 0.6)'
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.commissions,
            cols: [
              {
                label: 'Commissions',
                flex: rowFlex[0],
                tooltip: tooltips.commissions,
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.commission', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.commission', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.restaurant_delivery_charge,
            cols: [
              {
                label: 'Restaurant Delivery Charge',
                flex: rowFlex[0],
                tooltip: tooltips.restaurant_delivery_charge,
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      {getAllPossibleFilters(['vb_platform']).vb_platform?.some((platform) => platform === PLATFORM.OLO) && get(breakdownData, 'values.loyalty', 0) !== 0 ? (
        <DataComp
          sx={{ mt: '12px' }}
          rows={[
            {
              sx: {
                borderRadius: '8px',
                py: '15px',
                pl: '20px',
                pr: '10px',
                border: '1px solid #E3EDF0',
                mb: '10px',
                bgcolor: '#E3EDF0',
                cursor: 'pointer'
              },
              onClick: () => navigate('/marketing/trends'),
              indicatorColor: indicatorColor.marketing,
              cols: [
                {
                  label: 'Marketing',
                  flex: rowFlex[0],
                  tooltip: tooltips.marketing,
                  sx: { fontSize: fontSizes['14'] }
                },
                {
                  label: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'row-reverse',
                        gap: '10px'
                      }}>
                      <ChevronRight sx={{ color: '#196E82' }} />
                      <Typography
                        sx={{
                          fontSize: fontSizes['22'],
                          fontWeight: 600,
                          lineHeight: 'initial',
                          color: 'rgba(241, 84, 18, 1)'
                        }}>
                        -{formatCurrency(Math.abs(get(breakdownData, 'values.marketing', 0)), { maxFractionDigits: 2 })}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end">
                        <Typography
                          sx={{
                            bgcolor: '#196E821F',
                            color: '#196E82',
                            p: '5px 8px',
                            lineHeight: 'initial',
                            borderRadius: '14px',
                            fontSize: fontSizes['12']
                          }}>
                          {formatNumber(get(breakdownData, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  loading: breakdownLoading,
                  flex: 'initial',
                  sx: { display: 'flex', justifyContent: 'flex-end' }
                }
              ]
            },
            {
              hideIndicator: true,
              sx: { pt: '10px', py: '3px' },
              cols: [
                {
                  label: 'Ad Spend',
                  flex: rowFlex[0],
                  sx: {
                    color: 'rgba(47, 74, 90, 0.6)',
                    fontWeight: 'normal',
                    fontSize: fontSizes['12']
                  },
                  tooltip: tooltips.marketing_ad_spend
                },
                {
                  label: '-' + formatCurrency(Math.abs(get(breakdownData, 'values.marketing_ad', 0)), { maxFractionDigits: 2 }),
                  flex: 'initial',
                  loading: breakdownLoading,
                  sx: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: fontSizes['16'],
                    color: 'rgba(241, 84, 18, 1)'
                  }
                }
              ]
            },
            {
              hideIndicator: true,
              sx: { py: '3px', pb: '10px' },
              cols: [
                {
                  label: 'Promo Spend',
                  flex: rowFlex[0],
                  sx: {
                    color: 'rgba(47, 74, 90, 0.6)',
                    fontWeight: 'normal',
                    fontSize: fontSizes['12']
                  },
                  tooltip: tooltips.marketing_promo_spend
                },
                {
                  label: '-' + formatCurrency(Math.abs(get(breakdownData, 'values.marketing_promo', 0)), { maxFractionDigits: 2 }),
                  flex: 'initial',
                  loading: breakdownLoading,
                  sx: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: fontSizes['16'],
                    color: 'rgba(241, 84, 18, 1)'
                  }
                }
              ]
            },
            {
              hideIndicator: true,
              indicatorColor: indicatorColor.loyalty,
              sx: { pt: '10px', borderTop: '1px solid #E6E6E6E6' },
              cols: [
                {
                  label: 'Loyalty',
                  flex: rowFlex[0],
                  sx: {
                    color: 'rgba(47, 74, 90, 0.6)',
                    fontWeight: 'normal',
                    fontSize: fontSizes['14']
                  },
                  tooltip: tooltips.loyalty
                },
                {
                  label: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'row-reverse',
                        gap: '10px'
                      }}>
                      <Typography
                        sx={{
                          fontSize: fontSizes['22'],
                          fontWeight: 600,
                          lineHeight: 'initial',
                          color: 'rgba(241, 84, 18, 1)'
                        }}>
                        -{formatCurrency(Math.abs(get(breakdownData, 'values.loyalty', 0)), { maxFractionDigits: 2 })}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end">
                        <Typography
                          sx={{
                            bgcolor: '#196E821F',
                            color: '#196E82',
                            p: '5px 8px',
                            lineHeight: 'initial',
                            borderRadius: '14px',
                            fontSize: fontSizes['12']
                          }}>
                          {formatNumber(get(breakdownData, 'percentages.loyalty', 0), { maxFractionDigits: 2 })}%
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  loading: breakdownLoading,
                  flex: 'initial',
                  sx: { display: 'flex', justifyContent: 'flex-end' }
                }
              ]
            }
          ]}
        />
      ) : (
        <DataComp
          sx={{ mt: '12px' }}
          rows={[
            {
              onClick: () => navigate('/marketing/trends'),
              indicatorColor: indicatorColor.marketing,
              sx: {
                borderRadius: '8px',
                py: '15px',
                pl: '20px',
                pr: '10px',
                border: '1px solid #E3EDF0',
                mb: '10px',
                bgcolor: '#E3EDF0',
                cursor: 'pointer'
              },
              cols: [
                {
                  label: 'Marketing',
                  flex: rowFlex[0],
                  tooltip: tooltips.marketing,
                  sx: { fontSize: fontSizes['14'] }
                },
                {
                  label: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'row-reverse',
                        gap: '10px'
                      }}>
                      <ChevronRight sx={{ color: '#196E82' }} />
                      <Typography
                        sx={{
                          fontSize: fontSizes['22'],
                          fontWeight: 600,
                          lineHeight: 'initial',
                          color: 'rgba(241, 84, 18, 1)'
                        }}>
                        -{formatCurrency(Math.abs(get(breakdownData, 'values.marketing', 0)), { maxFractionDigits: 2 })}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end">
                        <Typography
                          sx={{
                            bgcolor: '#196E821F',
                            color: '#196E82',
                            p: '5px 8px',
                            lineHeight: 'initial',
                            borderRadius: '14px',
                            fontSize: fontSizes['12']
                          }}>
                          {formatNumber(get(breakdownData, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  loading: breakdownLoading,
                  flex: 'initial',
                  sx: { display: 'flex', justifyContent: 'flex-end' }
                }
              ]
            },
            {
              hideIndicator: true,
              sx: { pt: '10px', py: '3px' },
              cols: [
                {
                  label: 'Ad Spend',
                  flex: rowFlex[0],
                  sx: {
                    color: 'rgba(47, 74, 90, 0.6)',
                    fontWeight: 'normal',
                    fontSize: fontSizes['12']
                  },
                  tooltip: tooltips.marketing_ad_spend
                },
                {
                  label: '-' + formatCurrency(Math.abs(get(breakdownData, 'values.marketing_ad', 0)), { maxFractionDigits: 2 }),
                  flex: 'initial',
                  loading: breakdownLoading,
                  sx: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: fontSizes['16'],
                    color: 'rgba(241, 84, 18, 1)'
                  }
                }
              ]
            },
            {
              hideIndicator: true,
              sx: { py: '3px' },
              cols: [
                {
                  label: 'Promo Spend',
                  flex: rowFlex[0],
                  sx: {
                    color: 'rgba(47, 74, 90, 0.6)',
                    fontWeight: 'normal',
                    fontSize: fontSizes['12']
                  },
                  tooltip: tooltips.marketing_promo_spend
                },
                {
                  label: '-' + formatCurrency(Math.abs(get(breakdownData, 'values.marketing_promo', 0)), { maxFractionDigits: 2 }),
                  flex: 'initial',
                  loading: breakdownLoading,
                  sx: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: fontSizes['16'],
                    color: 'rgba(241, 84, 18, 1)'
                  }
                }
              ]
            }
          ]}
        />
      )}

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            sx: {
              borderRadius: '8px',
              py: '15px',
              pl: '20px',
              pr: '10px',
              border: '1px solid #E3EDF0',
              mb: '10px',
              bgcolor: '#E3EDF0',
              cursor: 'pointer'
            },
            onClick: () => navigate('/chargebacks/overview'),
            indicatorColor: indicatorColor.refunds,
            cols: [
              {
                label: Modules.Chargebacks,
                flex: rowFlex[0],
                tooltip: tooltips.chargebacks,
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <ChevronRight sx={{ color: '#196E82', fontSize: fontSizes['20'] }} />
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.chargeback', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.chargeback', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.reversals,
            sx: { pt: '10px', borderTop: '1px solid #E6E6E6E6' },
            cols: [
              {
                label: Modules.WonDisputes,
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['14']
                },
                tooltip: tooltips.won_disputes
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(14, 140, 67, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.reversal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.reversal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.unaccounted,
            sx: { pb: '5px' },
            cols: [
              {
                label: 'Others',
                flex: rowFlex[0],
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: otherTotal > 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)'
                      }}>
                      {otherTotal > 0 && '+'}
                      {formatCurrency(otherTotal, { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(Math.abs((otherTotal * 100) / get(breakdownData, 'values.subtotal_with_tax', 0)), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: [
              {
                label: 'Customer Tips',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: tooltips.customer_tips
              },
              {
                label: '+' + formatCurrency(get(breakdownData, 'values.customer_tip', 0), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: fontSizes['16'],
                  color: 'rgba(14, 140, 67, 1)'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { py: '3px' },
            hide:
              !getAllPossibleFilters(['vb_platform']).vb_platform?.some((platform) => platform === PLATFORM.OLO) ||
              get(breakdownData, 'values.restaurant_fees', 0) === 0,
            cols: [
              {
                label: 'Restaurant Fees',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: tooltips.restaurant_fees
              },
              {
                label: '+' + formatCurrency(get(breakdownData, 'values.restaurant_fees', 0), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: fontSizes['16'],
                  color: 'rgba(14, 140, 67, 1)'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: [
              {
                label: 'Miscellaneous',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: tooltips.miscellaneous
              },
              {
                label:
                  (get(breakdownData, 'values.miscellaneous', 0) >= 0 ? '+' : '-') +
                  formatCurrency(Math.abs(get(breakdownData, 'values.miscellaneous', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: get(breakdownData, 'values.miscellaneous', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: fontSizes['16']
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: [
              {
                label: 'Unaccounted',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: tooltips.unaccounted
              },
              {
                label:
                  (get(breakdownData, 'values.unaccounted', 0) >= 0 ? '+' : '-') +
                  formatCurrency(Math.abs(get(breakdownData, 'values.unaccounted', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: get(breakdownData, 'values.unaccounted', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: fontSizes['16']
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)', mt: '12px', py: 3 }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: 'Net Payout',
                flex: rowFlex[0],
                sx: { fontSize: fontSizes['14'], color: 'white' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.total_payment', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#E3EDF0',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.total_payment', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),

                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />
      <DataComp
        sx={{ mt: '12px', bgcolor: '#E3EDF0', borderColor: '#E3EDF0', pr: '10px', cursor: 'pointer' }}
        rows={[
          {
            hideIndicator: true,
            onClick: () => navigate('/marketing/cogs'),
            cols: [
              {
                label: 'Cost of Goods Sold',
                flex: rowFlex[0],
                tooltip: tooltips.cost_of_goods_sold,
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <ChevronRight sx={{ color: '#196E82' }} />
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.cogs', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#196E821F',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.cogs', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />
      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)', mt: '12px', py: 3 }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: 'Net Margin',
                flex: rowFlex[0],
                sx: { fontSize: fontSizes['14'], color: 'white' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.total_payment', 0) - get(breakdownData, 'values.cogs', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{
                          bgcolor: '#E3EDF0',
                          color: '#196E82',
                          p: '5px 8px',
                          lineHeight: 'initial',
                          borderRadius: '14px',
                          fontSize: fontSizes['12']
                        }}>
                        {formatNumber(get(breakdownData, 'percentages.total_payment', 0) - get(breakdownData, 'percentages.cogs', 0), { maxFractionDigits: 2 })}
                        %
                      </Typography>
                    </Box>
                  </Box>
                ),

                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />
    </Box>
  )
})

export default TotalBreakdown
