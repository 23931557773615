import { CustomMaterialTable, MultiSelect, Paper } from '@LoopKitchen/loop-ui'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Chip, Slider, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import React, { useState } from 'react'
import OrderDrawer, { OrderDrawerDetails } from 'src/components/OrderDrawer/OrderDrawer'
import {
  DefaultService,
  Pos3POrdersVariance,
  Pos3POrdersVarianceInput,
  Pos3POrdersVariancePaginated,
  PosLocation,
  PosThirdPartyOrdersAggregate,
  PosType
} from 'src/services/openApiV2'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import DeltaCard from '../../../components/DeltaCard'
import { exportCSV } from '../../../components/ExportCsvV2'
import PlatformLogo from '../../../components/PlatformLogo'
import { StringShortener } from '../../../components/StringShortener'
import Page from '../../../components/mui/Page'
import { useErrorData } from '../../../context/ErrorContext'
import { useFilter } from '../../../context/FilterContext'
import { useSnackData } from '../../../context/SnackContext'
import { filterNames } from '../../../utils/config/config'
import { formatCurrency } from '../../../utils/functions'
import usePostHogHook from '../../../utils/hooks/usePostHogHook'
import LoadingAnimation from '../dashboard/components/LoadingAnimation'

export default function ThirdPartyOrdersReconciliation() {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { trackPostHogDownload } = usePostHogHook()
  const { headerHeightPx } = useLayoutDimension()
  const [analyticsLoading, setAnalyticsLoading] = React.useState(true)
  const [dataLoading, setDataLoading] = React.useState(true)
  const [summaryData, setSummaryData] = React.useState<PosThirdPartyOrdersAggregate>({})
  const [tableData, setTableData] = React.useState<Pos3POrdersVariancePaginated>(null)
  const [varianceThreshold, setVarianceThreshold] = React.useState<number>(0.0)
  const [allPosTypes, setAllPosTypes] = React.useState<string[]>([])
  const [selectedPosTypes, setSelectedPosTypes] = React.useState<string[]>([])
  const [allLocations, setAllLocations] = React.useState<string[]>([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [orderDetails, setOrderDetails] = useState<OrderDrawerDetails>(null)
  const { setDownloadSnack } = useSnackData()
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [clickedCard, setClickedCard] = useState('')
  const [filterBubble, setFilterBubble] = useState<{ message: string }>({
    message: ''
  })

  const handleSliderChange = (event, newValue) => {
    setVarianceThreshold(newValue)
  }

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const handleClose = () => {
    setDrawerOpen(false)
  }

  const requestBodyForSummaryGetter = (): Pos3POrdersVarianceInput => {
    const requestBody: Pos3POrdersVarianceInput = getFiltersV2(['b_name', 'chain', 'start_date', 'end_date'], true)
    requestBody.pos = selectedPosTypes.join('|')
    return requestBody
  }

  const requestBodyForListGetter = (): Pos3POrdersVarianceInput => {
    const requestBody: Pos3POrdersVarianceInput = getFiltersV2(['b_name', 'chain', 'start_date', 'end_date'], true)
    requestBody.pos = selectedPosTypes.join('|')
    if (clickedCard === 'tax_variance') {
      requestBody.variance_tax_threshold = varianceThreshold
    } else if (clickedCard === 'total_variance') {
      requestBody.variance_threshold = varianceThreshold
    } else if (clickedCard === 'subtotal_variance') {
      requestBody.variance_without_tax_threshold = varianceThreshold
    } else if (clickedCard === 'orders_pos') {
      requestBody.type_in = 'pos'
    } else if (clickedCard === 'orders_3p') {
      requestBody.type_in = '3p'
    }
    return requestBody
  }

  const getPosTypes = async () => {
    try {
      const data: Array<PosType> = await DefaultService.callPosListPosTypesApiPosListPosTypesPost({
        requestBody: {
          ...getFiltersV2(['b_name', 'chain', 'start_date', 'end_date'], true)
        }
      })
      setAllPosTypes(data.map((posType) => posType.pos))
    } catch (err) {
      handleError(err.message)
    }
  }

  const getLocations = async () => {
    try {
      const data: Array<PosLocation> = await DefaultService.callPosListLocationsApiPosListLocationsPost({
        requestBody: {
          ...getFiltersV2(['chain', 'start_date', 'end_date'], true)
        }
      })
      setAllLocations(data.map((location) => location.location))
    } catch (err) {
      handleError(err.message)
    }
  }

  const getTableData = async (paginate: boolean = false) => {
    setDataLoading(true)
    setLoadingNextPage(paginate)
    try {
      const data: Pos3POrdersVariancePaginated = await DefaultService.callPosPos3pOrdersVarianceApiPaginatedPosPos3pOrdersVariancePost({
        requestBody: {
          ...requestBodyForListGetter(),
          limit: 100,
          offset: paginate ? tableData?.next_offset || 0 : 0
        },
        isPaginated: true
      })
      if (paginate) {
        data.data = [...tableData.data, ...data.data]
      }
      setTableData(data)
      setDataLoading(false)
      setLoadingNextPage(false)
    } catch (err) {
      handleError(err.body)
      setAnalyticsLoading(false)
      setSummaryData(null)
    }
  }

  const getSummaryData = async () => {
    setAnalyticsLoading(true)
    try {
      const requestBody: Pos3POrdersVarianceInput = requestBodyForSummaryGetter()

      const data: PosThirdPartyOrdersAggregate = await DefaultService.callPosAggregateMetricsFor3pVsPosOrdersApiPosAggregateMetricsFor3pVsPosOrdersPost({
        requestBody
      })
      setSummaryData(data)
      setAnalyticsLoading(false)
    } catch (err) {
      handleError(err.body)
      setAnalyticsLoading(false)
      setSummaryData(null)
    }
  }

  const getDataByOrderID = (order_date: string, order_id: string, slug: string) => {
    setOrderDetails({
      order_id,
      open: true,
      slug,
      order_date
    })
  }

  React.useEffect(() => {
    getPosTypes()
  }, [])

  React.useEffect(() => {
    getLocations()
  }, [])

  React.useEffect(() => {
    if (getFilters(['b_name']).length > 0) getSummaryData()
  }, [...getFilters(['b_name', 'chain', 'start_date', 'end_date']), selectedPosTypes])

  React.useEffect(() => {
    if (getFilters(['b_name']).length > 0) getTableData(false)
  }, [...getFilters(['b_name', 'chain', 'start_date', 'end_date']), clickedCard, selectedPosTypes, varianceThreshold])

  React.useEffect(() => {
    if (clickedCard === 'total_variance') {
      setFilterBubble({
        message: 'Showing only days with variance(total) > ' + varianceThreshold + '$'
      })
    } else if (clickedCard === 'tax_variance') {
      setFilterBubble({
        message: 'Showing only days with variance(tax) > ' + varianceThreshold + '$'
      })
    } else if (clickedCard === 'subtotal_variance') {
      setFilterBubble({
        message: 'Showing only days with variance(subtotal) > ' + varianceThreshold + '$'
      })
    } else if (clickedCard === 'orders_pos') {
      setFilterBubble({
        message: 'Showing only orders in POS but not in 3P'
      })
    } else if (clickedCard === 'orders_3p') {
      setFilterBubble({
        message: 'Showing only orders in 3P but not in POS'
      })
    }
  }, [varianceThreshold, clickedCard])

  return (
    <>
      {filterLoading && <LoadingAnimation />}
      {!filterLoading && (
        <>
          <Page
            title="POS <> 3P Orders Reconciliation"
            sx={{ bgcolor: 'white', p: 4 }}
            filterProps={{
              customMaxDate: moment().subtract(1, 'days').toDate(),
              hideFilterKeys: ['vb_name', 'am_name', 'vb_platform'],
              allowedFilters: {
                b_name: allLocations
              },
              extraFilters: [
                <MultiSelect
                  label="POS type"
                  selectButtonSx={{
                    p: '12px 32px 12px 8px'
                  }}
                  disableScrollLock
                  options={allPosTypes.map((cardType) => ({ label: cardType, value: cardType }))}
                  value={selectedPosTypes.length === 0 ? allPosTypes : selectedPosTypes}
                  onChange={(value) => {
                    setSelectedPosTypes(value)
                  }}
                />
              ],
              onResetCallback: () => {
                setSelectedPosTypes([])
              },
              independentFilter: true
            }}>
            <OrderDrawer
              orderDetails={orderDetails}
              onClose={() => setOrderDetails(null)}
            />
            <Paper sx={{ p: 0 }}>
              <Typography
                variant="h3"
                component="h3"
                pt={2}>
                Summary
              </Typography>
              <Box
                display="flex"
                gap="0.5rem"
                marginTop={'1rem'}
                flexWrap="wrap">
                {/*<DeltaCard*/}
                {/*  title={`${formatCurrency(summaryData?.total_3p)}`}*/}
                {/*  label="Total from 3P"*/}
                {/*  bigFont*/}
                {/*  loading={analyticsLoading}*/}
                {/*/>*/}
                {/*<DeltaCard*/}
                {/*  title={`${formatCurrency(summaryData?.total_pos)}`}*/}
                {/*  label="Total from POS"*/}
                {/*  bigFont*/}
                {/*  loading={analyticsLoading}*/}
                {/*/>*/}
                <DeltaCard
                  title={`${summaryData?.orders_3p}`}
                  label="Orders from 3P not in POS"
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('orders_3p')
                  }}
                />
                <DeltaCard
                  title={`${summaryData?.orders_pos}`}
                  label="Orders from POS not in 3P"
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('orders_pos')
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(summaryData?.variance_total)}`}
                  label="Variance in Total"
                  labelTooltip={'Variance between 3P and POS data in Total'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('total_variance')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(summaryData?.variance_subtotal)}`}
                  label="Variance in Subtotal"
                  labelTooltip={'Variance between 3P and POS data in subtotal'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('subtotal_variance')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(summaryData?.variance_tax)}`}
                  label="Variance in tax"
                  labelTooltip={'Variance between 3P and POS data in tax'}
                  bigFont
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('tax_variance')
                    setVarianceThreshold(0.1)
                  }}
                />
              </Box>
              <Box
                display="flex"
                gap="0.5rem"
                marginTop={'1rem'}
                flexWrap="wrap">
                <DeltaCard
                  title={`${formatCurrency(summaryData?.tax_pos)}`}
                  label="Tax from Pos"
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('tax_pos')
                    setVarianceThreshold(0.1)
                  }}
                />
                <DeltaCard
                  title={`${formatCurrency(summaryData?.tax_3p)}`}
                  label="Tax from 3p"
                  loading={analyticsLoading}
                  handleCardClick={() => {
                    setTableData(null)
                    setClickedCard('tax_3p')
                    setVarianceThreshold(0.1)
                  }}
                />
              </Box>
            </Paper>

            {(clickedCard === 'orders_3p' || clickedCard === 'orders_pos') && (
              <Box py={4}>
                <Chip
                  label={<Typography variant="body1">{filterBubble.message}</Typography>}
                  onDelete={() => {
                    setFilterBubble({ message: '' })
                    setClickedCard('')
                  }}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                  color="primary"
                />
              </Box>
            )}

            {(clickedCard === 'total_variance' || clickedCard === 'tax_variance' || clickedCard == 'subtotal_variance') && (
              <Box py={4}>
                <Chip
                  label={<Typography variant="body1">{filterBubble.message}</Typography>}
                  onDelete={() => {
                    setFilterBubble({ message: '' })
                    setClickedCard('')
                    setVarianceThreshold(0.0)
                  }}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                  color="primary"
                />
              </Box>
            )}

            {/* Render the slider and its label only when either of the variance cards are clicked */}
            {(clickedCard === 'total_variance' || clickedCard === 'tax_variance' || clickedCard == 'subtotal_variance') && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start">
                <Typography gutterBottom>Select Variance Threshold</Typography>
                <Box
                  width={250}
                  pl={1}>
                  <Slider
                    value={varianceThreshold}
                    onChange={handleSliderChange}
                    step={0.1}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Box>
            )}
            {/*{dataLoading && <LoadingAnimation />}*/}
            {
              <Box>
                <CustomMaterialTable
                  columns={[
                    {
                      title: filterNames.b_name,
                      field: 'b_name'
                    },
                    {
                      title: filterNames.vb_name,
                      field: 'vb_name'
                    },
                    {
                      title: 'POS',
                      field: 'pos',
                      render: (rowData: Pos3POrdersVariance) => (
                        <StringShortener
                          str={rowData.pos}
                          platform={rowData.pos} // Assuming you want to use the 'pos' value as the platform too
                          allowClickToCopy={false}
                          displayLength={0}
                        />
                      )
                    },
                    // {
                    //   title: 'Platform',
                    //   field: 'vb_platform',
                    //   render: (rowData: Pos3POrdersVariance) => (
                    //     <PlatformLogo
                    //       platformName={rowData.vb_platform}
                    //       shortImg={true}
                    //       height="20px"
                    //     />
                    //   )
                    // },
                    {
                      title: 'Date',
                      field: 'order_date'
                    },
                    {
                      title: 'POS Order ID',
                      field: 'order_id_pos'
                    },
                    {
                      title: '3P Order ID',
                      field: 'order_id_3p',
                      render: (rowData: Pos3POrdersVariance) =>
                        rowData.order_id_3p && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}>
                            {rowData.vb_platform && (
                              <Box>
                                <PlatformLogo
                                  shortImg
                                  platformName={rowData.vb_platform}
                                  height={'24px'}
                                  width={'24px'}
                                />
                              </Box>
                            )}
                            <Button
                              size={'small'}
                              variant={'text'}
                              onClick={() => {
                                getDataByOrderID(rowData.order_date, rowData.order_id_3p, rowData.slug)
                              }}>
                              {rowData.order_id_3p.slice(rowData.order_id_3p.length - 6)}
                            </Button>
                          </Stack>
                        )
                    },
                    {
                      title: 'Total from POS',
                      field: 'total_pos',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.total_pos)
                    },
                    {
                      title: 'Total from 3P',
                      field: 'total_3p',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.total_3p)
                    },
                    {
                      title: 'Variance in Total',
                      field: 'variance_total',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.variance_total)
                    },
                    {
                      title: 'Subtotal from POS',
                      field: 'subtotal_pos',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.subtotal_pos)
                    },
                    {
                      title: 'Subtotal from 3P',
                      field: 'subtotal_3p',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.subtotal_3p)
                    },
                    {
                      title: 'Variance in Subtotal',
                      field: 'variance_subtotal',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.variance_subtotal)
                    },
                    {
                      title: 'Tax from POS',
                      field: 'tax_pos',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.tax_pos)
                    },
                    {
                      title: 'Tax from 3P',
                      field: 'tax_3p',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.tax_3p)
                    },
                    {
                      title: 'Variance(Tax)',
                      field: 'variance_tax',
                      render: (rowData: Pos3POrdersVariance) => formatCurrency(rowData.variance_tax)
                    }
                  ]}
                  data={tableData?.data || []}
                  isLoading={dataLoading}
                  options={{
                    stickyHeader: headerHeightPx,
                    export: true,
                    search: false,
                    pagination: true,
                    toolbarSx: { pb: 2, pt: 0, pl: 0 },
                    totalPaginatedDataLength: tableData?.max_rows || 0,
                    loadingNextPage: loadingNextPage,
                    debounceMilliseconds: 1500
                  }}
                  onRefresh={() => getTableData(false)}
                  onLastPage={async () => {
                    if (tableData?.next_offset) {
                      await getTableData(true)
                    }
                  }}
                  onExportCSV={() => {
                    exportCSV({
                      apiCall: DefaultService.exportPosPos3pOrdersVarianceApiExportPosPos3pOrdersVariancePost, // Adjust this to the appropriate API endpoint for the new data type
                      requestBody: requestBodyForListGetter(),
                      onStart: () => setDownloadSnack({ status: 'start' }),
                      onComplete: () => setDownloadSnack({ status: 'complete' }),
                      onError: (errMessage: string) => {
                        setDownloadSnack({ status: 'close' })
                        handleError(errMessage)
                      },
                      trackDownload: (details: { fileName: string; type: 'CSV' | 'PDF' }) => {
                        trackPostHogDownload(details)
                      }
                    })
                  }}
                />
              </Box>
            }
          </Page>
        </>
      )}
    </>
  )
}
