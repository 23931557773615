import { AdsDetailsDrillDownBreakDownOptions } from 'src/services/openApiV2'
import { filterIcons, filterNames } from 'src/utils/config/config'
import JSONDataType from '../types'

const commonColumnsArr: JSONDataType['mainBreakdownOptions'][0]['columns'] = [{ title: 'Orders', field: 'orders', type: 'number' }]

const jsonData: JSONDataType = {
  title: `Orders`,
  mainBreakdownOptions: [
    {
      icon: filterIcons.b_name,
      label: filterNames.b_name,
      value: AdsDetailsDrillDownBreakDownOptions.B_NAME,
      columns: [{ title: filterNames.b_name, field: 'b_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.slug,
      label: filterNames.slug,
      value: AdsDetailsDrillDownBreakDownOptions.SLUG,
      columns: [{ title: filterNames.slug, field: 'slug' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_name,
      label: filterNames.vb_name,
      value: AdsDetailsDrillDownBreakDownOptions.VB_NAME,
      columns: [{ title: filterNames.vb_name, field: 'vb_name' }, ...commonColumnsArr]
    },
    {
      icon: filterIcons.vb_platform,
      label: filterNames.vb_platform,
      value: AdsDetailsDrillDownBreakDownOptions.VB_PLATFORM,
      columns: [{ title: filterNames.vb_platform, field: 'vb_platform' }, ...commonColumnsArr]
    }
  ],
  apiFunctions: {
    tableData: 'callDrillDownGeneratedAdsDetailsGroupedListOfEntriesApiPaginatedDrillDownGeneratedAdsDetailsGroupedListOfEntriesPost',
    dateRangeCompareData:
      'drillDownGeneratedAdsDetailsGroupedListOfEntriesDateRangeCompareApiDateRangeCompareDrillDownGeneratedAdsDetailsGroupedListOfEntriesPost',
    exportData: 'exportDrillDownGeneratedAdsDetailsGroupedListOfEntriesApiExportDrillDownGeneratedAdsDetailsGroupedListOfEntriesPost',
    filterData: 'callDrillDownGeneratedAdsDetailsUniqueValuesApiDrillDownGeneratedAdsDetailsUniqueValuesPost'
  }
}

export default jsonData
