import { decrypt, encrypt } from './encryptionDecryption'

export function getLocal(key: string, withDecryption?: boolean): any {
  try {
    const localData = localStorage.getItem(key)
    if (!localData) return null
    if (!withDecryption) return JSON.parse(localData)
    return JSON.parse(decrypt(localData))
  } catch (error) {
    return undefined
  }
}

export function setLocal<T>(key: string, data: T, withEncryption?: boolean): void {
  try {
    if (!withEncryption) localStorage.setItem(key, JSON.stringify(data))
    else localStorage.setItem(key, encrypt(JSON.stringify(data)))
  } catch (error) {
    console.error('Error setting local storage:', error)
    // Handle error if necessary
  }
}

export function setSession<T>(key: string, data: T, withEncryption?: boolean): void {
  try {
    if (!withEncryption) sessionStorage.setItem(key, JSON.stringify(data))
    else sessionStorage.setItem(key, encrypt(JSON.stringify(data)))
  } catch (error) {
    console.error('Error setting session storage:', error)
  }
}

export function getSession(key: string, withDecryption?: boolean): any {
  try {
    const sessionData = sessionStorage.getItem(key)
    if (!sessionData) return null
    if (!withDecryption) return JSON.parse(sessionData)
    return JSON.parse(decrypt(sessionData))
  } catch (error) {
    console.error('Error getting session storage:', error)
    return undefined
  }
}
