/*
    A generic component that can be used to export CSV files from any API call.
 */

import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'

type ExportCSVProps = {
  apiCall: (requestBody: any) => Promise<any>
  fileName?: string
  fileType?: 'CSV' | 'PDF'
  requestBody?: any
  onStart?: () => void
  onComplete?: () => void
  onError?: (errorMessage: string) => void
  trackDownload?: (details: { fileName: string; type: 'CSV' | 'PDF' }) => void
}

export const exportCSV = async ({
  apiCall,
  fileName = 'report.csv',
  fileType = 'CSV',
  requestBody = {},
  onStart,
  onComplete,
  onError,
  trackDownload
}: ExportCSVProps) => {
  if (onStart) onStart()

  try {
    const file = await apiCall({ requestBody })
    let blob = new Blob([file], { type: fileType })
    let a = document.createElement('a')
    a.download = fileName
    a.href = URL.createObjectURL(blob)
    a.click()
    if (onComplete) onComplete()

    if (trackDownload) {
      trackDownload({
        fileName: a.download,
        type: fileType as 'CSV' | 'PDF'
      })
    }
    downloadSuccessSlackAlert({})
  } catch (err) {
    downloadFailedSlackAlert({ err })
    if (onError) onError(err.message)
  }
}
