import { SxProps, Tooltip, TooltipProps } from '@mui/material'
import React from 'react'

interface ColoredTooltipProps extends TooltipProps {
  title: React.ReactNode
  tooltipSx?: SxProps
  children: React.ReactElement<any, any>
}

const ColoredTooltip = (props: ColoredTooltipProps) => {
  const { title, placement = 'top', tooltipSx, children } = props

  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 500,
            background: '#000',
            maxWidth: 600,
            padding: '10px',
            ...tooltipSx
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}
      {...props}>
      {children}
    </Tooltip>
  )
}

export default ColoredTooltip
