import moment from 'moment'
import React from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApiV2'
import { Dictionary } from 'src/utils/config/dictionary'
import { get } from 'src/utils/config/lodashUtils'
import { calculateDelta } from 'src/utils/functions'
import useTabUrlState from 'src/utils/hooks/useTabUrlState'
import Overview from '../SalesOverview/Overview'
import { ApiKpiErrorOverviewTypeV2 } from './types'

export function kFormatter(num): string {
  return (Math.abs(num) > 999 ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num)).toString()
}

interface ErrorOverviewProps {
  tabValue?: number
}

const ErrorOverview = ({ tabValue }: ErrorOverviewProps) => {
  const { currentUser } = useAuth()
  const { handleError } = useErrorData()
  const [linkTab, setLinkTab] = useTabUrlState('error_overview', tabValue ?? 1)
  const [graphData, setGraphData] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [prevTotal, setPrevTotal] = React.useState(0)
  const [graphDataLoading, setGraphDataLoading] = React.useState(true)
  const [message, setMessage] = React.useState('')
  const [messageType, setMessageType] = React.useState('')

  const handleLinkTab = (event: React.SyntheticEvent, newValue: number) => {
    setLinkTab(newValue)
    // navigate({
    //   pathname: ''
    // })
  }
  const labelArr = [
    { label: 'All', value: 1 },
    { label: `${Dictionary.MISSED} Orders`, value: 2 },
    { label: `${Dictionary.CANCELLED} Orders`, value: 3 },
    { label: `${Dictionary.INACCURATE} Orders`, value: 4 }
  ]
  const { getFilters, getFiltersV2, dateRange } = useFilter()
  const tabNames = ['', 'Missed', 'Cancelled', 'Inaccurate']
  const procedureNameArray = ['procedure_error_overview_graph_by_date']

  const delta = React.useMemo(() => {
    return calculateDelta(total, prevTotal)
  }, [total, prevTotal])

  const getDaysData = (arr, data) => {
    let newData = [],
      j = 0,
      i = 0
    while (i < arr.length && j < data.length) {
      if (arr[i] === moment(moment(data[j].Date).toDate()).format('D MMM')) {
        const date = moment(moment(data[j].Date).toDate()).format('D MMM')
        newData.push({
          Date: date,
          Order: data[j].Orders
        })
        j++
      } else {
        newData.push({
          Date: arr[i],
          Order: -1
        })
      }
      i++
    }
    while (i < arr.length) {
      newData.push({
        Date: arr[i],
        Order: -1
      })
      i++
    }
    return newData
  }

  React.useEffect(() => {
    ;(async () => {
      setGraphDataLoading(true)
      try {
        const res = (await DefaultService.getErrorOverviewV2KpiErrorOverviewV2Post({
          requestBody: {
            ...getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date', 'granularity'], true),
            error_category_in: tabNames[linkTab - 1]
          }
        })) as ApiKpiErrorOverviewTypeV2
        let sum = get(res, 'current_range_data.error_aggregate.errors', 0)
        let prevSum = get(res, 'previous_range_data.error_aggregate.errors', 0)

        const currentPeriodData = res.current_range_data.error_datewise.sort((a, b) => a.received_ts.localeCompare(b.received_ts))
        const prevPeriodData = res.previous_range_data.error_datewise.sort((a, b) => a.received_ts.localeCompare(b.received_ts))
        const result = []

        for (let i = 0; i < Math.min(currentPeriodData.length, prevPeriodData.length); i++) {
          const current = currentPeriodData[i]
          const prev = prevPeriodData[i]
          const currentValue = current.errors
          const prevValue = prev.errors
          result.push({
            id: i + 1,
            currentDate: moment(current.received_ts).format('D MMM'),
            prevDate: moment(prev.received_ts).format('D MMM'),
            currentValue,
            prevValue
          })
        }

        if (result.length === 0 && currentPeriodData.length > 0) {
          for (let i = 0; i < currentPeriodData.length; i++) {
            const current = currentPeriodData[i]
            const currentValue = current.errors
            result.push({
              id: i + 1,
              currentDate: moment(current.received_ts).format('D MMM'),
              currentValue
            })
          }
        }

        if (result.length === 0 && prevPeriodData.length > 0) {
          for (let i = 0; i < prevPeriodData.length; i++) {
            const prev = prevPeriodData[i]
            const prevValue = prev.errors
            result.push({
              id: i + 1,
              prevDate: moment(prev.received_ts).format('D MMM'),
              prevValue
            })
          }
        }

        setGraphData(result)
        if (sum === 0) {
          setMessage('have 0 errors')
          setMessageType('positive')
        } else {
          setMessage('')
          setMessageType('')
        }
        setTotal(sum)
        setPrevTotal(prevSum)
        setGraphDataLoading(false)
      } catch (error) {
        setGraphDataLoading(false)
        console.error(error)
        handleError("Graph data couldn't be loaded")
      }
    })()
  }, [linkTab, currentUser, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date', 'granularity'])])

  return (
    <>
      <Overview
        sectionLink="dashboard-error-overview"
        name="Error Overview"
        tabs={labelArr}
        indicator={{
          name: 'Errors',
          sum: total.toString(),
          delta: delta,
          deltaColor: delta <= 0 ? 'success' : 'error',
          deltaTooltip: `Previous Error: ${prevTotal}`,
          sumColor: 'green',
          startDate: dateRange.start.format('D MMM'),
          endDate: dateRange.end.format('D MMM'),
          startDate2: graphData.length && graphData[0].prevDate,
          endDate2: graphData.length && graphData[graphData.length - 1].prevDate
        }}
        procedureNameArray={procedureNameArray}
        graphData={graphData}
        graphDataLoading={graphDataLoading}
        linkTab={linkTab}
        handleLinkTab={handleLinkTab}
        type={messageType}
        chartClickable={true}
        message={message}
        allowDecimals={false}
      />
    </>
  )
}

export default ErrorOverview
